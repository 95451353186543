import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { StoneLoading } from "../components/lmx-loading/loading";
import { ScrollMemory } from "../App";

const PublicLayout = () => {
  return (
    <div className="PublicLayout">
     
    
      <Suspense fallback={<StoneLoading />}>
        <ScrollMemory>
          <Outlet />
        </ScrollMemory>
      </Suspense>

     
    </div>
  );
};

export default PublicLayout;
