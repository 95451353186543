import React from "react";
import { useDispatch, useSelector } from "react-redux";
import edit from "../../../assets/tablelist/view.png"
import { TableNew } from "../../../components/lmx-tables/table2";
import Content from "../../../components/content/Content";
import Head from "../../../components/Head/Head";
import { BlockTitle } from "../../../components/Component";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { ListHistoryDetailsAction } from "../../../redux/action/expense";

const History = () => {


  const navigate = useNavigate();
  const dispatch = useDispatch()

  const columns = React.useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Salary",
        accessor: "salary",
      }, {
        Header: "Credits",
        accessor: "cred_total",
      }, {
        Header: "Expenses",
        accessor: "exp_total",
      },
      {
        Header: "Actions",
        accessor: "action",
        disableSortBy: true,
        Cell: (cell) => (

          <div className="px-1 py-1 gap-10 flex items-center ">
            {" "}
            <img
              src={edit}
              className="h-5 cursor-pointer"
              onClick={() =>
                navigate(
                  {
                    pathname: `${process.env.PUBLIC_URL}/expense-tracker/download`,
                  },
                  {
                    state: { id: cell.cell.row.original.salary_id },
                  }
                )
              }
            />{" "}
          </div>
        ),
      },
    ],
    []
  );

  const { HistoryDetails, loading } = useSelector((state) => state.HistoryDetails);
  useEffect(() => {
    dispatch(ListHistoryDetailsAction());
  }, [dispatch])




  return (
    <>
      {loading ? (
        <div>
          Loading....
        </div>
      ) : (
        <>
          <React.Fragment>
            <Head title="History" />
            <h3 className="font-semibold text-2xl mt-3 font-poppins text-[EDEDED] px-4 dark:text-white ">
              {" "}
              History
            </h3>
            <Content>
              <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-5 md:space-x-1.5 p-1">
                <BlockTitle >Report
                </BlockTitle>
              </div>

              <div className="w-full  text-black dark:text-gray-200">
                <TableNew
                  loading={loading}
                  columns={columns}
                  data={HistoryDetails ? HistoryDetails : []}
                />
              </div>

            </Content>
          </React.Fragment>
        </>
      )}
    </>
  );
};

export default History;
