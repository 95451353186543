import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import avatar from "../../assets/avatar/user.png";
import Head from "../../components/Head/Head";
import ModalComponent from "../Models/Edit_Modal";
import { useReducer } from "react";
import moment from "moment";
import edit from "../../assets/profile/edit.png";
import Profile_Edit_Model from "../Models/Profile_Edit_Model";
import ProfMod from "../Models/Profile_Model";

const initialState = { showModal: false };
const initialStateA = { showModalA: false };
const initialStateB = { showModalB: false };

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_PASSWORD":
      return { mode: "change_password", showModal: true };
    case "CLOSE_MODAL":
      return { showModal: false };
    default:
      return state;
  }
};

const reducerA = (stateA, actionA) => {
  switch (actionA.type) {
    case "PROFILE_EDIT":
      return { mode: "profile_edit", showModalA: true };
    case "CLOSE_MODAL":
      return { showModalA: false };
    default:
      return stateA;
  }
};
const reducerB = (stateB, actionB) => {
  switch (actionB.type) {
    case "ADD_PHOTO":
      return { mode: "change_prof", showModalB: true };
    case "UPDATE_PHOTO":
      return { mode: "update_prof", showModalB: true };
    case "CLOSE_MODAL":
      return { showModalB: false };
    default:
      return stateB;
  }
};

const Profile = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateA, dispatchA] = useReducer(reducerA, initialStateA);
  const [stateB, dispatchB] = useReducer(reducerB, initialStateB);

  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );
  const [chng, setChng] = useState();
  useEffect(() => {
    user &&
      setChng(moment(user?.password_changed).format("MMMM Do YYYY, h:mm a"));
  }, [user]);


  return (
    <React.Fragment>
      <Head title="Profile" />
      <ModalComponent state={state} dispatch={dispatch} />
      <Profile_Edit_Model state={stateA} dispatch={dispatchA} user={user} />
      <ProfMod state={stateB} dispatch={dispatchB} />

      <h3 className="font-semibold text-2xl mt-3 font-poppins text-[EDEDED] px-4 dark:text-white ">
        {" "}
        Profile
      </h3>

      <div className="col-span-6 xl:col-span-3 m-3">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-5 md:space-x-1.5 ">
            <div className="flex py-4 px-3">
              <h3 className="font-semibold flex text-xl text-gray-800 dark:text-white mb-2 font-poppins">
                Manage your personal details.&nbsp;&nbsp;{" "}
                <img
                  src={edit}
                  alt="edit"
                  className="h-6 cursor-pointer"
                  onClick={() => {
                    dispatchA({ type: "PROFILE_EDIT" });
                  }}
                />
              </h3>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-5 md:space-x-1.5 m-1">
            <div className="flex flex-row  p-1">
              <div className="photo-wrapper mr-1  cursor-pointer">
                <div className="flex items-center space-x-4">
                  {user?.photo == undefined ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatchB({ type: "ADD_PHOTO" });
                      }}
                    >
                      <img
                        className=" rounded-full mx-1"
                        width={80}
                        height={90}
                        src={avatar}
                        alt="avatar"
                      />
                    </div>
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatchB({ type: "UPDATE_PHOTO" });
                      }}
                    >
                      <img
                        className=" rounded-full mx-1"
                        width={80}
                        height={80}
                        src={user?.photo}
                        alt="user photo"
                      />
                    </div>
                  )}

                  <div className="font-medium dark:text-white">
                    <div className="text-lg font-semibold uppercase font-poppins">
                      {" "}
                      {user?.name}
                    </div>
                    <div className="text-md text-gray-900 font-semibold dark:text-gray-400 font-poppins">
                      {user?.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="mb-3 text-xl font-semibold font-poppins text-black dark:text-white">
              <p className="mb-1.5 text-xl font-semibold text-black dark:text-white"></p>
            </h3>
          </div>
        </div>
      </div>

      <div className="col-span-6 xl:col-span-3 m-3">
        <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-6 px-4 dark:border-strokedark">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-5 md:space-x-1.5 ">
              <div className=" py-4 px-3">
                <h3 className="font-semibold text-xl text-gray-800 dark:text-white mb-2 font-poppins">
                  Password{" "}
                </h3>
                {user?.password_changed != undefined ? (
                  <p className="text-md text-gray-700 font-medium">
                    Last changed on{" "}
                    <span className="text-gray-900 italic">
                      {user ? user?.password_changed : "loading..."}.
                    </span>
                  </p>
                ) : (
                  <p className="text-md text-gray-700 font-medium">
                    {"Password not yet changed."}
                  </p>
                )}
              </div>
              <div className="px-6 py-2 grid gap-3 md:flex md:justify-between md:items-center  border-gray-200 dark:border-gray-700">
                <div className="inline-flex gap-x-2">
                  <div className="inline-flex gap-x-3 mb-0 px-3  gap-2 ">
                    <div
                      onClick={() => {
                        dispatch({ type: "CHANGE_PASSWORD" });
                      }}
                      className="flex justify-center flex-row  bg-[#0A0A0A]
                border-[#0A0A0A]  focus:ring-4 focus:outline-none focus:ring-[#0A0A0A] 
                 text-center rounded border border-stroke py-2 px-6 hover:text-white
                font-medium text-white cursor-pointer  hover:bg-[#888]  hover:shadow-1 "
                    >
                      Change Password
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
