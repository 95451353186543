import React, { useState, useLayoutEffect } from "react";
import { HiExclamationCircle } from "react-icons/hi";
// import { Label, TextInput, Button, Spinner } from "flowbite-react";
import { Spinner, Alert, Button } from "flowbite-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { checkTokenAction, resetPassAction } from "../redux/action/authUser";
import { useDispatch, useSelector } from "react-redux";
import { StoneLoading } from "../components/lmx-loading/loading";
import logo from "../assets/logo/logo.png"
import AuthFooter from "./AuthFooter";
import Navbar from "../components/navbar/Navbar";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  }

  const togglePasswordVisibility2 = () => {
    setIsPasswordVisible2((prevState) => !prevState);
  }
  //  Check token state fetch from redux store ... used for redirection - if user is already logged in
  const {
    loading: tokenChecking,
    checkToken: { message } = {},
    // error: TokenError,
  } = useSelector((state) => state.checkToken);

  // reset password api call state fetch from redux - to check api loading status
  const { loading: resettingPass, resetPass } = useSelector((state) => state.resetPass);

  // State Variables
  const [errorVal, setError] = useState("");

  // on Submitting Form send link to reset password
  const sendresetPass = async (formData) => {
    if (resettingPass) {
      return;
    }
    await dispatch(
      resetPassAction(
        {
          reset_password: true,
          user: formData.email,
          origin: window.location.origin,
        },
        navigate,
        setError
      )
    );
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  // on Submitting Form to change password
  const changePassOnSubmit = async (formData) => {
    if (resettingPass) {
      return;
    }
    await dispatch(
      resetPassAction(
        {
          change_password: true,
          reset_code: params?.reset_code,
          passwd: formData.password,
        },
        navigate,
        setError
      )
    );
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  useLayoutEffect(() => {
    if (message?.toLowerCase().includes("verify")) {
      navigate(`${process.env.PUBLIC_URL}/expense-tracker/auth-verify`);
    }
    if (resetPass?.message?.toLowerCase().includes("successfully")) {
      navigate(`${process.env.PUBLIC_URL}/expense-tracker/auth-login`);
    }
  }, [resetPass, dispatch, navigate]);
  useLayoutEffect(() => {
    secureLocalStorage.getItem("user_pref")?.token &&
      dispatch(checkTokenAction());
  }, [dispatch]);

  return (

    <div className='bg-white'>
      <Navbar />
      <div className="mx-auto  px-4 py-20 sm:px-6 lg:px-4 ">
        <div className="mx-auto max-w-lg -mt-2">
          {/* <div className="flex justify-center flex-row">
            <img className="h-10" src={logo} alt="logo" />
          </div> */}
          {/* <p className="mx-auto mt-5 mb-5 max-w-md text-center text-xl text-gray-900 font-semibold font-poppins">
            Reset our password to access Expense Tracker.<br />
          </p> */}

          <div className="nk-block-middle nk-auth-body  wide-xs">
            {tokenChecking ? (
              <StoneLoading />
            ) : (
              <>
                {params.hasOwnProperty("reset_code") ? (
                  <div className="max-w-4xl mx-auto mt-10 ">
                    <div className="flex flex-col items-center justify-center  space-y-4 antialiased text-gray-900 bg-white ">
                      <div className="mb-0 mt-0 space-y-4 rounded-lg  bg-white p-3 border-2 shadow-xl sm:p-8 lg:p-8">
                        <div className="flex flex-col items-center justify-center">

                          <div className="w-full  ">
                            <div className=" justify-center ">
                              <h1 className=" mb-6 text-3xl font-semibold text-left">
                                Create a New Password
                              </h1>
                              <p className="  text-left font-semibold"></p>
                              {errorVal && (
                                <Alert
                                  icon={HiExclamationCircle}
                                  color="failure"
                                  className="font-medium text-xs rounded-2xl "
                                >
                                  {errorVal}
                                </Alert>
                              )}
                              <form
                                className="is-alter"
                                onSubmit={handleSubmit(changePassOnSubmit)}
                              >

                                <div className="relative">
                                  <label
                                    className="block mb-2 text-sm 
                            font-medium text-gray-900 dark:text-white"
                                    htmlFor="password"
                                  >
                                    New Password
                                  </label>
                                  <input
                                    type={isPasswordVisible ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    {...register("password", {
                                      required: "New Password is required",
                                      minLength: {
                                        value: 8,
                                        message: "Minimum length is 8",
                                      },
                                      maxLength: {
                                        value: 128,
                                        message: "Maximum Length is 128",
                                      },
                                    })}
                                    placeholder="New Password"
                                    className=" border border-gray-300
                            text-gray-900 sm:text-sm rounded-lg
                                focus:ring-primary-600 focus:border-primary-600
                                block w-full p-2.5"
                                  />
                                  <p
                                    className="absolute inset-y-0 right-0 flex items-center px-4 mt-6 text-gray-600"
                                    onClick={togglePasswordVisibility}
                                  >
                                    {isPasswordVisible ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                    )}
                                  </p>
                                  {errors?.password && (
                                    <span className="invalid text-red-600">
                                      {errors.password.message}
                                    </span>
                                  )}
                                </div>


                                <div className="py-1 mb-3">
                                  <div className="relative">
                                    <label
                                      className="block mb-2 text-sm 
                            font-medium text-gray-900 dark:text-white"
                                      htmlFor="confirm_password"
                                    >
                                      Confirm new Password
                                    </label>

                                    <input
                                      type={isPasswordVisible2 ? "text" : "password"}
                                      id="confirm_password"
                                      name="confirm_password"
                                      {...register("confirm_password", {
                                        required: "Confirm New Password",
                                        validate: (val) => {
                                          if (watch("password") !== val) {
                                            return "Passwords do not match";
                                          }
                                        },
                                      })}
                                      placeholder="Confirm New Password"
                                      className=" border border-gray-300
                            text-gray-900 sm:text-sm rounded-lg
                                focus:ring-primary-600 focus:border-primary-600
                                block w-full p-2.5"
                                    />
                                    <p
                                      className="absolute inset-y-0 right-0 mt-6 flex items-center px-4 text-gray-600"
                                      onClick={togglePasswordVisibility2}
                                    >
                                      {isPasswordVisible2 ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                        </svg>
                                      )}
                                    </p>
                                    {errors?.confirm_password && (
                                      <span className="invalid text-red-600">
                                        {errors.confirm_password.message}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="py-1">
                                  <Button
                                    disabled={resettingPass}
                                    size="sm"
                                    className="w-full
                text-white bg-eblack font-medium 
                 rounded-lg text-sm px-5 py-1 hover:bg-gray-900
                 text-center mr-2 mb-2"
                                    type="submit"
                                  >
                                    {resettingPass ? (
                                      <Spinner size="sm" color="light" />
                                    ) : (
                                      "Change Password"
                                    )}
                                  </Button>
                                </div>
                              </form>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (

                  <div className="max-w-4xl mx-auto mt-10 ">
                    <div className="flex flex-col items-center justify-center  space-y-4 antialiased text-gray-900 bg-white ">
                      <div className="mb-0 mt-0 space-y-4 rounded-lg  bg-white p-3 border-2 shadow-xl sm:p-8 lg:p-8">

                        <h1 className=" mb-6 text-3xl font-semibold text-left">
                          Reset Password
                        </h1>
                        <p className="  text-left font-semibold">If you forgot your password, we’ll email you link to reset your password.</p>
                        <div className="w-full  ">
                          <div className="  justify-center ">
                            {errorVal && (
                              <Alert
                                icon={HiExclamationCircle}
                                color="failure"
                                className="font-medium text-xs rounded-2xl "
                              >
                                {errorVal}
                              </Alert>
                            )}
                            <form
                              className="is-alter"
                              onSubmit={handleSubmit(sendresetPass)}
                            >
                              <div className="py-1">
                                <div className="form-label-group">
                                  <label
                                    className="block mb-2 text-sm 
                font-medium text-gray-900 dark:text-white"
                                    htmlFor="email"
                                  >
                                    Email / Username
                                  </label>
                                </div>
                                <div className="form-control-wrap mb-3">
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    {...register("email", {
                                      required: "Email / Username is required",
                                    })}
                                    placeholder="Enter your email / username"
                                    className="bg-gray-50 border border-eblack text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  />
                                  {errors?.email && (
                                    <span className="invalid text-red-600">
                                      {errors.email.message}
                                    </span>
                                  )}
                                </div>

                              </div>

                              <div className="py-1 mb-3">
                                <Button
                                  disabled={resettingPass}
                                  size="sm"
                                  className="w-full
                  text-white bg-eblack border-eblack font-medium 
                   rounded-lg text-sm px-5 py-1.5 hover:bg-gray-900
                   text-center mr-2 mb-2"
                                  type="submit"
                                >
                                  {resettingPass ? (
                                    <Spinner size="sm" color="light" />
                                  ) : (
                                    "Send Password Reset Link"
                                  )}
                                </Button>
                              </div>
                              <div className="text-lg font-semibold text-blue-600 items-center flex justify-center">
                                <Link to="/" className="text-blue-600 cursor-pointer hover:text-blue-500 inline-flex items-center ml-4">
                                  Return to Login</Link>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                )}
              </>
            )}
          </div>


        </div>

      </div>
      {/* <AuthFooter /> */}
    </div>

  );
};
export default ForgotPassword;
