import logo from "../assets/logo/logo1.png";
import { Link } from "react-router-dom";
import travel from "../assets/ExpenseNabar/travel.png";
import monthly from "../assets/ExpenseNabar/monthly.png";
import analytics from "../assets/ExpenseNabar/analytics.png";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Collapse, Dropdown } from "flowbite";
import img from "../assets/ExpenseNabar/expense.jpg";
import { checkDashboardStatusAction } from "../redux/action/authUser";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import pho from "../assets/svg/blue_highlight_bold_03.svg"
import red from "../assets/svg/red_highlight_bold_05.svg"
import blue from "../assets/svg/green_highlight_03.svg"
import sftimg from "../assets/expenseview.png"
import Testimonials from "./Testimonals"; //new testimonal
import AOS from "aos";
import Footer from "../components/Footer/Footer"; //new footer
import TestiDemo from "../components/Testimonals/TestiDemo"; // new testimonal runs perfectly!
import Team from "../components/Team/Team";
import NewTestimonal from "../components/Testimonals/NewTestimonal";
import Contact from "../components/Contact/Contact";
import NewFooter from "../components/Footer/NewFooter";
import Cards from "../components/Cards/Cards";
import sam from "../assets/sam.jpeg"
import KiruFooter from "../components/Footer/KiruFooter";
import Services from "../components/Services/Services";

const HomePage = ({ data }) => {

  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 500,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  const dispatch = useDispatch();
  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
  };
  const target = useRef(null);
  const trigger = useRef(null);
  const otherButton = useRef(null);
  const otherDropdown = useRef(null);
  // const teamButton = useRef(null);
  // const teamDropdown = useRef(null);
  const [bool, setbool] = useState(false);

  //test comment
  //Mobile Navbar func
  const mobile = () => {
    const hammenu = target.current;
    const nav = trigger.current;
    const navoptions = {
      onCollapse: () => { },
      onExpand: () => { },
      onToggle: () => { },
    };
    const mobnav = new Collapse(hammenu, nav, navoptions);
    mobnav.expand();
    mobnav.collapse();
    if (bool === true) {
      mobnav.collapse();
    }
  };
  //Other Dropdown function
  useEffect(() => {
    mobile();
  }, []);

  //Team Dropdown function
  // const teamDrop = () => {
  //   const team = teamButton.current;
  //   const teamdown = teamDropdown.current;

  //   const teamoptions = {
  //     placement: "bottom",
  //     triggerType: "click",
  //     offsetSkidding: 0,
  //     offsetDistance: 10,
  //     delay: 300,
  //     onHide: () => { },
  //     onShow: () => { },
  //     onToggle: () => { },
  //   };
  //   const dropdownTeam = new Dropdown(teamdown, team, teamoptions);
  //   dropdownTeam.show();

  //   dropdownTeam.hide();
  // };

  //Other Dropdown function
  const otherDrop = () => {
    const other = otherButton.current;
    const otherdown = otherDropdown.current;

    const otheroptions = {
      placement: "bottom",
      triggerType: "click",
      offsetSkidding: 0,
      offsetDistance: 10,
      delay: 300,
      onHide: () => { },
      onShow: () => { },
      onToggle: () => { },
    };
    const dropdownother = new Dropdown(otherdown, other, otheroptions);
    dropdownother.show();

    dropdownother.hide();
  };
  useEffect(() => {
    mobile();
    // teamDrop();
    otherDrop();
  }, []);

  const { checkDashboardStatus: { user: { username } = {} } = {} } =
    useSelector((state) => state.checkDashboardStatus);

  useLayoutEffect(() => {
    secureLocalStorage.getItem("pref")?.token &&
      dispatch(checkDashboardStatusAction());
  }, [dispatch]);

  return (
    <div>
      <nav className="bg-white dark:bg-white-900 fixed w-full z-20 p-1.5  top-0 left-0 border-b border-gray-200 dark:border-gray-600  ">
        <div className="xl:container m-auto px-6 md:px-12">
          <div className="flex flex-wrap items-center justify-between gap-6 md:py-3 md:gap-0">
            <div className="w-full flex justify-between lg:w-auto">
              <a
                href={`${process.env.PUBLIC_URL}/`}
                className="flex items-center"
              >
                <img src={logo} className="h-10 mr-4" alt="Expense Tracker" />
              </a>
              <div className="flex md:order-2 overflow-hidden">
                <button
                  data-collapse-toggle="navbar-sticky"
                  id="hammenu"
                  ref={trigger}
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 overflow-visible"
                  aria-controls="navbar-sticky"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="#000"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
              id="navbar-sticky"
              aria-current="page"
              ref={target}
            >
              <div className="w-full space-y-2 border-primary/10 dark:border-gray-700 flex flex-col -ml-1 sm:flex-row lg:space-y-0 md:w-max ">
                <div
                  className="text-black  font-semibold font-poppins block px-4 py-1  
                          text-lg"
                >
                  <button
                    ref={otherButton}
                    id="others"
                    data-dropdown-toggle="dropdownNavbar3"
                    className="flex items-center  w-full  text-black   md:p-0  "
                  >
                    Products{" "}
                    <svg
                      className="w-5 h-5 ml-1"
                      aria-hidden="true"
                      fill="#000"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <div
                    ref={otherDropdown}
                    id="dropdownNavbar3"
                    className="z-40 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                  >
                    <ul className="py-2 " aria-labelledby="dropdownLargeButton">
                      <li>
                        <a
                          href="https://payroll.logimaxindia.com/"
                          target="_blank"
                          className="block px-4 py-2 font-semibold cursor-pointer  hover:bg-black hover:text-white "
                        >
                          Payroll{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://paymentgatewaystesting.vercel.app/"
                          target="_blank"
                          className="block px-4 py-2 font-semibold cursor-pointer  hover:bg-black hover:text-white "
                        >
                          Payments{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://jewellery.logimaxindia.com/"
                          target="_blank"
                          className="block px-4 py-2 font-semibold cursor-pointer  hover:bg-black hover:text-white "
                        >
                          JDMA{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <div
                  className="text-black  font-semibold font-poppins block px-4 py-1 
                          text-lg"
                >
                  <button
                    ref={teamButton}
                    id="others"
                    data-dropdown-toggle="dropdownNavbar2"
                    className="flex items-center  w-full  text-black    md:p-0  "
                  >
                    Company{" "}
                    <svg
                      className="w-5 h-5 ml-1"
                      aria-hidden="true"
                      fill="#000"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <div
                    ref={teamDropdown}
                    id="dropdownNavbar2"
                    className="z-40 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                  >
                    <ul className="py-2 " aria-labelledby="dropdownLargeButton">
                      <li>
                        <a
                          // href="/" target="_blank"
                          className="block px-4 py-2 font-semibold cursor-pointer  hover:bg-black hover:text-white "
                        >
                          About{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://makingminds.vercel.app/about"
                          target="_blank"
                          className="block px-4 py-2 font-semibold cursor-pointer  hover:bg-black hover:text-white "
                        >
                          Our Story{" "}
                        </a>
                      </li>
                      <hr />
                       <li>
                        <a href="/" target="_blank"
                          className="block px-4 py-2 font-semibold cursor-pointer  hover:bg-black hover:text-white ">
                          Resources{" "}
                        </a>
                      </li>
                      <li>
                        <a href="/contact" target="_blank"
                          className="block px-4 py-2 font-semibold cursor-pointer  hover:bg-black hover:text-white ">
                          Contact{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}

                {/* <Link
                  to="/faq"
                  className="text-black  font-semibold font-poppins block px-4 py-1 pl-3 pr-4 
                         text-lg"
                >
                  Help
                </Link> */}

                {/* <Link
                  to="/faq"
                  className="text-white rounded-md bg-eblack p-1 border-eblack  font-semibold font-poppins block px-4 py-1 pl-3 pr-4 
                         text-lg"
                >
                  Try it free
                </Link> */}
                {!!!username ? (
                  <>
                    <Link
                      to="/expense-tracker/create-account"
                      className="text-black  font-semibold border border-black rounded 
                      hover:bg-black hover:text-white font-poppins block  p-1 px-2
                         text-lg"
                    >
                      Get free trail
                    </Link>
                    <Link
                      to="/expense-tracker/auth-login"
                      className="text-black  font-semibold font-poppins block px-4 py-1 pl-3 pr-4 
                         text-lg lg:border-l"
                    >
                      Sign In
                    </Link>


                  </>
                ) : (
                  <Link
                    to="/expense-tracker/"
                    className="text-blue-800  font-semibold font-poppins block px-4 py-1 pl-3 pr-4 
                  text-lg lg:border-l"
                  >
                    Hi, {username}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <section className="text-gray-400 bg-white mt-12">
        <div className="container mx-auto flex px-5 py-6 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-5 md:mb-0 items-center text-center">
            <h1 className="mb-4 text-4xl font-Caveat font-extrabold  text-gray-900 md:text-3xl lg:text-6xl dark:text-white">
              Don't worry about maintaining our
              <span style={{ backgroundImage: `url(${red})` }} className="justify-center rounded-full bg-no-repeat bg-bottom whitespace-nowrap contain nowrap">&nbsp;Expenses</span> .
            </h1>
            <p className="mb-8  text-4xl  font-medium font-Caveat text-black  dark:text-gray-400">
              Simplified , Faster ,<span style={{ backgroundImage: `url(${blue})` }} className="justify-center rounded-full bg-no-repeat bg-bottom whitespace-nowrap contain nowrap">Effective.</span>
              {/* Experience the convenience of simplified online expense
              management, faster expense reporting, and effective cost control. */}
            </p>
            <div className="flex justify-center">
              {!!!username ? (
                <Link
                  to="/expense-tracker/auth-login"
                  className="inline-flex text-white  bg-[#0A0A0A] hover:bg-[#24292F]/90 focus:ring-4  focus:ring-[#24292F]/50 font-medium  text-center  items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2 shadow
                border-2 py-3 px-10  focus:outline-none rounded-lg text-lg  "
                >
                  Start now - It's free{" "}
                </Link>
              ) : (
                <Link
                  to="/expense-tracker/auth-login"
                  className="inline-flex text-white  bg-[#0A0A0A] hover:bg-[#24292F]/90 focus:ring-4  focus:ring-[#24292F]/50 font-medium  text-center  items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2 shadow
                  border-2 py-3 px-10  focus:outline-none rounded-lg text-lg"
                >
                  Access Expense Tracker{" "}
                </Link>
              )}
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="payemntimg"
              src={img}
            />
          </div>
        </div>
        <h3 className="text-center text-3xl mt-10 font-Caveat md:text-3xl lg:text-6xl  mb-5 font-medium text-black ">
          Simplified business expense tracking software Platform.
        </h3>
        <section className="hero container max-w-screen-lg rounded-full mx-auto pb-5 mt-1  flex">

          <img className="lg:mx-auto md:mr-10" src={sftimg} alt="screenshot" />
        </section>
      </section>



      <section className="text-white bg-white">
        <div className="container px-5 py-4 mx-auto">
          <div className="flex flex-col text-center w-full mb-5">
            <h3 className="text-center text-4xl mt-10 font-Caveat md:text-3xl lg:text-6xl mb-5 font-medium text-black ">
              All Expenses in one <span style={{ backgroundImage: `url(${pho})` }} className="justify-center bg-no-repeat bg-right-bottom whitespace-nowrap contain nowrap rounded">Platform</span>.
            </h3>
          </div>
          <div className="flex flex-wrap ">
            <div className="lg:w-1/3 md:w-1/3 p-4 w-full">
              <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                <div className="flex items-left mb-3">
                  <img className="mx-auto w-14" src={travel} alt="travel" />
                </div>
                <h2 className="text-black text-left text-2xl mb-5 font-semibold font-poppins">
                  Travel
                </h2>
                <div className="flex-grow text-center">
                  <h2 className="text-gray-800 text-left text-xl mb-5 font-medium">
                    Manage all our travel expenses with the expense tracker
                    software. Here, you can find the faster expense reporting,
                    and effective cost control.
                  </h2>
                </div>
              </div>
            </div>

            <div className="lg:w-1/3 md:w-1/3 p-4 w-full">
              <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                <div className="flex items-left mb-3">
                  <img className="mx-auto w-14" src={monthly} alt="monthly" />
                </div>
                <h2 className="text-black text-left text-2xl mb-5 font-semibold font-poppins">
                  Expense{" "}
                </h2>
                <div className="flex-grow text-center">
                  <h2 className="text-gray-800 text-left text-xl mb-5 font-medium">
                    Manage all our Monthly expenses and our salary expenses with
                    the expense tracker software. Here, you can find the faster
                    expense reporting, and effective cost control.
                  </h2>
                </div>
              </div>
            </div>

            <div className="lg:w-1/3 md:w-1/3 p-4 w-full">
              <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                <div className="flex items-left mb-3">
                  <img
                    className="mx-auto w-14"
                    src={analytics}
                    alt="analytics"
                  />
                </div>
                <h2 className="text-black text-left text-2xl mb-5 font-semibold font-poppins">
                  Analytics
                </h2>
                <div className="flex-grow text-center">
                  <h2 className="text-gray-800 text-left text-xl mb-5 font-medium">
                    Manage all our expenses with the expense tracker software.
                    Here, you can find the analytics report and monthly reports
                    faster expense reporting, and effective cost control.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#0A0A0A] py-16 ">
        <div className="container m-auto px-6 text-center md:px-12 lg:px-20">
          <h2 className="mb-5 text-4xl font-semibold font-poppins text-white md:text-4xl">
            Ready to do your best work?
          </h2>
          <h2 className="mb-8 text-3xl font-medium  text-white md:text-4xl">
            Let's get you started.
          </h2>
          <a
            href="#"
            className="relative flex h-12 w-full mx-auto items-center 
                        justify-center px-8 before:absolute before:inset-0 before:rounded
                         before:bg-blue-700 before:transition before:duration-300 hover:before:scale-105 
                         active:duration-75 active:before:scale-95 sm:w-max"
          >
            <Link
              to="/expense-tracker/auth-login"
              className="relative text-xl flex font-semibold text-white "
            >
              Get Started
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 7L15 12L10 17"
                  stroke="#fff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </a>
        </div>
      </section>

      <footer className="bg-white dark:bg-gray-800  border-2 bottom-0   ">
        <div className="mx-auto py-7 text-center">
          <div className="flex flex-row justify-center  mt-2 mb-4 space-x-5 sm:justify-center md:mt-0">
            <a href="/" target="_blank" rel="noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 50 50"
              >
                <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"></path>
              </svg>
            </a>
            <a
              href="mailto:teammakingminds@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 50 50"
              >
                <path d="M12 23.403V23.39 10.389L11.88 10.3h-.01L9.14 8.28C7.47 7.04 5.09 7.1 3.61 8.56 2.62 9.54 2 10.9 2 12.41v3.602L12 23.403zM38 23.39v.013l10-7.391V12.41c0-1.49-.6-2.85-1.58-3.83-1.46-1.457-3.765-1.628-5.424-.403L38.12 10.3 38 10.389V23.39zM14 24.868l10.406 7.692c.353.261.836.261 1.189 0L36 24.868V11.867L25 20l-11-8.133V24.868zM38 25.889V41c0 .552.448 1 1 1h6.5c1.381 0 2.5-1.119 2.5-2.5V18.497L38 25.889zM12 25.889L2 18.497V39.5C2 40.881 3.119 42 4.5 42H11c.552 0 1-.448 1-1V25.889z"></path>
              </svg>
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              <svg
                width="30"
                height="30"
                fill="#000"
                className="text-xl transition-colors text-black duration-200 hover:text-black dark:hover:text-black"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
              </svg>
            </a>
          </div>
          <div className="flex flex-row justify-center items-center mt-5 text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-0 mb-5">
            <a
              href={`${process.env.PUBLIC_URL}/`}
              className="mr-4 hover:text-blue-600 md:mr-6 "
            >
              {" "}
              Home{" "}
            </a>

            <Link
              to="https://makingminds.vercel.app/contact"
              target="_blank"
              rel="noreferrer"
              className="mr-4 hover:text-blue-600 md:mr-6 "
            >
              {" "}
              Contact{" "}
            </Link>

            <Link
              to="https://makingminds.vercel.app/about"
              target="_blank"
              rel="noreferrer"
              className="mr-4 hover:text-blue-600 md:mr-6 "
            >
              About
            </Link>

            <Link
              to="https://makingminds.vercel.app/makingminds/products"
              target="_blank"
              rel="noreferrer"
              className="mr-4 hover:text-blue-600 md:mr-6 "
            >
              Products
            </Link>

            <Link
              to="https://makingminds.vercel.app/legal/terms-conditions"
              target="_blank"
              rel="noreferrer"
              className="mr-4 hover:text-blue-600 md:mr-6 "
            >
              Terms
            </Link>

            <Link
              to="https://makingminds.vercel.app/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="mr-4 hover:text-blue-600 md:mr-6  "
            >
              Privacy Policy
            </Link>
          </div>
          <span className="text-sm   text-gray-500 sm:text-center font-medium dark:text-gray-400">
            {" "}
            © {getYear()} Expense Tracker - All Rights Reserved.
          </span>
        </div>
      </footer>


      {/* <TestiDemo /> */}

      {/* <Footer/> */}

      {/* <Testimonials/> */}

      {/* <Team /> */}

      {/* <NewTestimonal /> */}

      {/* <Contact /> */}

      {/* <NewFooter/>  */}

      {/* <Cards/> */}

      {/* <KiruFooter /> */}

      {/* <Services /> */}


      {/* <body class="bg-blue-500">
        <nav class="relative px-4 py-4 flex justify-between items-center bg-white">

          <ul class="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
            <li><a class="text-sm text-gray-400 hover:text-gray-500" href="#">Home</a></li>
            <li class="text-gray-300">

            </li>
            <li><a class="text-sm text-blue-600 font-bold" href="#">About Us</a></li>
            <li class="text-gray-300">

            </li>
            <li><a class="text-sm text-gray-400 hover:text-gray-500" href="#">Services</a></li>
            <li class="text-gray-300">

            </li>
            <li><a class="text-sm text-gray-400 hover:text-gray-500" href="#">Pricing</a></li>
            <li class="text-gray-300">

            </li>
            <li><a class="text-sm text-gray-400 hover:text-gray-500" href="#">Contact</a></li>
          </ul>

        </nav>

      </body> */}



      <footer className="bg-gray-100  dark:bg-gray-800  border-2 bottom-0    ">
        <div className=" mx-auto p-3 text-center">
          <span className="text-sm text-gray-500 sm:text-center font-medium dark:text-gray-400">
            Designed and Developed by&nbsp;
            <a
              href="https://makingminds.vercel.app/"
              className="text-black hover:underline"
            >
              Making Minds ❤️
            </a>
          </span>
        </div>
      </footer>
    </div >
  );
};

export default HomePage;
