import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import {
  ButtonGroup,
  Form,
  FormGroupSingle,
  Icon,
  Row,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { checkDashboardStatusAction } from "../../redux/action/authUser";
import { addExpenseDetailsAction } from "../../redux/action/expense";
import store from "../../redux/store";
import { toastsuccess } from "../../components/lmx-toast-style/toast-style";
import moment from "moment";

const ExpenseModel = ({ state, dispatch }) => {
  const redux_dispatch = useDispatch();
  const { checkLoginDetails } = useSelector((state) => state.loginDetailsCheck);
  const navigate = useNavigate();

  const { loading: issubmitting } = useSelector(
    (state) => state.addExpenseDetails
  );
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    dispatch(checkDashboardStatusAction());
  }, []);

  const saveData = async (data) => {
    const adddata = {
      expense_name: data.expense_name,
      expense_description: data.expense_description,
      expense_date: moment(data.expense_date).format("YYYY-MM-DD"),
      amount: data.amount,
      year: new Date().getFullYear(),
      month: new Date().toLocaleString("default", { month: "long" }),
    };
    redux_dispatch(addExpenseDetailsAction(adddata, navigate));
    if (store.getState().addExpenseDetails.hasOwnProperty("error") == false) {
      toastsuccess("Expense details added Successfully");
      reset({
        expense_name: "",
        expense_description: "",
        expense_date: "",
        amount: "",
      });
      dispatch({ type: "CLOSE_MODAL" });
    }
  };
  return (
    <React.Fragment>
      <Modal
        className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem] justify-center"
        show={state.showModal}
        size="xl"
        popup={true}
        onClose={() => {
          dispatch({ type: "CLOSE_MODAL" });
        }}
      >
        <Modal.Header />
        <Modal.Body key={"expense_model"}>
          <div className="space-y-6 ">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Add a Expense for{" "}
              {new Date().toLocaleString("default", { month: "long" })} {"-"}
              {new Date().getFullYear()}
            </h3>

            <Form>
              <Row>
                <FormGroupSingle>
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-black dark:text-white"
                      htmlFor="expensename"
                    >
                      Expense Name
                    </label>
                    <input
                      {...register("expense_name", {
                        required: "expense name is  required",
                        maxLength: {
                          value: 100,
                          message: "Max Length is 100",
                        },
                      })}
                      type="text"
                      id="expense_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="expense_name"
                      placeholder="Enter expense name"
                      defaultValue={null}
                    />
                    {errors?.expense_name && (
                      <span className="text-red-600">
                        <Icon className={"sm"} name="alert-circle" />
                        {errors.expense_name.message}
                      </span>
                    )}
                  </div>
                </FormGroupSingle>

                <FormGroupSingle>
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-black dark:text-white"
                      htmlFor="expense_description"
                    >
                      Description
                    </label>
                    <input
                      {...register("expense_description", {
                        required: "description  is  required",
                        maxLength: {
                          value: 100,
                          message: "Max Length is 100",
                        },
                      })}
                      type="text"
                      id="expense_description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="expense_description"
                      placeholder="Enter description "
                      defaultValue={null}
                    />
                    {errors?.expense_description && (
                      <span className="text-red-600">
                        <Icon className={"sm"} name="alert-circle" />
                        {errors.expense_description.message}
                      </span>
                    )}
                  </div>
                </FormGroupSingle>
              </Row>

              <Row>
                <FormGroupSingle>
                  <div className="relative sm:mb-0 flex-grow w-full">
                    <label
                      htmlFor="expense_date"
                      className="block mb-2 text-sm font-medium text-gray-900
                     dark:text-white"
                    >
                      Expense Date
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300
                     text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500
                    block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="expense_date"
                      type="date"
                      {...register("expense_date", {
                        valueAsDate: true,
                      })}
                    />
                    {/* <DatePicker
                      {...register("expense_date")}
                      id="expense_date"
                      className="bg-gray-50 border border-gray-300
                      text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500
                     block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                      dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="expense_date"
                    //   defaultValue={new Date()}
                    //   selected="expense_date"
                    //   selected={expense_date}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      withPortal
                      portalId="root-portal"
                      dateFormat="dd/MM/yyyy"
                      placeholder="Date of Birth"
                    /> */}
                    {/* {errors?.expense_date && (
                      <span className="text-danger text-red-600 text-sm">
                        <Icon className={"sm"} name="alert-circle" />
                        {errors.expense_date.message}
                      </span>
                    )} */}
                  </div>
                </FormGroupSingle>

                <FormGroupSingle>
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-black dark:text-white"
                      htmlFor="amount"
                    >
                      Amount
                    </label>
                    <input
                      {...register("amount", {
                        required: "amount  is  required",
                        maxLength: {
                          value: 100,
                          message: "Max Length is 100",
                        },
                        pattern: {
                          value: /^([0-9])/,
                          message: "Invalid Amount",
                        },
                      })}
                      type="text"
                      id="amount"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="amount"
                      placeholder="Enter amount "
                      defaultValue={null}
                    />
                    {errors?.amount && (
                      <span className="text-red-600">
                        <Icon className={"sm"} name="alert-circle" />
                        {errors.amount.message}
                      </span>
                    )}
                  </div>
                </FormGroupSingle>
              </Row>

              <ButtonGroup>
                <button
                  type="button"
                  disabled={issubmitting}
                  className="flex justify-center mb-2 ml-4 md:ml-2 text-white bg-red-500
                        hover:bg-red-600 focus:ring-4 focus:ring-blue-300 
                        font-medium rounded-lg text-sm px-5 py-2.5 mr-2  
                        dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none
                        dark:focus:ring-red-800"
                  onClick={() => {
                    dispatch({ type: "CLOSE_MODAL" });
                  }}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  disabled={issubmitting}
                  className="flex justify-center mb-2 ml-4 md:ml-2 text-white bg-green-500
                        hover:bg-green-800 focus:ring-4 focus:ring-blue-300 
                        font-medium rounded-lg text-sm px-5 py-2.5 mr-2  
                        dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none
                        dark:focus:ring-green-800"
                  onClick={handleSubmit(saveData)}
                >
                  {issubmitting ? "Saving" : "Save"}
                </button>
              </ButtonGroup>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ExpenseModel;
