import React, { useState, useEffect } from "react";
import { Form, FormGroup, Spinner, Alert, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  checkDashboardStatusAction,
  logoutUserAction,
  sendOTPAction,
  verifyOTPAction,
} from "../redux/action/authUser";
import { useDispatch, useSelector } from "react-redux";
import Loading, { Loader } from "../components/lmx-loading/winbull-loader";
import lblack from "../assets/logo/logo.png";
import { Icon } from "../components/Component";
const AuthVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //  Check token state fetch from redux store ...
  const { loading: tokenChecking } = useSelector((state) => state.checkToken);
  const { loading: sendingOTP } = useSelector((state) => state.sendOTP);
  const { loading: verifyingOTP } = useSelector((state) => state.verifyOTP);
  const { checkDashboardStatus: { user } = {}, loading: loadingUser } =
    useSelector((state) => state.checkDashboardStatus);
  const [errorVal, setError] = useState("");
  const otherAccLogin = async () => {
    await dispatch(logoutUserAction());
    await dispatch({ type: "CHECK_TOKEN_SUCCESS", payload: {} });
    secureLocalStorage.clear();
    navigate(`${process.env.PUBLIC_URL}/expense-tracker/auth-login`);
  };

  const onFormSubmit = async (formData) => {
    await dispatch(
      verifyOTPAction({
        email: user?.email,
        email_otp: formData.email_otp,
      })
    );
    setTimeout(() => {
      setError("");
    }, 4000);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   if (user?.email_verified) {
  //     navigate(`${process.env.PUBLIC_URL}/expense-tracker`);
  //   } else {
  //     dispatch(
  //       sendOTPAction({
  //         otp_for: 2,
  //         email: user?.email,
  //       })
  //     );
  //   }
  // }, [user]);

  useEffect(() => {
    if (user?.email_verified) {
      navigate(`${process.env.PUBLIC_URL}/expense-tracker`);
    } else {
      user?.email && dispatch(
        sendOTPAction({
          otp_for: 2,
          email: user?.email,
        })
      );
    }
  }, [user?.email]);

  useEffect(() => {
    if (!!!secureLocalStorage.getItem("pref")) {
      navigate(`${process.env.PUBLIC_URL}/expense-tracker/auth-login`);
    } else {
      const loginpref = secureLocalStorage.getItem("pref").pref;
      dispatch(checkDashboardStatusAction(loginpref));
    }
  }, []);

  return (
    <React.Fragment>
      <div className="bg-white">
        <div className="mx-auto  px-4 py-20 sm:px-6 lg:px-4 ">
          <div className="mx-auto max-w-lg -mt-2">
            <div className="flex justify-center flex-row">
              <img src={lblack} alt="login" className="h-10  " />
            </div>
            <p className="mx-auto mt-5 mb-5 max-w-md text-center text-xl text-gray-900 font-semibold font-poppins">
              Verify your Email Address.
              <br />
            </p>
            {typeof loadingUser === "undefined" || loadingUser ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <div className="mb-0 mt-0 space-y-4 rounded-lg bg-white p-3 border-2 shadow-xl sm:p-8 lg:p-8">
                  <h4 className="text-left text-gray-700 text-lg font-semibold">
                    Verify your Email
                  </h4>{" "}
                  <div className="flex ">
                    <p className="font-semibold text-gray-800 text-sm">
                      {user?.email}{" "}
                    </p>

                  </div>
                  {errorVal && (
                    <div className="mb-3">
                      <Alert color="danger" className="alert-icon">
                        {" "}
                        <Icon name="alert-circle" /> {errorVal}{" "}
                      </Alert>
                    </div>
                  )}
                  <Form
                    className="is-alter"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <div className="flex">
                        <div className="form-label-group">
                          <label
                            htmlFor="default-01"
                            className="block mb-2 text-left
               text-sm font-medium text-black dark:text-white"
                          >
                            {" "}
                            One Time Verification Code
                          </label>

                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            !!verifyingOTP || !!sendingOTP ? null : otherAccLogin()
                          }
                          className="justify-end text-blue-700 font-medium text-sm ml-20"
                        >
                          Login using another account
                        </div>
                      </div>
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                          id="default-01"
                          name="email_otp"
                          placeholder="Enter recieved verification code"
                          {...register("email_otp", {
                            required: "Enter Code",
                            maxLength: {
                              value: 6,
                              message: "Max Length is 6",
                            },
                            minLength: {
                              value: 6,
                              message: "Min Length is 6",
                            },
                          })}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                        />
                        {errors.email_otp && (
                          <span className="invalid text-red-600 text-left">
                            {errors.email_otp.message}
                          </span>
                        )}
                      </div>
                    </FormGroup>

                    <div className="form-label-group">
                      <div className="grid grid-cols-1">
                        {sendingOTP ? (
                          <div>
                            <span
                              className="text-green-500 text-left"
                              style={{ cursor: "default" }}
                            >
                              {" "}
                              Sending Code ...
                            </span>
                          </div>
                        ) : (
                          <div>
                            <span
                              className="text-blue-700 justify-end text-right"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                !!verifyingOTP || !!sendingOTP
                                  ? null
                                  : dispatch(
                                    sendOTPAction({
                                      otp_for: 2,
                                      email: user?.email,
                                    })
                                  )
                              }
                              to={`${process.env.PUBLIC_URL}#send_otp`}
                            >
                              Resend Verification Code
                            </span>
                          </div>
                        )}{" "}
                      </div>
                    </div>

                    <div className="flex"></div>
                    <FormGroup className="mt-2">
                      <Button
                        disabled={!!verifyingOTP || !!sendingOTP}
                        onClick={
                          !!verifyingOTP || !!sendingOTP
                            ? null
                            : handleSubmit(onFormSubmit)
                        }
                        size="lg"
                        className="w-full
                        text-white bg-[#1C64F2] font-medium 
                         rounded-lg text-sm px-5 py-2.5 
                          text-center mr-2 mb-2"
                        type="submit"
                      >
                        {!!verifyingOTP || !!sendingOTP ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AuthVerify;
