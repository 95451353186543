import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import {
  ButtonGroup,
  Form,
  FormGroupSingle,
  Icon,
  Row,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { checkDashboardStatusAction } from "../../redux/action/authUser";
import { addMonthlySalaryAction } from "../../redux/action/expense";
import store from "../../redux/store";
import { toastsuccess } from "../../components/lmx-toast-style/toast-style";
import moment from "moment";

const Salary_Modal = ({ state, dispatch }) => {
  const redux_dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );
  const { loading: issubmitting } = useSelector(
    (state) => state.addMonthlySalary
  );

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const id = user?.id;
  useEffect(() => {
    dispatch(checkDashboardStatusAction());
  }, []);

  const saveData = async (data) => {
    const adddata = {
      salary: data.salary_amount,
      salary_date: moment(data.salary_date).format("YYYY-MM-DD"),
      year: new Date().getFullYear(),
      month: new Date().toLocaleString("default", { month: "long" }),
    };
    redux_dispatch(addMonthlySalaryAction(adddata, navigate));
    if (store.getState().addMonthlySalary.hasOwnProperty("error") == false) {
      toastsuccess("Salary details added Successfully");
      reset({
        salary_amount: "",
      });
      dispatch({ type: "CLOSE_MODAL" });
    }
  };
  return (
    <React.Fragment>
      <Modal
        className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem] justify-center"
        show={state.showModalA}
        size="xl"
        popup={true}
        onClose={() => {
          dispatch({ type: "CLOSE_MODAL" });
        }}
      >
        <Modal.Header />
        <Modal.Body key={"expense_model"}>
          <div className="space-y-6 ">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Add New Salary amount for{" "}
              {new Date().toLocaleString("default", { month: "long" })} {"-"}
              {new Date().getFullYear()}
            </h3>

            <Form>
              <Row>
                <FormGroupSingle>
                  <div>
                    <label
                      className="mb-1 block text-sm font-medium text-black dark:text-white"
                      htmlFor="salary_amount"
                    >
                      Salary amount
                    </label>
                    <input
                      {...register("salary_amount", {
                        required: "salary amount is  required",
                        maxLength: {
                          value: 100,
                          message: "Max Length is 100",
                        },
                        pattern: {
                          value: /^([0-9])/,
                          message: "Invalid Salary",
                        },
                      })}
                      type="text"
                      id="salary_amount"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="salary_amount"
                      placeholder="Enter salary amount"
                      defaultValue={null}
                    />
                    {errors?.salary_amount && (
                      <span className="text-red-600">
                        <Icon className={"sm"} name="alert-circle" />
                        {errors.salary_amount.message}
                      </span>
                    )}
                  </div>
                </FormGroupSingle>
              </Row>

              <Row>
                <FormGroupSingle>
                  <div className="relative sm:mb-0 flex-grow w-full">
                    <label
                      htmlFor="salary_date"
                      className="block mb-2 text-sm font-medium text-gray-900
                     dark:text-white"
                    >
                      Salary Date
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300
                     text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500
                    block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="salary_date"
                      type="date"
                      {...register("salary_date", {
                        valueAsDate: true,
                      })}
                    />
                  </div>
                </FormGroupSingle>
              </Row>
              <ButtonGroup>
                <button
                  type="button"
                  disabled={issubmitting}
                  className="flex justify-center mb-2 ml-4 md:ml-2 text-white bg-red-500
                      hover:bg-red-600 focus:ring-4 focus:ring-blue-300 
                      font-medium rounded-lg text-sm px-5 py-2.5 mr-2  
                      dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none
                      dark:focus:ring-red-800"
                  onClick={() => {
                    dispatch({ type: "CLOSE_MODAL" });
                  }}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  disabled={issubmitting}
                  className="flex justify-center mb-2 ml-4 md:ml-2 text-white bg-green-500
                      hover:bg-green-800 focus:ring-4 focus:ring-blue-300 
                      font-medium rounded-lg text-sm px-5 py-2.5 mr-2  
                      dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none
                      dark:focus:ring-green-800"
                  onClick={handleSubmit(saveData)}
                >
                  {issubmitting ? "Saving" : "Save"}
                </button>
              </ButtonGroup>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Salary_Modal;
