import React from "react";
import { useSelector } from "react-redux";
import Head from "../../components/Head/Head";
import Transaction from "./Transaction/Transaction";
import RecentExpense from "./Recent/RecentExpense";

const Dashboard = () => {
  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );

  return (
    <>
      <Head title="Dashboard" />
      <div className="flex flex-col mt-2 gap-4 m-2">
        <h3 className="font-semibold text-2xl   font-poppins text-[EDEDED]  ">
          {" "}
          Monthly Report
        </h3>
        <div className="flex flex-row gap-4 w-full">
          <Transaction />
        </div>
        {/* <div className="grid sm:grid-cols-2 gap-6 lg:grid-cols-1  px-3">
          <div className="max-w-full flex-col flex text-center ">
            <div className="flex flex-row gap-4 w-full">
              <Transaction />
              <RecentExpense />
            </div>
          </div>
        </div> */}
      </div>

      {/* <Card className="m-2">
        <div className="flex items-center col-span-12  ">
          <h2 className="  text-xl font-medium ">
            Economy Graph{" "}
            <span className="text-xs">(Based on Previous Months) </span>
          </h2>
        </div>
        <div className="grid sm:grid-cols-2 gap-6 lg:grid-cols-1  px-3">
          <div className="max-w-full flex-col flex text-center ">

            <ResponsiveContainer width={"100%"} height={200}>
              <LineChart barCategoryGap={0} data={expenseGraph}>
                <Line
                  name="Credits"
                  type="monotone"
                  dataKey="credit"
                  stroke="#12611f"
                />
                <Line
                  name="Expense"
                  type="monotone"
                  dataKey="expense"
                  stroke="#1c6585"
                />


                <XAxis angle={-10} fontSize={10} dataKey="month" />
                <YAxis angle={-10} fontSize={10} dataKey="salary" />
                <Tooltip />
                <Legend align="center" verticalAlign="bottom" />
                <CartesianGrid strokeDasharray="2 2" stroke="#e8e6e6" />
              </LineChart>
            </ResponsiveContainer>

          </div>

          <div className="max-w-full flex-col flex text-center ">
            <div className="flex items-center col-span-12  mb-4">
              <h2 className="  text-xl font-medium ">
                Expense Graph{" "}
                <span className="text-xs">(Based on Previous Months) </span>
              </h2>
            </div>
            <Card>
              <ResponsiveContainer width={"100%"} height={200}>
              
                <RadarChart data={expenseGraph}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="month" />
                  <PolarRadiusAxis angle={30} domain={[0, 150]} />
                  <Radar
                    name="Expense"
                    dataKey="expense"
                    stroke="#8884d8"
                    fill="#8884d8"
                    fillOpacity={0.6}
                  />
                  <Radar
                    name="Credits"
                    dataKey="credit"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                    fillOpacity={0.6}
                  />
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
            </Card>
          </div>
        </div>
      </Card> */}
    </>
  );
};

export default Dashboard;
