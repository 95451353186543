import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicLayout from "./pages/PublicLayout";
import EventBus from "./common/EventBus";
import "react-toastify/dist/ReactToastify.css";
import {
  ToastStyle,
  toastfunc,
} from "./components/lmx-toast-style/toast-style";
import { ToastContainer } from "react-toastify";
// AUTH
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import AuthVerify from "./auth/AuthVerify";
//ADMINAREA
import ExpenseTracker from "./pages/admin/ExpenseTracker";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings"
// OTHER PAGES
import Page404 from "./components/Page404/Page404";
import Page503 from "./components/Page503/Page503";
import Terms from "./pages/Others/Terms";
import Faq from "./pages/Others/Faq";
import Contact from "./pages/Others/Contact";
//EXPENSE TRACKER


//EXPEMSE TRACKER FILE
import Expense from "./pages/ExpenseTracker/Expense";
import Register from "./auth/Register";
import History from "./pages/ExpenseTracker/History/History";
import DownloadHistory from "./pages/ExpenseTracker/History/DownloadHistory";
import HomePage from "./pages/HomePage";
import Analytics from "./pages/ExpenseTracker/Analytics/Analytics";

export const ScrollMemory = ({ children }) => {
  useEffect(() => {
    // Restore the scroll position to top component mounts
    window.scrollTo(0, 0);
  }, []);

  return children;
};

const App = () => {
  //
  useEffect(() => {
    //
    EventBus.on("server_down", () => {
      window.history.replaceState(
        { from: window.location.pathname },
        null,
        `/503`
      );
      window.location.reload();
    });
    //
    EventBus.on("error", (errs) => {
      errs?.map((err) => toastfunc(err));
    });

    // clear Events
    return () => {
      EventBus.remove("error");
      EventBus.remove("server_down");
    };
  }, []);
  //
  return (
    <div>
      <Routes>
        <Route path="" element={<PublicLayout />}>
          <Route exact path={`${process.env.PUBLIC_URL}/`} element={<HomePage />}> </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/auth-login`} element={<Login />}> </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/create-account`} element={<Register />}> </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/auth-verify`} element={<AuthVerify />}> </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/reset_password`} element={<ForgotPassword />} />
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/reset_password/confirm_reset/:reset_code`} element={<ForgotPassword />} />
          <Route exact path={`${process.env.PUBLIC_URL}/terms`} element={<Terms />} />
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} element={<Faq />} />
          <Route exact path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} />
        </Route>
        {/* Private Route for Member */}
        <Route path="expense-tracker" element={<ExpenseTracker />}>
          <Route index element={<Dashboard />}></Route>
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/profile`} element={<Profile />}></Route>
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/settings`} element={<Settings />}></Route>
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/terms`} element={<Terms />} />
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/faq`} element={<Faq />} />
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/contact`} element={<Contact />} />
          {/* {Expense Tracker} */}
          {/* {EXPENSE TRACKER FILE} */}
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/expense`} element={<Expense />} />
          {/* {HISTORY} */}
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/history`} element={<History />} />
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/download`} element={<DownloadHistory />} />
          {/* Analytics */}
          <Route exact path={`${process.env.PUBLIC_URL}/expense-tracker/analytics`} element={<Analytics />} />




          {/* 404 page inside Admin Section */}
          <Route path="*" element={<Dashboard />} />
        </Route>
        {/* Error 404 Page */}
        <Route path="*" element={<Page404 />} />
        {/* Error 404 Page */}

        {/* Error 503 Page */}
        <Route path="/503" element={<Page503 />} />
        {/* Error 503 Page */}
      </Routes>
      <ToastStyle>
        <ToastContainer />
      </ToastStyle>
    </div>
  );
};

export default App;
