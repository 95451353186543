import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, Spinner } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeProfilePasswordAction,
  checkDashboardStatusAction,
  editProfileAction,
} from "../../redux/action/authUser";
import Icon from "../../components/icon/Icon";
import store from "../../redux/store";
import { toastsuccess } from "../../components/lmx-toast-style/toast-style";
import { useNavigate } from "react-router-dom";

const ModalComp = ({ state, dispatch }) => {
  const redux_dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register: registerA,
    handleSubmit: handleSubmitA,
    getValues,
    reset: resetA,
    formState: { errors: errorsA },
  } = useForm();
  const { loading: isChanging } = useSelector(
    (state) => state.changeProfilePassword
  );
  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };
  const togglePasswordVisibility2 = () => {
    setIsPasswordVisible2((prevState) => !prevState);
  };
  const submitForm = (data) => {
    redux_dispatch(changeProfilePasswordAction(data, navigate));
    if (
      store.getState().changeProfilePassword.hasOwnProperty("error") == false
    ) {
      toastsuccess("Password changed Successfully");
      resetA({
        old_password: "",
        new_password: "",
        new_password2: "",
      });
      dispatch({ type: "CLOSE_MODAL" });
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem]"
        show={state.showModal}
        size="lg"
        popup={true}
        onClose={() => {
          resetA({
            old_password: "",
            new_password: "",
            new_password2: "",
          });
          dispatch({ type: "CLOSE_MODAL" });
        }}
      >
        <Modal.Header />
        <Modal.Body key={"change_password"}>
          <div className="space-y-6 ">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Change Password
              <p className="text-sm text-gray-600 font-medium mb-2">
                Set a strong password to prevent unauthorized access to your
                account.
              </p>
            </h3>
            <div>
              <div className="mb-0">
                <label
                  className="mb-1 block text-sm font-medium text-black dark:text-white"
                  htmlFor="old_password"
                >
                  Old password
                </label>

                <input
                  {...registerA("old_password", {
                    required: " Old Password is required",
                  })}
                  type="password"
                  id="old_password"
                  className="w-full rounded mb-2 border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="old_password"
                  defaultValue={null}
                  placeholder="Enter Current Password"
                />
                {errorsA?.old_password && (
                  <span className="text-red-600">
                    <Icon className={"sm"} name="alert-circle" />
                    {errorsA.old_password.message}
                  </span>
                )}
              </div>

              <div className="mb-0">
                <label
                  className="mb-1 block text-sm font-medium text-black dark:text-white"
                  htmlFor="new_password"
                >
                  New Password
                </label>
                <div className="relative">
                  <input
                    {...registerA("new_password", {
                      validate: (val) => {
                        if (getValues().old_password == val) {
                          return "Old password and New password can't be same";
                        }
                      },
                      required: "New Password is required",
                      minLength: {
                        value: 8,
                        message: "Min Length is 8",
                      },
                      maxLength: {
                        value: 128,
                        message: "Max Length is 128",
                      },
                    })}
                    type={isPasswordVisible ? "text" : "password"}
                    id="new_password"
                    className="w-full mb-2 rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="new_password"
                    defaultValue={null}
                    placeholder="Enter New Password"
                  />
                  <p
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </p>
                  {errorsA?.new_password && (
                    <span className="text-red-600">
                      <Icon className={"sm"} name="alert-circle" />
                      {errorsA.new_password.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="w-full ">
                <label
                  className="mb-1 block text-sm font-medium text-black dark:text-white"
                  htmlFor="new_password2"
                >
                  Re-Enter New password
                </label>
                <div className="relative">
                  <input
                    {...registerA("new_password2", {
                      validate: (val) => {
                        if (getValues().new_password != val) {
                          return "Passwords do no match";
                        }
                      },
                    })}
                    type={isPasswordVisible2 ? "text" : "password"}
                    id="new_password2"
                    className="w-full mb-3 rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black  focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="new_password2"
                    defaultValue={null}
                    placeholder="Re-Enter New Password"
                  />
                  <p
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={togglePasswordVisibility2}
                  >
                    {isPasswordVisible2 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </p>
                  {errorsA?.new_password2 && (
                    <span className="text-red-600">
                      <Icon className={"sm"} name="alert-circle" />
                      {errorsA.new_password2.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-0">
              <Button
                size="sm"
                className="flex justify-center rounded border cursor-pointer 
                  border-stroke py-0  font-medium text-white bg-red-500 hover:shadow-1
                  hover:bg-red-600"
                disabled={isChanging}
                onClick={() => {
                  dispatch({ type: "CLOSE_MODAL" });
                }}
              >
                Cancel
              </Button>
              <Button
                className="flex justify-center rounded border cursor-pointer 
                border-stroke py-0 font-medium text-white bg-green-500 hover:shadow-1
                hover:bg-green-600"
                disabled={isChanging}
                onClick={handleSubmitA(submitForm)}
                size="sm"
              >
                {isChanging ? <Spinner size="sm" /> : " Change Password"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ModalComp;
