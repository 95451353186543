import React from 'react'

export const Form = ({ ...props }) => {
    return (
        <div className="overflow-x-auto  p-3 ">
            {props.children}
        </div>
    )
}

export const Row = ({ ...props }) => {
    return (
        <div className="flex lg:w-3/3 w-full sm:flex-row flex-col mx-auto  mb-2 
         sm:px-0 items-end sm:space-x-4 sm:space-y-0 space-y-4">
            {props.children}
        </div>
    )
}

export const FormGroup = ({ ...props }) => {
    return (
        <div className="relative sm:mb-0 flex-grow w-full">
            {props.children}
        </div>
    )
}

export const FormGroupSingle = ({ ...props }) => {
    return (
        <div className="w-full relative">
            {props.children}
        </div>
    )
}