import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Head from '../../components/Head/Head';
import address from "../../assets/profile/address.png"
import location from "../../assets/profile/session.png"
import { useReducer } from 'react';
import InfoModel from '../Models/InfoModel';
import moment from 'moment';



const initialStateA = { showModalA: false };

const reducerA = (stateA, actionA) => {
  switch (actionA.type) {
    case "ACCOUNT_ACTIVITY":
      return { mode: "account_activity", showModalA: true };
    case "ACCOUNT_DETAILS":
      return { mode: "account_details", showModalA: true };
    case "CLOSE_MODAL":
      return { showModal: false };
    default:
      return stateA;
  }
};
const Settings = () => {
  const [stateA, dispatchA] = useReducer(reducerA, initialStateA);
  const { checkLoginDetails } = useSelector((state) => state.loginDetailsCheck);

  return (
    <div>
      <Head title="Settings" />
      <InfoModel state={stateA} dispatch={dispatchA} />
      <h3 className="font-semibold text-2xl mt-3  font-poppins text-[EDEDED] px-4 dark:text-white ">
        {" "}
        Sessions
      </h3>
      <div className="col-span-6 xl:col-span-3 m-4 mt-3">
      
        <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke dark:border-strokedark">
            <div className=" py-6 px-4">
              <h3 className="font-semibold text-xl text-black dark:text-white mb-2">Active Sessions</h3>
              <p className="text-sm text-gray-600 font-medium">View and manage the list of locations where you've signed in on your devices.</p>
            </div>
          </div>
          <div
            onClick={() => {
              dispatchA({ type: "ACCOUNT_ACTIVITY" });
            }}
            className="flex cursor-pointer hover:bg-gray-100 flex-col md:flex-row items-left justify-left space-y-3 md:space-y-5 md:space-x-1.5 ">
            <div className="flex flex-row  p-1 mt-2">
              <div className="photo-wrapper mr-1  cursor-pointer" >
                <img className="w-10 h-10 rounded mx-1 mt-1" src={address} alt="email" />
              </div>
              <h3 className="font-semibold text-xl text-black dark:text-white mb-2">{checkLoginDetails[0]?.browser_fam}<br />
                <p className="font-semibold text-xl text-black dark:text-white mb-2">  {moment(checkLoginDetails[0]?.signin_time).format('DD/MM/yyyy')}</p>
              </h3>
              <h3 className="font-semibold ml-10  text-xl text-black dark:text-white mb-2">{checkLoginDetails[0]?.os_fam}({checkLoginDetails[0]?.os_ver})<br />
                <h3 className="font-semibold text-xl text-black dark:text-white mb-2">{checkLoginDetails[0]?.ip_address}
                </h3>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-6 xl:col-span-3 m-4">
        <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke dark:border-strokedark">
            <div className=" py-5 px-5">
              <h3 className="font-semibold text-xl text-black dark:text-white mb-2">Activity History</h3>
              <p className="text-sm text-gray-600 font-medium">View and manage all of your Logimax Payroll account activity.</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center space-y-3 md:space-y-5 md:space-x-1.5 ">
            <div className="flex flex-row  justify-center">
              <div className="photo-wrapper "  >
                <img className="w-[220px] h-[180px] rounded-full " src={location} alt="location" />
              </div>
            </div>
          </div>
          <div className="px-14 py-2 grid gap-3 md:flex md:justify-center md:items-center  border-gray-200 dark:border-gray-700">
            <div className="inline-flex gap-x-2">
              <div className="inline-flex gap-x-3 mb-0 px-3  gap-2 ">
                <div
                  onClick={() => {
                    dispatchA({ type: "ACCOUNT_DETAILS" });
                  }}
                  className="flex justify-center flex-row  bg-[#0A0A0A]
          border-[#0A0A0A]  focus:ring-4 focus:outline-none focus:ring-[#888] 
           text-center rounded border border-stroke py-2 px-6 hover:text-white
          font-medium text-white cursor-pointer  hover:bg-[#888]  hover:shadow-1 "
                >
                  Show Account Activity
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
};
export default Settings;
