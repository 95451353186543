import React from "react";
import { Button } from "reactstrap";
import Loading from "../lmx-loading/winbull-loader";
import JsPDF from "jspdf";
import { useExportData } from "react-table-plugins";
import {
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage,
  MdArrowDropUp,
  MdArrowDropDown,
} from "react-icons/md";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
  useExpanded,
  // useExportData
} from "react-table";

// export const Styles = styled.div`
// overflow-x: auto;
// background-color: rgb(255 255 255 / var(--tw-bg-opacity));
// margin:2vh;
// overflow:hidden;
// border-radius:0.5rem;
// position:relative;
// box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
// padding: 2vh 0.75vw;
// table {
//   margin:.5vh;
//   width: 100%;
//   border-radius:0.5rem;
//   font-size: 0.875rem;
//   line-height: 1.25rem;
//   color: rgb(55 65 81 / var(--tw-text-opacity));
//   border-spacing: 0rem;
//   border: 2px solid #e1e1e1;
//   box-shadow: 0px 0px;
//   tr {
//     border-bottom-width: 1px;
//     :last-child {
//       td {
//         text-align:center;
//         border-bottom: 0;
//       }
//     }
//   }
//   th,
//   td {
//     text-align:center;
//     padding-left: 1rem;
//     padding-right: 1rem;
//     margin: 0;
//     padding: 10px 10px;
//     border-bottom: 1px solid #e1e1e1;
//     border-right: 0px solid black;
//     font-size: small;
//     font-family:700;
//     border-radius:0.5rem;

//     :last-child {
//       text-align:center;
//       border-right: 0;

//     }
//   }
// }
// `;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="inline-flex gap-x-1 ">
      <div>
        <input
          className="bg-gray-50 border
           border-gray-300 text-gray-900 text-sm 
           rounded-lg focus:ring-blue-500 focus:border-blue-500 block
            w-[8rem] pl-5 p-2  dark:bg-gray-700 dark:border-gray-600 
            dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
             dark:focus:border-blue-500"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={count === 0 ? `No Records` : ` Search...`}
        />
      </div>
    </div>
  );
}

// function getExportFileBlob({ columns, data, fileType, fileName }) {
//   if (fileType === "csv") {
//     // CSV example
//     const headerNames = columns.map((col) => col.exportValue);
//     const csvString = Papa.unparse({ fields: headerNames, data });
//     return new Blob([csvString], { type: "text/csv" });
//   }
//   if (fileType === "pdf") {
//     const headerNames = columns.map((column) => column.exportValue);
//     const doc = new JsPDF();
//     doc.autoTable({
//       head: [headerNames],
//       body: data,
//       margin: { top: 20 },
//       styles: {
//         minCellHeight: 12,
//         halign: "left",
//         valign: "center",
//         fontSize: 7,
//       },
//     });
//     doc.save(`${fileName}.pdf`);

//     return false;
//   }
// }
export function TableNew({ columns, data, loading }) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: false,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    exportData,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    rows,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, expanded },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // getExportFileBlob,
      initialState: { pageSize: 5, pageIndex: 0, sortBy: [{}] },
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExportData,
    useExpanded,
    usePagination,
    useRowSelect
    //
  );

  const firstPageRows = rows.slice(0, 5);

  // Render the UI for your table
  return (
    <>
      {data.length == 0 ? (
        <div>
          {" "}
          {loading == true ? (
            <Loading />
          ) : (
            <p className="text-center font-bold text-lg ">No Results Found </p>
          )}{" "}
        </div>
      ) : (
        <>
          <div className="-mt-7  ml-3 px-2 py-2 grid gap-3 md:flex md:justify-between md:items-center  border-gray-200 dark:border-gray-700">
            <div>
              <div className="inline-flex gap-x-2">
                <div>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    // globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />{" "}
                </div>
                <div className="select">
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    aria-label="Filter all"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {`${pageSize} records`}
                      </option>
                    ))}
                  </select>{" "}
                </div>
              </div>
            </div>
          </div>
          <section className="bg-transparent dark:bg-gray-900 p-3 sm:p-5">
            <div className="overflow-x-auto">
              <table
                {...getTableProps()}
                className="w-full text-md text-left text-black font-medium dark:text-gray-400"
              >
                <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}

                          scope="col"
                          className="px-4 py-3 sticky"
                        >
                          {column.render("Header")}
                          <span className="sr-only">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <MdArrowDropDown />
                              ) : (
                                <MdArrowDropUp />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={i}>
                        <tr
                          {...row.getRowProps()}
                          className="border-b dark:border-gray-700"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-4 py-3"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
          <div style={{ marginTop: "-1rem", padding: "10px" }}>
            <span className="ml-5 -mt-4  ">
              <Button
                className="mx-1 border-blue-600 p-1  hover:bg-blue-600 rounded-md hover:text-white focus:ring-4 focus:outline-none border"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {" "}
                <MdFirstPage />
              </Button>

              <Button
                className="mx-1 border-blue-600 p-1 hover:bg-blue-600 rounded-md hover:text-white focus:ring-4 focus:outline-none border"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {" "}
                <MdChevronLeft />{" "}
              </Button>

              <Button
                className="mx-1 border-blue-600 p-1 hover:bg-blue-600 rounded-md hover:text-white focus:ring-4 focus:outline-none border"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {" "}
                <MdChevronRight />{" "}
              </Button>

              <Button
                className="mx-1 border-blue-600 p-1 hover:bg-blue-600 rounded-md hover:text-white focus:ring-4 focus:outline-none border"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {" "}
                <MdLastPage />{" "}
              </Button>
            </span>
            <span style={{ right: "5vw", position: "absolute" }}>
              {/* <span > */}
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
              {/* </span> */}
            </span>
          </div>
        </>
      )}
    </>
  );
}
