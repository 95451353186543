import secureLocalStorage from "react-secure-storage";
import {
  LOGIN_USER_BGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER_BGIN,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  CHECK_DASHBOARD_STATUS_BGIN,
  CHECK_DASHBOARD_STATUS_SUCCESS,
  CHECK_DASHBOARD_STATUS_ERROR,
  EDIT_PROFILE_BGIN,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  CHANGE_PROFILE_PASSWORD_BGIN,
  CHANGE_PROFILE_PASSWORD_SUCCESS,
  CHANGE_PROFILE_PASSWORD_ERROR,
  CHECK_TOKEN_BGIN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  SEND_OTP_BGIN,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  VERIFY_OTP_BGIN,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  RESET_PASS_BGIN,
  RESET_PASS_SUCCESS,
  RESET_PASS_ERROR,
  CHECK_LOGIN_DETAILS_BGIN,
  CHECK_LOGIN_DETAILS_SUCCESS,
  CHECK_LOGIN_DETAILS_ERROR,
  ADD_USER_PHOTO_BGIN,
  ADD_USER_PHOTO_SUCCESS,
  ADD_USER_PHOTO_ERROR,
  SIGNUP_USER_BGIN,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  EDIT_USER_PHOTO_BGIN,
  EDIT_USER_PHOTO_SUCCESS,
  EDIT_USER_PHOTO_ERROR,
  // // SEND_OTP_EXIT,
  // VERIFY_OTP_EXIT,
} from "../constant/authUser";

export const loginUserReducer = (state = { loginDetails: {} }, action) => {
  switch (action.type) {
    case LOGIN_USER_BGIN:
      return { loading: true, loginDetails: {} };
    case LOGIN_USER_SUCCESS:
      secureLocalStorage.setItem("pref", {
        pref: action.payload.preferences,
        token: action.payload.token,
        login_expiry: new Date(action.payload.login_expiry),
        email_verified: action.payload.email_verified,
      });
      if (action.payload.email_verified === false) {
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/expense-tracker/auth-login"}`,
          "Verify Auth",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/expense-tracker/auth-verify"}`
        );
        window.location.reload();
      } else {
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/expense-tracker/auth-login"}`,
          "auth-login",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/expense-tracker/auth-login"}`
        );
        window.location.reload();
      }
      return { loading: false, loginDetails: action.payload };
    case LOGIN_USER_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const SignupUserReducer = (state = { adddata: {} }, action) => {
  switch (action.type) {
    case SIGNUP_USER_BGIN:
      return { loading: true, adddata: {} };
    case SIGNUP_USER_SUCCESS:
      return { loading: false, adddata: action.payload };
    case SIGNUP_USER_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const logoutUserReducer = (state = { userLogOut: {} }, action) => {
  switch (action.type) {
    case LOGOUT_USER_BGIN:
      return { loading: true, userLogOut: {} };
    case LOGOUT_USER_SUCCESS:
      return { loading: false, userLogOut: action.payload };
    case LOGOUT_USER_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const checkTokenReducer = (state = { checkToken: {} }, action) => {
  switch (action.type) {
    case CHECK_TOKEN_BGIN:
      return { loading: true, checkToken: {} };
    case CHECK_TOKEN_SUCCESS:
      return { loading: false, checkToken: action.payload };
    case CHECK_TOKEN_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const checkDashboardStatusReducer = (
  state = { checkDashboardStatus: {} },
  action
) => {
  switch (action.type) {
    case CHECK_DASHBOARD_STATUS_BGIN:
      return { loading: true, checkDashboardStatus: {} };
    case CHECK_DASHBOARD_STATUS_SUCCESS:
      return { loading: false, checkDashboardStatus: action.payload };
    case CHECK_DASHBOARD_STATUS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const editProfileReducer = (state = { editProfile: {} }, action) => {
  switch (action.type) {
    case EDIT_PROFILE_BGIN:
      return { loading: true, editProfile: {} };
    case EDIT_PROFILE_SUCCESS:
      return { loading: false, editProfile: action.payload };
    case EDIT_PROFILE_ERROR:
      return { error: true };
    default:
      return state;
  }
};

// // send otp
export const sendOTPReducer = (state = { sendOTP: {} }, action) => {
  switch (action.type) {
    case SEND_OTP_BGIN:
      return { loading: true, sendOTP: {} };
    case SEND_OTP_SUCCESS:
      return { loading: false, sendOTP: action.payload };
    case SEND_OTP_ERROR:
      return { error: true };
    default:
      return state;
  }
};

// verify otp
export const verifyOTPReducer = (state = { verifyOTP: {} }, action) => {
  switch (action.type) {
    case VERIFY_OTP_BGIN:
      return { loading: true, verifyOTP: {} };
    case VERIFY_OTP_SUCCESS:
      secureLocalStorage.clear();
      window.history.pushState(
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/expense-tracker/auth-login"}`,
        "auth-login",
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/expense-tracker/auth-login"}`
      );
      window.location.reload();
      return { loading: false, verifyOTP: action.payload };
    case VERIFY_OTP_ERROR:
      return { error: true };
    default:
      return state;
  }
};

// reset pass by mail
export const resetPassReducer = (state = { resetPass: {} }, action) => {
  switch (action.type) {
    case RESET_PASS_BGIN:
      return { loading: true, resetPass: {} };
    case RESET_PASS_SUCCESS:
      return { loading: false, resetPass: action.payload };
    case RESET_PASS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const changeProfilePasswordReducer = (
  state = { changeProfilePassword: {} },
  action
) => {
  switch (action.type) {
    case CHANGE_PROFILE_PASSWORD_BGIN:
      return { loading: true, changeProfilePassword: {} };
    case CHANGE_PROFILE_PASSWORD_SUCCESS:
      return { loading: false, changeProfilePassword: action.payload };
    case CHANGE_PROFILE_PASSWORD_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const checkLoginDetailsReducer = (
  state = { checkLoginDetails: [] },
  action
) => {
  switch (action.type) {
    case CHECK_LOGIN_DETAILS_BGIN:
      return { loading: true, checkLoginDetails: [] };
    case CHECK_LOGIN_DETAILS_SUCCESS:
      return { loading: false, checkLoginDetails: action.payload };
    case CHECK_LOGIN_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

//ADD USER PHOTO
export const addUserPhotoReducer = (state = { addUserPhoto: {} }, action) => {
  switch (action.type) {
    case ADD_USER_PHOTO_BGIN:
      return { loading: true, addUserPhoto: {} };
    case ADD_USER_PHOTO_SUCCESS:
      return { loading: false, addUserPhoto: action.payload };
    case ADD_USER_PHOTO_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const editUserPhotoReducer = (state = { editUserPhoto: {} }, action) => {
  switch (action.type) {
    case EDIT_USER_PHOTO_BGIN:
      return { loading: true, editUserPhoto: {} };
    case EDIT_USER_PHOTO_SUCCESS:
      return { loading: false, editUserPhoto: action.payload };
    case EDIT_USER_PHOTO_ERROR:
      return { error: true };
    default:
      return state;
  }
};
