import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdHistory, MdSettings, MdAccountBox, MdAddchart } from "react-icons/md";
import { AiFillDashboard } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { GiTakeMyMoney } from "react-icons/gi";
import avatar from "../../assets/avatar/user.png";
import { logoutUserAction } from "../../redux/action/authUser";
import secureLocalStorage from "react-secure-storage";
import { FaSignOutAlt } from "react-icons/fa";
import logo from "../../assets/logo/logo.png";
const Sidebar = ({ show, setter }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );

  const className =
    "bg-[#0A0A0A] w-[240px]   transition-[margin-left] fixed ease-in-out duration-500  md:static top-0 bottom-0 left-0 z-40";
  // className = "bg - [#0A0A0A] w - [240px] yt - scrollbar scrollbar - hide   h - [calc(100vh - 0px)]  text - yt - white  overflow - scroll  transition - [margin - left] fixed ease -in -out duration - 500  md:static top - 0 bottom - 0 left - 0 z - 40"
  // THIS CODE IS FOR SCROL VIEW IN SIDEBAR 
  const appendClass = show ? " ml-0" : " ml-[-270px] md:ml-0";

  const MenuItems = ({ icon, name, route, className, onClick }) => {
    // Highlight menu item based on currently displayed route
    const colorClass =
      location.pathname === route
        ? "rounded-4xl bg-[#888] px-4 py-3 text-white"
        : "text-white/100 hover:text-white/100 rounded-4xl hover:bg-[#888] px-4 py-3";
    return (
      <Link
        to={route}
        onClick={() => {
          onClick ? onClick() : setter((oldVal) => !oldVal);
        }}
        className={`flex gap-1 [&>*]:my-auto text-lg text-white pl-5 py-3   text-center border-b-white/10 ${colorClass} ${!!className ? className : ""
          }`}
      >
        <div className="text-lg  justify-center flex [&>*]:mx-auto w-[40px]">
          {icon}
        </div>
        <div className="flex justify-center flex-row font-medium text-xl ">
          {name}
        </div>
      </Link>
    );
  };

  const ModalOverlay = () => (
    <div
      className={`flex md:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
      onClick={() => {
        setter((oldVal) => !oldVal);
      }}
    />
  );

  return (
    <>
      <div className="flex ">
        <div
          className={` ${open ? "w-[50px] bg-[#0A0A0A] " : "w-[-240px] bg-[#0A0A0A] "
            } `}
        >
          <div className={`${className}${appendClass}`}>
            <div className="p-2 flex border-b-[1px] mb-3">
              <div className="flex items-center ml-[5px] justify-between">

              </div>
              <Link to={`${process.env.PUBLIC_URL}/`}>
                {/* <img
                  src={logo}
                  alt="Company Logo"
                  className="rounded  text-center h-10 ml-6 mt-0"
                /> */}
                <h4 className="mt-2.5 ml-5  text-xl font-semibold text-[#EDEDED] lg:block dark:text-gray-300">
                  EXPENSE TRACKER
                </h4>
              </Link>
            </div>
            <div className="pb-2 flex justify-start  flex-col border-b-[2px]  border-b-white/30">
              <div className=" flex justify-center flex-row">
                {" "}
                {user?.photo == undefined ? (
                  <Link to="/expense-tracker/profile">
                    <img
                      className=" rounded-full mx-auto"
                      width={80}
                      height={80}
                      src={avatar}
                      alt="avatar"
                    />
                  </Link>
                ) : (
                  <Link to="/expense-tracker/profile">
                    <img
                      className=" rounded-full mb-1 mx-auto"
                      width={80}
                      height={80}
                      src={user?.photo}
                      alt="user photo"
                    />
                  </Link>
                )}
              </div>
              <div className="flex justify-center  flex-row">
                <p className="text-white uppercase font-bold text-md">
                  {user?.name} {user?.lname}
                </p>
              </div>
              <div className="flex justify-center mb-1 flex-row">
                <p className="text-white font-medium text-sm">{user?.email}</p>
              </div>
            </div>

            <div className="flex flex-col justify-center border-t border-black ">
              <MenuItems
                name="Dashboard"
                route={`${process.env.PUBLIC_URL}/expense-tracker`}
                icon={<AiFillDashboard />}
              />

              <MenuItems
                name="Expenses"
                route={`${process.env.PUBLIC_URL}/expense-tracker/expense`}
                icon={<GiTakeMyMoney />}
              />
              <MenuItems
                name="History"
                route={`${process.env.PUBLIC_URL}/expense-tracker/history`}
                icon={<MdHistory />}
              />
              <MenuItems
                name="Analytics"
                route={`${process.env.PUBLIC_URL}/expense-tracker/analytics`}
                icon={<MdAddchart />}
              />
              <MenuItems
                name="My Profile"
                route={`${process.env.PUBLIC_URL}/expense-tracker/profile`}
                icon={<MdAccountBox />}
              />
              <MenuItems
                name=" Settings"
                route={`${process.env.PUBLIC_URL}/expense-tracker/settings`}
                icon={<MdSettings />}
              />

              <MenuItems
                onClick={() => {
                  dispatch(logoutUserAction());
                  secureLocalStorage.removeItem("pref");
                  window.open("/", "_self");
                }}
                name="Sign Out"
                route={`${process.env.PUBLIC_URL}/`}
                icon={<FaSignOutAlt />}
              />
            </div>
          </div>

          {show ? <ModalOverlay /> : <></>}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
