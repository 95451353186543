import React, { useEffect, useState } from "react";
import { useReducer } from "react";
import ExpenseModel from "../Models/Expense_Model";
import { Link } from "react-router-dom";
import Salary_Modal from "../Models/Salary_Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  ListCurrentMonthlySalaryAction,
  ListReportDetailsAction,
} from "../../redux/action/expense";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Cash_in_modal from "../Models/Cash_in_modal";
import { Table } from "../../../src/components/lmx-tables/table1";
import Head from "../../components/Head/Head";

const initialState = { showModal: false };
const initialStateA = { showModalA: false };
const initialStateB = { showModalB: false };

const reducer = (state, action) => {
  switch (action.type) {
    case "EXPENSE_ADD":
      return { mode: "expense_model", showModal: true };
    case "CLOSE_MODAL":
      return { showModal: false };
    default:
      return state;
  }
};
const reducerA = (stateA, actionA) => {
  switch (actionA.type) {
    case "SALARY_ADD":
      return { mode: "salary_modal", showModalA: true };
    case "CLOSE_MODAL":
      return { showModalA: false };
    default:
      return stateA;
  }
};

const reducerB = (stateB, actionB) => {
  switch (actionB.type) {
    case "CASH_IN":
      return { mode: "cash_in_modal", showModalB: true };
    case "CLOSE_MODAL":
      return { showModalB: false };
    default:
      return stateB;
  }
};
const Expense = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount",
        Cell: (cell) => (
          <div>
            {cell.cell.row.original.exp_amount ? (
              <div className="text-red-600">
                {cell.cell.row.original.exp_amount}
              </div>
            ) : cell.cell.row.original.salary_amount ? (
              <div className="text-green-600">
                {cell.cell.row.original.salary_amount}
              </div>
            ) : (
              <div className="text-green-600">
                {cell.cell.row.original.cred_amount}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "expense_date",
        disableSortBy: true,
        Cell: (cell) => (
          <>{moment(cell.cell.row.original.date).format("DD/M/YYYY")}</>
        ),
        disableSortBy: false,
      },
    ],
    []
  );

  const componentRef = useRef();
  const redux_dispatch = useDispatch();
  const handlePrint = useReactToPrint({ content: () => componentRef.current });
  const { CurrentSalary, loading } = useSelector(
    (state) => state.salaryCurrent
  );
  const { listReportDetails } = useSelector((state) => state.listReportDetails);
  // console.log(CurrentSalary);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateA, dispatchA] = useReducer(reducerA, initialStateA);
  const [stateB, dispatchB] = useReducer(reducerB, initialStateB);

  let tot_savings = 0;
  let sal = 0;
  let tot_expense = 0;

  const [savings, setsavings] = useState();
  const [total, settotal] = useState();
  const [totalsal, settotalsal] = useState(0);

  useEffect(() => {
    for (var i = 0; i < listReportDetails.length;) {
      listReportDetails[i].exp_amount &&
        (tot_expense += parseInt(listReportDetails[i].exp_amount));
      listReportDetails[i].cred_amount &&
        (sal += parseInt(listReportDetails[i].cred_amount));
      i++;
      settotal(tot_expense);
      settotalsal(sal);
    }
    console.log(totalsal);
    total &&
      setsavings(
        parseInt(CurrentSalary[0]?.amount) +
        parseInt(totalsal) -
        parseInt(total)
      );
  }, [listReportDetails, total]);

  useEffect(() => {
    redux_dispatch(ListCurrentMonthlySalaryAction());
  }, [redux_dispatch]);

  useEffect(() => {
    redux_dispatch(
      ListReportDetailsAction(
        new Date().toLocaleString("default", { month: "long" }),
        new Date().getFullYear()
      )
    );
  }, [redux_dispatch]);



  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="">
            <Head title="Expenses" />
            <h3 className="font-semibold text-2xl mt-3  font-poppins text-[EDEDED] px-4 dark:text-white ">
              {" "}
              Expense
            </h3>
            <div className="px-3 mt-[1rem] ">
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                <div className="md:col-span-6 lg:col-span-12">
                  <div className="h-full space-y-6 group p-6 sm:p-8 rounded-3xl bg-white border border-gray-200/50 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10">
                    <ExpenseModel state={state} dispatch={dispatch} />
                    <Salary_Modal state={stateA} dispatch={dispatchA} />
                    <Cash_in_modal state={stateB} dispatch={dispatchB} />

                    <div className="flex">
                      {CurrentSalary &&
                        CurrentSalary[0]?.amount.includes("No salary") ? (
                        <span className="flex-1 ">
                          <span className="inline-flex  bg-gray-800 rounded-md border shadow-sm">
                            <Link
                              style={{ cursor: "not-allowed" }}
                              className="inline-block cursor-pointer border-e px-4 py-3 text-sm font-medium text-white hover:bg-gray-800 hover:text-gray-50 focus:relative"
                            >
                              Cash out
                            </Link>
                          </span>

                          <span className="inline-flex  rounded-md border bg-white shadow-sm">
                            <Link
                              style={{ cursor: "not-allowed" }}
                              className="inline-block cursor-pointer border-e px-4 py-3 text-sm font-medium text-gray-800 hover:bg-white hover:text-green-700 focus:relative"
                            >
                              Cash in
                            </Link>
                          </span>
                        </span>
                      ) : (
                        <span className="flex-1 ">
                          <span className="inline-flex  bg-gray-800 rounded-md border shadow-sm">
                            <Link
                              onClick={() => {
                                dispatch({ type: "EXPENSE_ADD" });
                              }}
                              className="inline-block cursor-pointer border-e px-4 py-3 text-sm font-medium text-white hover:bg-gray-800 hover:text-gray-50 focus:relative"
                            >
                              Cash out
                            </Link>
                          </span>

                          <span className="inline-flex  rounded-md border bg-white shadow-sm">
                            <Link
                              // to="/admin/expensedetails/add"
                              onClick={() => {
                                dispatchB({ type: "CASH_IN" });
                              }}
                              className="inline-block cursor-pointer border-e px-4 py-3 text-sm font-medium text-gray-800 hover:bg-white hover:text-green-700 focus:relative"
                            >
                              Cash in
                            </Link>
                          </span>
                        </span>
                      )}
                      <span className="flex ">
                        {CurrentSalary &&
                          CurrentSalary[0]?.amount.includes("No salary") ? (
                          <span className="inline-flex  rounded-md border bg-white shadow-sm">
                            <div className="inline-block border-e px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:relative">
                              No salary added
                            </div>
                          </span>
                        ) : (
                          <span className="inline-flex  rounded-md border bg-white shadow-sm">
                            <div className="inline-block border-e px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:relative">
                              {parseInt(CurrentSalary[0]?.amount) +
                                parseInt(totalsal)}
                            </div>
                          </span>
                        )}
                        {CurrentSalary &&
                          CurrentSalary[0]?.amount.includes("No salary") ? (
                          <span className="inline-flex">
                            <div className="inline-block">
                              <a
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  dispatchA({ type: "SALARY_ADD" });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H12.75L12.75 15C12.75 15.4142 12.4142 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15V12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H11.25L11.25 9C11.25 8.58579 11.5858 8.25 12 8.25Z"
                                    fill="#1C274C"
                                  />
                                </svg>
                              </a>
                            </div>
                          </span>
                        ) : (
                          <span className="inline-flex">
                            <div className="inline-block">
                              <a
                                style={{
                                  cursor: "not-allowed",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H12.75L12.75 15C12.75 15.4142 12.4142 15.75 12 15.75C11.5858 15.75 11.25 15.4142 11.25 15V12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H11.25L11.25 9C11.25 8.58579 11.5858 8.25 12 8.25Z"
                                    fill="#1C274C"
                                  />
                                </svg>
                              </a>
                            </div>
                          </span>
                        )}
                      </span>
                    </div>

                    <hr className="bg-gray-900 border-b" />

                    <div className="flex">
                      <span className="flex-1 ">
                        <span className="inline-flex  rounded-md border bg-gray-800 shadow-sm">
                          <button
                            onClick={handlePrint}
                            className="inline-block  px-4 py-1 text-sm font-medium text-white hover:bg-gray-700 focus:relative"
                          >
                            Download PDF
                          </button>

                          <button
                            onClick={handlePrint}
                            className="inline-block px-1.5 py-2 text-white "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12 4L12 14M12 14L15 11M12 14L9 11"
                                stroke="#ffffff"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 20C7.58172 20 4 16.4183 4 12M20 12C20 14.5264 18.8289 16.7792 17 18.2454"
                                stroke="#1C274C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                            </svg>
                          </button>
                        </span>
                      </span>
                      <span className="flex ">
                        <span className="flex  rounded-md border bg-blue-700 shadow-sm">
                          <Link
                            to="/expense-tracker/history"
                            className="flex  px-4 py-1 text-md font-medium text-white hover:bg-blue-600 "
                          >
                            History &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path
                                  d="M12 8V12L14.5 14.5"
                                  stroke="#fff"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />{" "}
                                <path
                                  d="M5.60423 5.60423L5.0739 5.0739V5.0739L5.60423 5.60423ZM4.33785 6.87061L3.58786 6.87438C3.58992 7.28564 3.92281 7.61853 4.33408 7.6206L4.33785 6.87061ZM6.87963 7.63339C7.29384 7.63547 7.63131 7.30138 7.63339 6.88717C7.63547 6.47296 7.30138 6.13549 6.88717 6.13341L6.87963 7.63339ZM5.07505 4.32129C5.07296 3.90708 4.7355 3.57298 4.32129 3.57506C3.90708 3.57715 3.57298 3.91462 3.57507 4.32882L5.07505 4.32129ZM3.75 12C3.75 11.5858 3.41421 11.25 3 11.25C2.58579 11.25 2.25 11.5858 2.25 12H3.75ZM16.8755 20.4452C17.2341 20.2378 17.3566 19.779 17.1492 19.4204C16.9418 19.0619 16.483 18.9393 16.1245 19.1468L16.8755 20.4452ZM19.1468 16.1245C18.9393 16.483 19.0619 16.9418 19.4204 17.1492C19.779 17.3566 20.2378 17.2341 20.4452 16.8755L19.1468 16.1245ZM5.14033 5.07126C4.84598 5.36269 4.84361 5.83756 5.13505 6.13191C5.42648 6.42626 5.90134 6.42862 6.19569 6.13719L5.14033 5.07126ZM18.8623 5.13786C15.0421 1.31766 8.86882 1.27898 5.0739 5.0739L6.13456 6.13456C9.33366 2.93545 14.5572 2.95404 17.8017 6.19852L18.8623 5.13786ZM5.0739 5.0739L3.80752 6.34028L4.86818 7.40094L6.13456 6.13456L5.0739 5.0739ZM4.33408 7.6206L6.87963 7.63339L6.88717 6.13341L4.34162 6.12062L4.33408 7.6206ZM5.08784 6.86684L5.07505 4.32129L3.57507 4.32882L3.58786 6.87438L5.08784 6.86684ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM16.1245 19.1468C14.9118 19.8483 13.5039 20.25 12 20.25V21.75C13.7747 21.75 15.4407 21.2752 16.8755 20.4452L16.1245 19.1468ZM20.25 12C20.25 13.5039 19.8483 14.9118 19.1468 16.1245L20.4452 16.8755C21.2752 15.4407 21.75 13.7747 21.75 12H20.25ZM6.19569 6.13719C7.68707 4.66059 9.73646 3.75 12 3.75V2.25C9.32542 2.25 6.90113 3.32791 5.14033 5.07126L6.19569 6.13719Z"
                                  fill="#fff"
                                />{" "}
                              </g>
                            </svg>
                          </Link>
                        </span>
                      </span>
                    </div>

                    <div ref={componentRef}>
                      <h3 className="text-xl uppercase text-center p-2  font-medium text-gray-900 dark:text-white">
                        Report of{" "}
                        {new Date().toLocaleString("default", {
                          month: "long",
                        })}{" "}
                        {"-"} {new Date().getFullYear()}
                      </h3>

                      <div className="w-full divide-y-2 divide-gray-200 text-gray-600 dark:text-gray-200">
                        <Table
                          loading={loading}
                          columns={columns}
                          data={listReportDetails ? listReportDetails : []}
                        />
                      </div>
                    </div>

                    <div className="flex">
                      <span className="flex-1 ">
                        <span className="inline-flex  rounded-md border bg-white shadow-sm">
                          <div className=" inline-flex border-e px-1 py-3 text-md font-bold text-gray-700 ">
                            Balance ₹{savings}
                          </div>
                        </span>
                      </span>
                      <span className="flex ">
                        <span className="inline-flex  rounded-md border-red-600 bg-red-700 shadow-sm">
                          <div className="inline-flex border-e px-1 py-3 text-md font-bold text-white ">
                            Total {"₹"}
                            {total}
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Expense;
