import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { Menu, Transition, Popover } from "@headlessui/react";
import avatar from "../../assets/avatar/user.png";
import { Fragment } from "react";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { logoutUserAction } from "../../redux/action/authUser";
import classNames from "classnames";
import { HiOutlineBell, } from "react-icons/hi";
import { GetNotificationDetailsAction } from "../../redux/action/expense";

export default function MenuBarMobile({ setter, show }) {
  const dispatch = useDispatch();
  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );
  const { notifyDetails } = useSelector((state) => state.notifyDetails);
  useEffect(() => {
    dispatch(GetNotificationDetailsAction());
  }, []);
  return (
    <nav className="md:hidden z-20 fixed top-0 left-0 right-0 h-[60px] bg-[#0A0A0A] flex [&>*]:my-auto px-2">
      <button
        className="text-4xl flex text-white"
        onClick={() => {
          setter((oldVal) => !oldVal);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-7 w-7 "
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4 6H20M4 12H14M4 18H9"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div className="mx-auto">
        <Link to={`${process.env.PUBLIC_URL}/expense-tracker`}>
          <h4 className="text-left font-outfit text-xl  text-[#EDEDED] lg:block dark:text-gray-300 font-medium  ">
            Expense Tracker
          </h4>
        </Link>
      </div>

      <Menu as="div" className="relative ml-20">
        <div>
          <Menu.Button className="relative group inline-flex items-center rounded-sm p-1 text-white " >
            <HiOutlineBell fontSize={26} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-70 origin-top-right rounded-md bg-ewhite py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className=" w-full px-4 py-2 font-medium text-center text-eblack rounded-t-lg bg-gray-200 dark:bg-gray-800 dark:text-white">
              Notifications
            </div>
            <div className=" text-left px-4 py-2 p-1 mt-0 text-white ">

              {notifyDetails.length == 0 && (
                <div className="mt-2 py-1 text-sm text-eblack">None.</div>
              )}
              {notifyDetails.map((e, idx) => {
                return (
                  <div
                    key={idx}
                    className={
                      e?.msg?.includes("saved")
                        ? "mt-2 py-1 text-sm text-blue-700"
                        : "mt-2 py-1 text-sm text-red-700"
                    }
                  >
                    {e?.msg}
                  </div>
                );
              })}
            </div>

          </Menu.Items>
        </Transition>
      </Menu>
      <Link className="text-3xl flex text-white" href="/login">
        {show ? (
          <FaUser />
        ) : (
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button
                className="relative flex rounded-full bg-gray-800 text-sm
             focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Open user menu</span>
                {/* <img
                  className="rounded-full"
                  src={avatar}
                  alt="Company Logo"
                  width={40}
                    height={40}
                 
                /> */}

                {user?.photo == undefined ? (
                  <img
                    className=" rounded-full "
                    width={40}
                    height={40}
                    src={avatar}
                    alt="avatar"
                  />
                ) : (
                  <img
                    className=" rounded-full "
                    width={40}
                    height={40}
                    src={user?.photo}
                    alt="user photo"
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-70 origin-top-right rounded-md bg-[#0A0A0A] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                <div className=" text-left px-4 py-2 p-1 mt-0 text-white ">
                  <p className="text-[#EDEDED] text-sm mb-1 uppercase font-medium">
                    {user?.username}
                  </p>
                  <p className="text-white text-sm   font-medium">
                    {user?.email}
                  </p>
                </div>
                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/expense-tracker/`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-sm text-[#EDEDED] font-medium"
                  >
                    Dashboard
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/expense-tracker/settings`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-sm text-[#EDEDED] font-medium"
                  >
                    Settings
                  </Link>
                </Menu.Item>
                <hr />

                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/expense-tracker/profile`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-sm text-[#EDEDED] font-medium"
                  >
                    My Account
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-sm text-[#EDEDED] font-medium"
                  >
                    Expense Homepage
                  </Link>
                </Menu.Item>
                <hr />

                <Menu.Item>
                  <Link
                    onClick={() => {
                      dispatch(logoutUserAction());
                      secureLocalStorage.clear("pref");
                      window.open("/expense-tracker/auth-login", "_self");
                    }}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-sm text-[#EDEDED] font-medium"
                  >
                    Log Out
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </Link>
    </nav>
  );
}
