import React, { useState, useLayoutEffect } from 'react'
import lblack from "../assets/logo/logo.png"
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import image from "../assets/login/door.png"
import {
    Block,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockTitle,
    Button,
    Icon,
    PreviewCard,
} from "../components/Component";
import { Link } from "react-router-dom";
import { checkTokenAction, loginUserAction } from "../redux/action/authUser";
import AuthFooter from './AuthFooter';
import Navbar from '../components/navbar/Navbar';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // Check token state fetch from redux store ...
    const {
        loading: tokenChecking,
        checkToken: { message } = {},
        error: TokenError,
    } = useSelector((state) => state.checkToken);
    //    login state fetch from redux - to check api loading status
    const { loading: isLoggingIn, loginDetails } = useSelector(
        (state) => state.loginstate
    );
    //    State Variables
    const [passState, setPassState] = useState(false);
    const [errorVal, setError] = useState("");

    //    on Submitting Form
    const onFormSubmit = async (formData) => {
        if (!!isLoggingIn) {
            return;
        }
        await dispatch(
            loginUserAction(formData?.name, formData?.passcode, setError)
        );
        setTimeout(() => {
            setError("");
        }, 4000);
    };

    useLayoutEffect(() => {
        if (message?.toLowerCase().includes("verify")) {
            navigate(`${process.env.PUBLIC_URL}/expense-tracker/auth-verify`);
        }
        if (message?.toLowerCase().includes("logged")) {
            navigate(`${process.env.PUBLIC_URL}/expense-tracker`);
        }
    }, [message]);

    useLayoutEffect(() => {
        secureLocalStorage.getItem("pref")?.token &&
            dispatch(checkTokenAction());
    }, []);

    return (

        <div className='bg-white'>
            <Navbar />
            <div className="mx-auto mt-10  px-4 py-20 sm:px-6 lg:px-4 ">
                <div className="mx-auto max-w-lg -mt-2">
                    {/* <div className="flex justify-center flex-row">
                        <img src={lblack} alt='login' className='h-10  ' />
                    </div> */}
                    {/* <p className="mx-auto mt-5 mb-5 max-w-md text-center text-xl text-gray-900 font-semibold font-poppins">
                        Sign-In to access Expense Tracker.<br />
                    </p> */}
                    {tokenChecking ? (
                        <></>
                    ) : (
                        <>
                            <div>
                                {errorVal && (
                                    <div className="mb-0 bg-red-600 p-1 text-white rounded mt-0">
                                        <Alert color="danger" className="bg-red-600 p-1 rounded-lg text-white">
                                            {" "}
                                            <Icon name="alert-circle" /> {errorVal}{" "}
                                        </Alert>
                                    </div>
                                )}
                            </div>

                            <form
                                onSubmit={handleSubmit(onFormSubmit)}
                                className="mb-0 mt-0 space-y-4 rounded-lg bg-white p-3 border-2 shadow-xl sm:p-8 lg:p-8" >
                                <p className="text-left text-gray-700 text-lg font-semibold"> Sign-In to access Expense Tracker.</p>
                                <div>
                                    {/* <label htmlFor="default-01" className="block mb-2 text-left
               text-sm font-medium text-black dark:text-white"> Username / Email address</label> */}

                                    <div className="relative">
                                        <input
                                            type="text"
                                            id="default-01"
                                            name="name"
                                            {...register("name", {
                                                required: "Username is required",
                                            })}
                                            placeholder="Email Address"
                                            className="w-full rounded-lg border-eblack p-4 pe-12 text-sm shadow-sm"
                                        />
                                        {errors.name && (
                                            <span className="invalid text-red-600 text-left">{errors.name.message}</span>
                                        )}

                                        <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                            <svg
                                                xmlns="http:www.w3.org/2000/svg"
                                                className="h-4 w-4 text-gray-400"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                            </svg>
                                        </span>

                                    </div>
                                </div>

                                <div>




                                    <div className="relative">
                                        <input
                                            type={isPasswordVisible ? "text" : "password"}
                                            id="password"
                                            name="passcode"
                                            {...register("passcode", {
                                                required: "Password is required",
                                            })}

                                            placeholder=" Password"
                                            className="w-full rounded-lg border-eblack p-4 pe-12 text-sm shadow-sm"
                                        />
                                        <p
                                            className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {isPasswordVisible ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                            )}
                                        </p>
                                        {errors.passcode && (
                                            <span className="invalid text-red-600">
                                                {errors.passcode.message}
                                            </span>
                                        )}

                                    </div>
                                </div>


                                <div className="form-label-group">
                                    <div className="grid grid-cols-1">
                                        <Link
                                            className="block  text-left font-medium text-sm text-blue-600 dark:text-blue-500 hover:underline"
                                            to={`${process.env.PUBLIC_URL}/expense-tracker/reset_password`}
                                        >
                                            Forgot Password?
                                        </Link>

                                    </div>
                                </div>


                                <Button
                                    disabled={isLoggingIn}
                                    size="lg"
                                    type="submit"
                                    className="w-full
                text-white bg-eblack hover:bg-gray-900 font-medium 
                 rounded-lg text-sm px-5 py-3.5 
                  text-center mr-2 mb-2"
                                >
                                    {isLoggingIn ? (
                                        <Spinner size="md" color="light" />
                                    ) : (
                                        "Sign In"
                                    )}
                                </Button>

                                <p className="text-center  mb-2 text-slate-600  text-md   dark:text-white">
                                    Don't have an account?&nbsp;
                                    <Link
                                        className="font-medium text-md text-blue-600 dark:text-blue-500 hover:underline"
                                        to={`${process.env.PUBLIC_URL}/expense-tracker/create-account`}
                                    >
                                        Sign up
                                    </Link>
                                </p>
                            </form>


                        </>
                    )}

                </div>

            </div>
            {/* <AuthFooter /> */}
        </div >


    )
}

export default Login