import React from "react";
import { Modal } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const InfoModel = ({ state, dispatch }) => {
  const redux_dispatch = useDispatch();
  const { checkLoginDetails } = useSelector((state) => state.loginDetailsCheck);

  return (
    <React.Fragment>
      {state.mode === "account_activity" ? (
        <Modal
          className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem] justify-center"
          dismissible
          show={state.showModalA}
          size="xl"
          popup={true}
          onClose={() => {
            dispatch({ type: "CLOSE_MODAL" });
          }}
        >
          <Modal.Header />
          <Modal.Body key={"account_activity"}>
            <div className="space-y-6 px-0 pb-2 sm:pb-2 lg:px-0 xl:pb-2">
              <h3 className="font-semibold text-xl text-green-600 dark:text-white ">
                Current session
              </h3>
              <div className="flex  flex-col md:flex-row items-left justify-left space-y-3 md:space-y-5 md:space-x-1.5 ">
                <div className="flex flex-row   mt-2">
                  <h3 className="font-semibold text-xl text-black dark:text-white mb-2">
                    {checkLoginDetails[0]?.browser_fam}
                    <br />
                    <p className="text-sm text-gray-600 font-medium">
                      {" "}
                      version {checkLoginDetails[0]?.browser_ver}
                    </p>
                  </h3>
                  <h3 className="font-semibold ml-10  text-xl text-black dark:text-white mb-2">
                    {checkLoginDetails[0]?.os_fam}(
                    {checkLoginDetails[0]?.os_ver})<br />
                  </h3>
                  <h3 className="font-semibold text-xl text-black dark:text-white mb-2">
                    {checkLoginDetails[0]?.ip_address}
                    <br />
                    <p className="text-sm text-gray-600 font-medium">
                      {" "}
                      {moment(checkLoginDetails[0]?.signin_time).format(
                        "DD/MM/yyyy"
                      )}
                    </p>
                  </h3>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem]"
          dismissible
          show={state.showModalA}
          size="xl"
          popup={true}
          onClose={() => {
            dispatch({ type: "CLOSE_MODAL" });
          }}
        >
          <Modal.Header />
          <Modal.Body key={"account_details"}>
            {checkLoginDetails?.map((checkLoginDetails, idx) => {
              return (
                <div className="space-y-6 px-0 pb-0 sm:pb-0 lg:px-0 xl:pb-1">
                  <div className="flex  flex-col md:flex-row items-left justify-left ">
                    <div className="flex flex-row ">
                      <h3 className="font-semibold text-xl text-black dark:text-white mb-2">
                        {checkLoginDetails.browser_fam}
                        <br />
                        <p className="text-sm text-gray-600 font-medium">
                          {" "}
                          version {checkLoginDetails.browser_ver}
                        </p>
                      </h3>

                      <h3 className="font-semibold ml-[5rem] text-xl text-black dark:text-white mb-2">
                        {checkLoginDetails?.os_fam}({checkLoginDetails?.os_ver})
                        <br />
                        <h3 className="font-semibold text-xl text-black dark:text-white mb-2">
                          {checkLoginDetails?.ip_address}
                          <br />
                          <h3 className="text-sm text-gray-600 font-medium">
                            {" "}
                            {moment(checkLoginDetails?.signin_time).format(
                              "DD/MM/yyyy"
                            )}
                          </h3>
                        </h3>
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default InfoModel;
