export const LOGIN_USER_BGIN = "LOGIN_USER_BGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const SIGNUP_USER_BGIN = "SIGNUP_USER_BGIN";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";

export const LOGOUT_USER_BGIN = "LOGOUT_USER_BGIN";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";

export const CHECK_TOKEN_BGIN = "CHECK_TOKEN_BGIN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_ERROR = "CHECK_TOKEN_ERROR";

export const CHECK_DASHBOARD_STATUS_BGIN = "CHECK_DASHBOARD_STATUS_BGIN";
export const CHECK_DASHBOARD_STATUS_SUCCESS = "CHECK_DASHBOARD_STATUS_SUCCESS";
export const CHECK_DASHBOARD_STATUS_ERROR = "CHECK_DASHBOARD_STATUS_ERROR";

export const EDIT_PROFILE_BGIN = "EDIT_PROFILE_BGIN";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";

export const SEND_OTP_BGIN = "SEND_OTP_BGIN";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_ERROR = "SEND_OTP_ERROR";
// export const SEND_OTP_EXIT = "SEND_OTP_EXIT";

export const VERIFY_OTP_BGIN = "VERIFY_OTP_BGIN";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
// export const VERIFY_OTP_EXIT = "VERIFY_OTP_EXIT";

export const RESET_PASS_BGIN = "RESET_PASS_BGIN";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";
export const RESET_PASS_ERROR = "RESET_PASS_ERROR";

export const CHANGE_PROFILE_PASSWORD_BGIN = "CHANGE_PROFILE_PASSWORD_BGIN";
export const CHANGE_PROFILE_PASSWORD_SUCCESS =
  "CHANGE_PROFILE_PASSWORD_SUCCESS";
export const CHANGE_PROFILE_PASSWORD_ERROR = "CHANGE_PROFILE_PASSWORD_ERROR";

export const CHECK_LOGIN_DETAILS_BGIN = "CHECK_LOGIN_DETAILS_BGIN";
export const CHECK_LOGIN_DETAILS_SUCCESS = "CHECK_LOGIN_DETAILS_SUCCESS";
export const CHECK_LOGIN_DETAILS_ERROR = "CHECK_LOGIN_DETAILS_ERROR";

//ADD USER PHOTO
export const ADD_USER_PHOTO_BGIN = "ADD_USER_PHOTO_BGIN";
export const ADD_USER_PHOTO_SUCCESS = "ADD_USER_PHOTO_SUCCESS";
export const ADD_USER_PHOTO_ERROR = "ADD_USER_PHOTO_ERROR";

export const EDIT_USER_PHOTO_BGIN = "EDIT_USER_PHOTO_BGIN";
export const EDIT_USER_PHOTO_SUCCESS = "EDIT_USER_PHOTO_SUCCESS";
export const EDIT_USER_PHOTO_ERROR = "EDIT_USER_PHOTO_ERROR";