import React, { useEffect, useState } from "react";
import { Button, Modal } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkDashboardStatusAction,
  editProfileAction,
  sendOTPAction,
} from "../../redux/action/authUser";
import { Col, FormGroup, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { toastsuccess } from "../../components/lmx-toast-style/toast-style";
import Icon from "../../components/icon/Icon";
import store from "../../redux/store";

const Profile_Edit_Model = ({ user, state, dispatch }) => {
  const { loading: updatingdata } = useSelector((state) => state.editProfile);
  const { loading: sendingOTP } = useSelector((state) => state.sendOTP);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const {
    register: registerA,
    handleSubmit: handleSubmitA,
    reset: resetA,
    formState: { errors: errorsA },
  } = useForm();

  useEffect(() => {
    setFormData({
      ...formData,
      name: user?.name,
      email: user?.email,
    });
    reset();
    resetA();
  }, [user]);

  const redux_dispatch = useDispatch();
  const [modalTab, setModalTab] = useState("1");
  const [formData, setFormData] = useState({});
  const [modal, setModal] = useState(false);
  // input change handler
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const updateUserFn = () => {
  //   const loginpref = secureLocalStorage.getItem("pref").pref;
  //   redux_dispatch(checkDashboardStatusAction(loginpref));
  // };

  const closemodal = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };
  const closeModal = () => {
    setModalTab("1");
    setModal(false);
    setFormData({
      name: user?.name,
      email: user?.email,
    });
    reset();
  };
  const submitForm = async () => {
    await redux_dispatch(
      editProfileAction(formData, {
        setModalTab,
        toastsuccess,
        closemodal,
      })
    );
  };
  const resendCode = () => {
    redux_dispatch(sendOTPAction({ otp_for: 1, email: formData?.email }));
  };
  return (
    <React.Fragment>
      <Modal
        className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem] justify-center"
        dismissible
        show={state.showModalA}
        size="xl"
        popup={true}
        onClose={() => {
          dispatch({ type: "CLOSE_MODAL" });
        }}
      >
        <Modal.Header />
        <Modal.Body key={"profile_edit"}>
          <div className=" ">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Update Profile
            </h3>
            <ul className="">
              {modalTab === "1" && (
                <li className="nav-item">
                  <a
                    className={` text-xl font-medium text-gray-900 dark:text-white ${
                      modalTab === "1" && "active"
                    }`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModalTab("1");
                    }}
                  >
                    Personal Details :
                  </a>
                </li>
              )}
              {modalTab === "2" && (
                <li className="nav-item">
                  <a
                    className={`text-xl font-medium text-gray-900 dark:text-white ${
                      modalTab === "2" && "active"
                    }`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModalTab("2");
                    }}
                  >
                    Verify Email :
                  </a>
                </li>
              )}
            </ul>

            <div className="tab-content">
              {modalTab === "1" && (
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="mb-1 block text-sm font-bold text-black dark:text-white"
                        htmlFor="name"
                      >
                        First Name :
                      </label>
                      <input
                        {...register("name", {
                          required: "Name Required",
                          maxLength: {
                            value: 100,
                            message: "Max Length is 100",
                          },
                        })}
                        type="text"
                        id="name"
                        className="w-full rounded mb-2 border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        name="name"
                        onChange={(e) => onInputChange(e)}
                        value={formData?.name}
                        placeholder="Enter Name"
                      />
                      {errors?.name && (
                        <span className="text-danger">
                          <Icon className={"sm"} name="alert-circle" />
                          {errors.name.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label
                        className="mb-1 block text-sm font-bold text-black dark:text-white"
                        htmlFor="email"
                      >
                        Email :
                      </label>
                      <input
                        {...register("email", {
                          required: "Email is required",
                          maxLength: {
                            value: 100,
                            message: "Max Length is 100",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        type="email"
                        id="email"
                        className="w-full rounded mb-2 border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        name="email"
                        onChange={(e) => onInputChange(e)}
                        value={formData?.email}
                        placeholder="Email Address"
                      />
                      {errors?.email && (
                        <span className="text-danger">
                          <Icon className={"sm"} name="alert-circle" />
                          {errors.email.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <div className="flex justify-end gap-4 pt-0">
                    <Button
                      className="flex justify-center rounded border cursor-pointer 
                  border-stroke py-0  font-medium text-white bg-red-500 hover:shadow-1
                  hover:bg-red-600"
                      style={{
                        pointerEvents: updatingdata && "none",
                        cursor: updatingdata && "default",
                      }}
                      href="#dropdownitem"
                      onClick={(ev) => {
                        ev.preventDefault();
                        dispatch({ type: "CLOSE_MODAL" });
                        closeModal();
                      }}
                      color="danger"
                      size="md"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="flex justify-center rounded border cursor-pointer 
                border-stroke py-0 font-medium text-white bg-green-500 hover:shadow-1
                hover:bg-green-600"
                      disabled={updatingdata}
                      color="primary"
                      size="md"
                      onClick={!updatingdata ? handleSubmit(submitForm) : null}
                    >
                      {updatingdata ? "Updating ..." : "Update Profile"}
                    </Button>
                  </div>
                </Row>
              )}

              {modalTab === "2" && (
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="email_otp"
                      >
                        Verification Code
                      </label>
                      <input
                        type="number"
                        id="email_otp"
                        name="email_otp"
                        placeholder="Enter Verification Code recieved on your email"
                        defaultValue={formData?.email_otp}
                        {...registerA("email_otp", {
                          required: "Enter Verification Code",
                          minLength: {
                            value: 6,
                            message: "Min Length is 6",
                          },
                          maxLength: {
                            value: 6,
                            message: "Max Length is 6",
                          },
                        })}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={(e) => onInputChange(e)}
                        className="w-full mb-2 rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black  focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      />
                      {errorsA?.email_otp && (
                        <span className="text-danger">
                          <Icon className={"sm"} name="alert-circle" />
                          {errorsA.email_otp.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col size="6" className="">
                    <FormGroup>
                      <div className=" justify-left ">
                        {" "}
                        <Button
                          disabled={updatingdata || sendingOTP}
                          color="secondary"
                          size="xs"
                          onClick={() => {
                            (!updatingdata && !sendingOTP )&& resendCode() ;
                          }}
                          className="flex justify-center rounded border cursor-pointer 
                                                    border-stroke py-0 font-medium text-white bg-blue-500 hover:shadow-1
                                                    hover:bg-blue-600"
                        >
                          {sendingOTP ? "Sending Code" : "Resend Code"}
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                  <div className="flex justify-end gap-4 pt-0">
                    <Button
                      className="flex justify-center rounded border cursor-pointer 
                                            border-stroke py-0  font-medium text-white bg-red-500 hover:shadow-1
                                            hover:bg-red-600"
                      onClick={(ev) => {
                        ev.preventDefault();
                        dispatch({ type: "CLOSE_MODAL" });
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="flex justify-center rounded border cursor-pointer 
                                            border-stroke py-0 font-medium text-white bg-green-500 hover:shadow-1
                                            hover:bg-green-600"
                      disabled={updatingdata || sendingOTP}
                      color="primary"
                      size="md"
                      onClick={
                        !updatingdata && !sendingOTP
                          ? handleSubmitA(submitForm)
                          : null
                      }
                    >
                      {updatingdata ? "Verifying Code" : "Submit & Update"}
                    </Button>
                  </div>
                </Row>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Profile_Edit_Model;
