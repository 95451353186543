import React, { useState } from 'react'
import lblack from "../assets/logo/logo.png"
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import {
    Button,
} from "../components/Component";
import { Link } from "react-router-dom";
import { SignupUserAction } from "../redux/action/authUser";
import AuthFooter from './AuthFooter';
import store from '../redux/store';
import Navbar from '../components/navbar/Navbar';

const Register = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);


    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    }

    const togglePasswordVisibility2 = () => {
        setIsPasswordVisible2((prevState) => !prevState);
    }

    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [username, setusername] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [conformpassword, setconformpassword] = useState("");
    const { loading } = useSelector((state) => state.Signupstate);
    const handleFormSubmit = async () => {
        const adddata = {
            first_name: fname,
            last_name: lname,
            username: username,
            email: email,
            con_pass: conformpassword,
        };
        await dispatch(SignupUserAction(adddata));
        if (store.getState().Signupstate.hasOwnProperty("error") == false) {
            navigate(`${process.env.PUBLIC_URL}/expense-tracker/auth-login`);
        }
    };


    return (

        <div className='bg-white'>
            <Navbar />
            <div className="mx-auto mt-10 px-4 py-12 sm:px-6 lg:px-4 ">
                <div className="mx-auto max-w-lg ">
                    {/* <div className="flex justify-center flex-row">
                        <img src={lblack} alt='login' className='h-10  ' />
                    </div> */}
                    {/* <p className="mx-auto mt-1 mb-1 max-w-md text-center text-xl text-gray-900 font-semibold font-poppins">
                        Create your new Expense Tracker Account.<br />
                    </p> */}

                    <>
                        <form
                            onSubmit={handleSubmit(handleFormSubmit)}
                            className="space-y-4  rounded-lg bg-white p-3 border-2 shadow-xl sm:p-8 lg:p-8  " >
                            <p className="text-left text-gray-700 text-lg font-semibold">Create your new Expense Tracker Account.</p>

                            <div className=' grid grid-cols-6 gap-6'>
                                <div class="col-span-6 sm:col-span-3">
                                    {/* <label className="block mb-1 text-left
                                text-sm font-medium text-black dark:text-white" htmlFor="fname">
                                        First  Name
                                    </label> */}
                                    <div className="form-control-wrap">
                                        <input
                                            {...register("fname", {
                                                required: "First name  is  required",
                                                maxLength: {
                                                    value: 100,
                                                    message: "Max Length is 100",
                                                },
                                                onChange: (e) => setfname(e.target.value),
                                            })}
                                            type="text"
                                            id="fname"
                                            name="fname"
                                            value={fname}
                                            placeholder=" First Name"
                                            className="w-full rounded-lg border-eblack p-4  text-sm shadow-sm"
                                        />
                                        {errors.fname && (
                                            <span className="invalid text-red-600 text-left">{errors.fname.message}</span>
                                        )}
                                    </div>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    {/* <label className="block mb-1 text-left
                                text-sm font-medium text-black dark:text-white" htmlFor="lname">
                                        Last  Name
                                    </label> */}
                                    <div className="form-control-wrap">
                                        <input
                                            {...register("lname", {
                                                required: "last name  is  required",
                                                maxLength: {
                                                    value: 100,
                                                    message: "Max Length is 100",
                                                },
                                                onChange: (e) => setlname(e.target.value),
                                            })}
                                            type="text"
                                            id="lname"
                                            value={lname}
                                            name="lname"
                                            placeholder=" Last Name"
                                            className="w-full rounded-lg border-eblack p-4  text-sm shadow-sm"
                                        />
                                        {errors.lname && (
                                            <span className="invalid text-red-600 text-left">{errors.lname.message}</span>
                                        )}
                                    </div>
                                </div>


                                <div class="col-span-6 sm:col-span-3">
                                    {/* <label htmlFor="username" className="block mb-1 text-left
                                    text-sm font-medium text-black dark:text-white"> Username</label> */}

                                    <div className="relative">
                                        <input
                                            {...register("username", {
                                                required: "username  is  required",
                                                maxLength: {
                                                    value: 100,
                                                    message: "Max Length is 100",
                                                },
                                                onChange: (e) => setusername(e.target.value),
                                            })}
                                            type="text"
                                            id="username"
                                            name="username"
                                            value={username}
                                            placeholder=" Username"
                                            className="w-full rounded-lg border-eblack p-4  text-sm shadow-sm"
                                        />
                                        {errors.username && (
                                            <span className="invalid text-red-600 text-left">{errors.username.message}</span>
                                        )}
                                    </div>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    {/* <label htmlFor="email" className="block mb-1 text-left
                                    text-sm font-medium text-black dark:text-white"> Email</label> */}

                                    <div className="relative">
                                        <input
                                            {...register("email", {
                                                required: "email  is  required",
                                                maxLength: {
                                                    value: 100,
                                                    message: "Max Length is 100",
                                                },
                                                onChange: (e) => setemail(e.target.value),
                                            })}
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={email}
                                            placeholder=" Email"
                                            className="w-full rounded-lg border-eblack p-4  text-sm shadow-sm"
                                        />
                                        {errors.email && (
                                            <span className="invalid text-red-600 text-left">{errors.email.message}</span>
                                        )}

                                    </div>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <div className="form-label-group">
                                        <div className="grid grid-cols-2">
                                            {/* <label
                                                className="block mb-1 text-left text-sm font-medium text-black dark:text-white" htmlFor="password" >
                                                Password
                                            </label> */}
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type={isPasswordVisible ? "text" : "password"}
                                            {...register("password", {
                                                required: "password  is  required",
                                                minLength: {
                                                    value: 8,
                                                    message: "Min Length is 8",
                                                },
                                                maxLength: {
                                                    value: 128,
                                                    message: "Max Length is 128",
                                                },
                                                onChange: (e) => {
                                                    setpassword(e.target.value.split(" ").join(""));
                                                },
                                            })}
                                            id="password"
                                            name="password"
                                            value={password}
                                            placeholder=" Password"
                                            className="w-full rounded-lg border-eblack p-4  text-sm shadow-sm"
                                        />
                                        <p
                                            className="absolute inset-y-0 right-0 cursor-pointer flex items-center px-4 text-gray-600"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {isPasswordVisible ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                            )}
                                        </p>
                                        {errors.password && (
                                            <span className="invalid text-red-600">
                                                {errors.password.message}
                                            </span>
                                        )}

                                    </div>
                                </div>




                                <div class="col-span-6 sm:col-span-3">
                                    <div className="form-label-group">
                                        {/* <label
                                            className="block mb-1 text-left text-sm font-medium text-black dark:text-white"
                                            htmlFor="conformpassword" >
                                            Confirm  password
                                        </label> */}
                                    </div>
                                    <div className="relative">
                                        <input
                                            type={isPasswordVisible2 ? "text" : "password"}
                                            {...register("conformpassword", {
                                                required: "conform password  is  required",
                                                validate: (val) => {
                                                    if (watch("password") != val) {
                                                        return "Your passwords do no match";
                                                    }
                                                },
                                                onChange: (e) => {
                                                    setconformpassword(e.target.value.split(" ").join(""));
                                                },
                                            })}
                                            id="conformpassword"
                                            name="conformpassword"
                                            value={conformpassword}
                                            placeholder=" Confirm Password"
                                            className="w-full rounded-lg border-eblack p-4  text-sm shadow-sm"
                                        />
                                        <p
                                            className="absolute cursor-pointer inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                            onClick={togglePasswordVisibility2}
                                        >
                                            {isPasswordVisible2 ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                            )}
                                        </p>

                                        {errors.conformpassword && (
                                            <span className="invalid text-red-600">
                                                {errors.conformpassword.message}
                                            </span>
                                        )}

                                    </div>
                                </div>

                            </div>

                            <p className="text-right flex mb-1  text-md font-medium text-black dark:text-white">
                                Already have an account?{" "}&nbsp;
                                <Link to={`${process.env.PUBLIC_URL}/expense-tracker/auth-login`}>
                                    <strong className='text-blue-700'>Sign in instead</strong>
                                </Link>
                            </p>
                            <Button
                                size="lg"
                                type="submit"
                                className="w-full
                text-white bg-eblack hover:bg-gray-900 font-medium 
                 rounded-lg text-sm px-5 py-3.5 
                  text-center mr-2 mb-1"
                            >
                                {loading ? <Spinner size="md" color="light" /> : "Register"}
                            </Button>
                        </form>
                    </>


                </div>

            </div>
            {/* <AuthFooter /> */}
        </div >


    )
}

export default Register