//MONTHLY SALARY

export const LIST_CURRENT_MONTHLYSALARY_DETAILS_BGIN = "LIST_CURRENT_MONTHLYSALARY_DETAILS_BGIN";
export const LIST_CURRENT_MONTHLYSALARY_DETAILS_SUCCESS = "LIST_CURRENT_MONTHLYSALARY_DETAILS_SUCCESS";
export const LIST_CURRENT_MONTHLYSALARY_DETAILS_ERROR = "LIST_CURRENT_MONTHLYSALARY_DETAILS_ERROR";

export const ADD_MONTHLY_SALARY_BGIN = "ADD_MONTHLY_SALARY_BGIN";
export const ADD_MONTHLY_SALARY_SUCCESS = "ADD_MONTHLY_SALARY_SUCCESS";
export const ADD_MONTHLY_SALARY_ERROR = "ADD_MONTHLY_SALARY_ERROR";

export const EDIT_MONTHLY_SALARY_BGIN = "EDIT_MONTHLY_SALARY_BGIN";
export const EDIT_MONTHLY_SALARY_SUCCESS = "EDIT_MONTHLY_SALARY_SUCCESS";
export const EDIT_MONTHLY_SALARY_ERROR = "EDIT_MONTHLY_SALARY_ERROR";

export const INDI_FETCH_MONTHLY_SALARY_BGIN = "INDI_FETCH_MONTHLY_SALARY_BGIN";
export const INDI_FETCH_MONTHLY_SALARY_SUCCESS = "INDI_FETCH_MONTHLY_SALARY_SUCCESS";
export const INDI_FETCH_MONTHLY_SALARY_ERROR = "INDI_FETCH_MONTHLY_SALARY_ERROR";

//EXPENSE DETAILS
export const ADD_EXPENSE_DETAILS_BGIN = "ADD_EXPENSE_DETAILS_BGIN";
export const ADD_EXPENSE_DETAILS_SUCCESS = "ADD_EXPENSE_DETAILS_SUCCESS";
export const ADD_EXPENSE_DETAILS_ERROR = "ADD_EXPENSE_DETAILS_ERROR";

export const LIST_REPORT_DETAILS_BGIN = "LIST_REPORT_DETAILS_BGIN";
export const LIST_REPORT_DETAILS_SUCCESS = "LIST_REPORT_DETAILS_SUCCESS";
export const LIST_REPORT_DETAILS_ERROR = "LIST_REPORT_DETAILS_ERROR";


//CREDIT DETAILS
export const ADD_CREDIT_DETAILS_BGIN = "ADD_CREDIT_DETAILS_BGIN";
export const ADD_CREDIT_DETAILS_SUCCESS = "ADD_CREDIT_DETAILS_SUCCESS";
export const ADD_CREDIT_DETAILS_ERROR = "ADD_CREDI_DETAILS_ERROR";

//HISTORY
export const LIST_HISTORY_DETAILS_BGIN = "LIST_HISTORY_DETAILS_BGIN";
export const LIST_HISTORY_DETAILS_SUCCESS = "LIST_HISTORY_DETAILS_SUCCESS";
export const LIST_HISTORY_DETAILS_ERROR = "LIST_HISTORY_DETAILS_ERROR";

export const PRINT_HISTORY_DETAILS_BGIN = "PRINT_HISTORY_DETAILS_BGIN";
export const PRINT_HISTORY_DETAILS_SUCCESS = "PRINT_HISTORY_DETAILS_SUCCESS";
export const PRINT_HISTORY_DETAILS_ERROR = "PRINT_HISTORY_DETAILS_ERROR";

//GRAPH
export const GRAPH_EXPENSE_BEGIN = "GRAPH_EXPENSE_BEGIN";
export const GRAPH_EXPENSE_SUCCESS = "GRAPH_EXPENSE_SUCCESS";
export const GRAPH_EXPENSE_ERROR = "GRAPH_EXPENSE_ERROR";


//Notify
export const NOTIFY_DETAILS_BEGIN = "NOTIFY_DETAILS_BEGIN";
export const NOTIFY_DETAILS_SUCCESS = "NOTIFY_DETAILS_SUCCESS";
export const NOTIFY_DETAILS_ERROR = "NOTIFY_DETAILS_ERROR";