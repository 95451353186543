import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetExpenseGraphAction } from "../../../redux/action/expense";

export default function Transaction() {
  const dispatch = useDispatch();
  const { expenseGraph } = useSelector((state) => state.expenseGraph);

  const salary = expenseGraph?.map((obj) => {
    return obj?.salary;
  });

  console.log(salary);
  useEffect(() => {
    dispatch(GetExpenseGraphAction());
  }, []);
  return (
    <div className="h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1">
      <strong className="text-gray-700 font-medium">Transactions</strong>
      <div className="mt-3 w-full flex-1 text-xs">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={expenseGraph}
            margin={{
              top: 20,
              right: 10,
              left: -10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="salary" fill="#0ea5e9" />
            <Bar dataKey="expense" fill="#ea580c" />
            <Bar dataKey="credit" fill="#22bb33" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
