import React from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IndiHistoryDetailsAction } from "../../../redux/action/expense";
import logo from "../../../assets/logo/logo.png";
import print from "../../../assets/print/printer.png";
import { useRef } from "react";
const DownloadHistory = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({ content: () => componentRef.current });
  const id = location?.state.id;
  const { IndiHistory } = useSelector((state) => state.HistoryIndi);
  useEffect(() => {
    dispatch(IndiHistoryDetailsAction(id));
  }, [id]);
  
  function NumInWords(number) {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            " " +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  }
  const expense = IndiHistory?.expense_total;
  const total = expense;
  return (
    <div>
      <section className="bg-transparent dark:bg-gray-900 p-3 sm:p-4">
        <div className=" px-4 py-10 sm:px-6 lg:px-2 lg:py-10 mx-auto">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="bg-white dark:bg-gray-800 relative  sm:rounded-lg overflow-hidden">
              <div className="flex gap-x-3 mt-1 px-3   gap-2 justify-end">
                <img
                  onClick={handlePrint}
                  src={print}
                  alt="printer"
                  className="cursor-pointer"
                  width={40}
                  height={40}
                />
              </div>

              <div ref={componentRef}>
                <div className="flex flex-row md:flex-row items-center justify-center ">
                  <img
                    src={logo}
                    alt="Company Logo"
                    className="rounded  flex justify-center flex-row h-10 "
                  />
                </div>
                <h1 className="text-xl font-bold dark:text-white p-4 text-center">
                  Report of {IndiHistory?.month} {IndiHistory?.year}
                </h1>
                <hr />

                {IndiHistory.expenses?.length != 0 && (
                  <div className="invoice-head mb-5">
                    <div className="invoice-contact">
                      <div className="invoice-contact-info">
                        <div className="overflow-x-auto rounded-lg">
                          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                            <thead>
                              <tr>
                                <th className="bg-blue-300 text-center text-black font-poppins  border border-solid text-md  font-medium  ">
                                  Expense Name
                                </th>
                                <th className="bg-blue-300 text-center text-black font-poppins  border border-solid text-md  font-medium ">
                                  Expense Description
                                </th>
                                <th className="bg-blue-300 text-center text-black font-poppins border border-solid text-md  font-medium ">
                                  Expense Date
                                </th>
                                <th className="bg-blue-300 text-center text-black font-poppins border border-solid text-md  font-medium ">
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {IndiHistory.expenses?.map((e, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.expense_name}
                                    </td>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.expense_description}
                                    </td>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.expense_date}
                                    </td>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.amount}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {IndiHistory.credit?.length != 0 && (
                  <div className="invoice-head ">
                    <div className="invoice-contact">
                      <div className="invoice-contact-info">
                        <div className="overflow-x-auto rounded-lg">
                          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                            <thead>
                              <tr>
                                <th className="bg-blue-300 text-center text-black  font-poppins  border border-solid text-md  font-medium  ">
                                  Credit Name
                                </th>
                                <th className="bg-blue-300 text-center text-black  font-poppins  border border-solid text-md  font-medium ">
                                  Credit Description
                                </th>
                                <th className="bg-blue-300 text-center text-black  font-poppins border border-solid text-md  font-medium ">
                                  Credit Date
                                </th>
                                <th className="bg-blue-300 text-center text-black  font-poppins border border-solid text-md  font-medium ">
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {IndiHistory.credit?.map((e, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.credit_name}
                                    </td>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.credit_description}
                                    </td>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.credit_date}
                                    </td>
                                    <td className=" px-4 py-2  text-center text-md font-medium text-black">
                                      {e.amount}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <hr />
                <div className="grid justify-end ">
                  <tr className="">
                    <td className=" px-4 py-2 font-bold uppercase text-black">
                      Total Credit
                    </td>
                    <td className=" px-4 py-2 font-bold text-black">
                      {IndiHistory?.credit_total}
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-4 py-2 font-bold uppercase text-black">
                      Total Expense
                    </td>
                    <td className=" px-4 py-2 font-bold text-black">
                      {IndiHistory?.expense_total}
                    </td>
                  </tr>
                </div>

                <div className="px-3 py-3">
                  <h5 className="text-md font-bold ">
                    Total Expense:
                    <span className="font-bold text-md">
                      {" "}
                      {NumInWords(total).toUpperCase()}RUPEES ONLY/-
                    </span>
                  </h5>
                  <span className="mt-5 italic text-sm font-medium">
                    {" "}
                    Invoice was created on a computer and is valid without the
                    signature and seal.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DownloadHistory;
