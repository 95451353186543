import React from "react";
import Head from "../../components/Head/Head";
import {
  Block,
  PreviewCard,
} from "../../components/Component";

const Terms = ({ ...props }) => {
  return (
    <React.Fragment>
      <Head title="Terms &amp; Conditions" />
      <div class="py-16">
        <div class="xl:container m-auto px-6 text-gray-600 dark:text-gray-300 md:px-12 xl:px-6">
          <div className="mx-auto text-center text-2xl font-semibold text-gray-800">
            <div className="text-center text-5xl font-poppins mb-5 font-semibold text-gray-800">
              Terms &amp; Policy
            </div>

            <p className="text-center font-medium mb-5  text-sm">
              We are on a mission to make the web a better place. The
              following terms, as well as our Policy and Terms of Service,
              apply to all users.
            </p>
            <p className="text-sm italic mb-5">Last Update: Nov 12, 2019</p>

          </div>
          <div class="grid">
            <div
              class="row-span-2  rounded-xl border border-gray-100 dark:border-gray-700 bg-white dark:bg-gray-800 p-8 text-center shadow-2xl shadow-gray-600/10 dark:shadow-none">
              <div class="flex h-full flex-col justify-center space-y-4">

                <Block>
                  <PreviewCard className="card-bordered">
                    <div className="entry">
                      <h3 className="text-left text-lg font-bold text-gray-800 mb-1">Terms and Conditions</h3>
                      <p className="text-left">
                        We’ve decided to make these Terms available under a{" "}
                        <strong>Creative Commons</strong> license. You can grab a copy
                        of these Terms and other legal documents on Github. You’re
                        more than welcome to copy them, adapt them, and repurpose them
                        for your own use.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Introduction</h4>
                      <p className="text-left">
                        These Website Standard Terms and Conditions written on this
                        webpage shall manage your use of our website, Webiste Name
                        accessible at Website.com.
                      </p>
                      <br />
                      <p className="text-left">
                        These Terms will be applied fully and affect to your use of
                        this Website. By using this Website, you agreed to accept all
                        terms and conditions written in here. You must not use this
                        Website if you disagree with any of these Website Standard
                        Terms and Conditions.
                      </p>
                      <br />
                      <p className="text-left">
                        Minors or people below 18 years old are not allowed to use
                        this Website.
                      </p>
                      <br/>
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Intellectual Property Rights</h4>
                      <p className="text-left">
                        Other than the content you own, under these Terms, Company
                        Name and/or its licensors own all the intellectual property
                        rights and materials contained in this Website.
                      </p>
                      <br />
                      <p className="text-left">
                        You are granted limited license only for purposes of viewing
                        the material contained on this Website.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Restrictions</h4>
                      <p className="text-left">
                        You are specifically restricted from all of the following:
                      </p>
                      <ul className="list list-sm list-checked text-left">
                        
                        <li><span className="text-md font-bold">➞ </span>Publishing any Website material in any other media;</li>
                        <li>
                          <span className="text-md  font-bold">➞ </span>Selling, sublicensing and/or otherwise commercializing any
                          Website material;
                        </li>
                        <li>
                        <span className="text-md text-red font-bold">➞ </span>
                          Publicly performing and/or showing any Website material;
                        </li>
                        <li>
                        <span className="text-md text-red font-bold">➞ </span>
                          Using this Website in any way that is or may be damaging to
                          this Website;
                        </li>
                        <li>
                        <span className="text-md text-red font-bold">➞ </span>
                          Using this Website in any way that impacts user access to
                          this Website;
                        </li>
                        <li>
                        <span className="text-md text-red font-bold">➞ </span>
                          Using this Website contrary to applicable laws and
                          regulations, or in any way may cause harm to the Website, or
                          to any person or business entity;
                        </li>
                        <li>
                        <span className="text-md text-red font-bold">➞ </span>
                          Engaging in any data mining, data harvesting, data
                          extracting or any other similar activity in relation to this
                          Website;
                        </li>
                        <li>
                        <span className="text-md text-red font-bold">➞ </span>
                          Using this Website to engage in any advertising or
                          marketing.
                        </li>
                        <li>
                        <span className="text-md text-red font-bold">➞ </span>
                          Certain areas of this Website are restricted from being
                          access by you and Company Name may further restrict access
                          by you to any areas of this Website, at any time, in
                          absolute discretion. Any user ID and password you may have
                          for this Website are confidential and you must maintain
                          confidentiality as well.
                        </li>
                      </ul>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Your Content</h4>
                      <p className="text-left">
                        In these Website Standard Terms and Conditions, “Your Content”
                        shall mean any audio, video text, images or other material you
                        choose to display on this Website. By displaying Your Content,
                        you grant Company Name a non-exclusive, worldwide irrevocable,
                        sub licensable license to use, reproduce, adapt, publish,
                        translate and distribute it in any and all media.
                      </p>
                      <br />
                      <p  className="text-left">
                        Your Content must be your own and must not be invading any
                        third-party"s rights. Company Name reserves the right to
                        remove any of Your Content from this Website at any time
                        without notice.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">No warranties</h4>
                      <p  className="text-left">
                        This Website is provided “as is,” with all faults, and Company
                        Name express no representations or warranties, of any kind
                        related to this Website or the materials contained on this
                        Website. Also, nothing contained on this Website shall be
                        interpreted as advising you.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Limitation of liability</h4>
                      <p  className="text-left">
                        In no event shall Company Name, nor any of its officers,
                        directors and employees, shall be held liable for anything
                        arising out of or in any way connected with your use of this
                        Website whether such liability is under contract. Company
                        Name, including its officers, directors and employees shall
                        not be held liable for any indirect, consequential or special
                        liability arising out of or in any way related to your use of
                        this Website.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Indemnification</h4>
                      <p  className="text-left">
                        You hereby indemnify to the fullest extent Company Name from
                        and against any and/or all liabilities, costs, demands, causes
                        of action, damages and expenses arising in any way related to
                        your breach of any of the provisions of these Terms.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Severability</h4>
                      <p  className="text-left">
                        If any provision of these Terms is found to be invalid under
                        any applicable law, such provisions shall be deleted without
                        affecting the remaining provisions herein.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800  mb-1" >Variation of Terms</h4>
                      <p  className="text-left">
                        Company Name is permitted to revise these Terms at any time as
                        it sees fit, and by using this Website you are expected to
                        review these Terms on a regular basis.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800  mb-1">Assignment</h4>
                      <p  className="text-left">
                        The Company Name is allowed to assign, transfer, and
                        subcontract its rights and/or obligations under these Terms
                        without any notification. However, you are not allowed to
                        assign, transfer, or subcontract any of your rights and/or
                        obligations under these Terms.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800  mb-1">Entire Agreement</h4>
                      <p  className="text-left">
                        These Terms constitute the entire agreement between Company
                        Name and you in relation to your use of this Website, and
                        supersede all prior agreements and understandings.
                      </p>
                      <br />
                      <h4 className="text-left text-lg font-bold text-gray-800 mb-1">Governing Law &amp; Jurisdiction</h4>
                      <p  className="text-left">
                        
                        These Terms will be governed by and interpreted in accordance
                        with the laws of the State of Country, and you submit to the
                        non-exclusive jurisdiction of the state and federal courts
                        located in Country for the resolution of any disputes.
                      </p>
                    </div>
                  </PreviewCard>
                </Block>
              </div>
            </div>
          </div>
        </div>
      </div >
    </React.Fragment>
  );
};

export default Terms;
