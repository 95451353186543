import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import avatar from "../../assets/avatar/user.png";
import { logoutUserAction } from "../../redux/action/authUser";
import secureLocalStorage from "react-secure-storage";
import { Menu, Transition, Popover } from "@headlessui/react";
import { Fragment } from "react";
import logo from "../../assets/logo/logo.png";
import {
  HiOutlineBell,
} from "react-icons/hi";
import classNames from "classnames";
import { FiSettings } from "react-icons/fi";
import { CgAddR } from "react-icons/cg";
import { useEffect } from "react";
import { GetNotificationDetailsAction } from "../../redux/action/expense";

const NavbarTop = () => {
  const location = useLocation();
  const path = location?.pathname;
  const dispatch = useDispatch();
  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );
  const { notifyDetails } = useSelector((state) => state.notifyDetails);
  useEffect(() => {
    dispatch(GetNotificationDetailsAction());
  }, []);

  return (
    <div>
      <div className="bg-white h-14 px-4 flex items-center border-b border-gray-200 justify-between">
        <div className="relative">
          {/* <HiOutlineSearch fontSize={20} className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2" />
          <input
            type="text"
            placeholder="Search..."
            className="text-sm focus:outline-none active:outline-none border border-gray-300 w-[24rem] h-10 pl-11 pr-4 rounded-sm"
          /> */}
        </div>
        <div className="flex items-center gap-2 mr-2">
          <div className="relative">
            <Link
              to={`${process.env.PUBLIC_URL}/expense-tracker/expense`}
              className="group inline-flex items-center rounded-sm p-1 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100"
              data-tooltip-target="tooltip-bottom-add"
              data-tooltip-placement="bottom"
            >
              <CgAddR color="#363636" outline="#363636" fontSize={28} />
              <div
                id="tooltip-bottom-add"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                Add&nbsp;Expense
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </Link>
          </div>

          <div className="relative">
            <Link
              to={`${process.env.PUBLIC_URL}/expense-tracker/settings`}
              data-tooltip-target="tooltip-bottom-settings"
              data-tooltip-placement="bottom"
            >
              <div className="roup inline-flex items-center rounded-sm p-1 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100">
                <FiSettings fontSize={22} />
              </div>
              <div
                id="tooltip-bottom-settings"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                Settings
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </Link>
          </div>

          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open && "bg-gray-100",
                    "group inline-flex items-center rounded-sm p-1 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100"
                  )}
                  data-tooltip-target="tooltip-bottom-notification"
                  data-tooltip-placement="bottom"
                >
                  <HiOutlineBell fontSize={24} />
                  <div
                    id="tooltip-bottom-notification"
                    role="tooltip"
                    className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                  >
                    Notifications
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
                    <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                      <strong className="text-gray-700 font-medium">
                        Notifications
                      </strong>
                      {notifyDetails.length == 0 && (
                        <div className="mt-2 py-1 text-sm">None.</div>
                      )}
                      {notifyDetails.map((e, idx) => {
                        return (
                          <div
                            key={idx}
                            className={
                              e?.msg?.includes("saved")
                                ? "mt-2 py-1 text-sm text-blue-700"
                                : "mt-2 py-1 text-sm text-red-700"
                            }
                          >
                            {e?.msg}
                          </div>
                        );
                      })}
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <hr className="h-6 mt-2 w-px self-stretch  bg-gray-500 " />

          <Menu as="div" className="relative">
            <div className="flex">
              <div className="flex mt-1.5 ">
                <p className="text-eblack text-xl mb-0 uppercase font-medium">
                  {user?.username}
                </p>
              </div>
              <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                <div className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center">
                  {user?.photo == undefined ? (
                    <img
                      className=" w-10 h-10 rounded-full mx-auto"
                      src={avatar}
                      alt="avatar"
                    />
                  ) : (
                    <img
                      className=" w-10 h-10 rounded-full mx-auto"
                      src={user?.photo}
                      alt="user photo"
                    />
                  )}
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-[#0A0A0A] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/expense-tracker/`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-md text-[#EDEDED] font-medium"
                  >
                    Dashboard
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/expense-tracker/settings`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-md text-[#EDEDED] font-medium"
                  >
                    Settings
                  </Link>
                </Menu.Item>
                <hr />

                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/expense-tracker/profile`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-md text-[#EDEDED] font-medium"
                  >
                    My Account
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-md text-[#EDEDED] font-medium"
                  >
                    Expense Homepage
                  </Link>
                </Menu.Item>
                <hr />

                <Menu.Item>
                  <Link
                    onClick={() => {
                      dispatch(logoutUserAction());
                      secureLocalStorage.clear("pref");
                      window.open("/expense-tracker/auth-login", "_self");
                    }}
                    className="bg-[#0A0A0A] block px-4 py-2 hover:bg-gray-200 hover:text-black justify-center items-center p-1 text-md text-[#EDEDED] font-medium"
                  >
                    Log Out
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default NavbarTop;
