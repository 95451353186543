import React, { useState, useEffect } from "react";
import { RevolvingDot, MutatingDots } from "react-loader-spinner";
// import Loadingmage from "../../assets/loader/loader.gif";

export const Loader = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "33vh" }}
    >
      <MutatingDots
        height="100"
        width="100"
        color="#798bff"
        secondaryColor="#122499"
        ariaLabel="loading-indicator"
      />
    </div>
  );
};
// export const TableLoading = () => {
//   return (
//     <div className="grid h-auto place-items-center">
//       <img alt="Loading ..." width={100} src={Loadingmage} />
//     </div>
//   );
// };

export const StoneLoading = () => {
  return (
    <>
    </>
    // <div className="grid h-screen place-items-center">
    //   <img width={100} src={Loadingmage} />
    // </div>
  );
};

const Loading = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <MutatingDots
        height="100"
        width="100"
        color="#798bff"
        secondaryColor="#122499"
        ariaLabel="loading-indicator"
      />
    </div>
  );
};

export default Loading;
