import React from "react";
import Icon from "../icon/Icon";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const Block = ({ className, size, ...props }) => {
  const blockClass = classNames({
    "nk-block": true,
    [`nk-block-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={blockClass}>{props.children}</div>;
};
export const BlockContent = ({ className, ...props }) => {
  const blockContentClass = classNames({
    "nk-block-content": true,
    [`${className}`]: className,
  });
  return <div className={blockContentClass}>{props.children}</div>;
};

export const BlockBetween = ({ className, ...props }) => {
  return (
    <div className={`nk-block-between ${className ? className : ""}`}>
      {props.children}
    </div>
  );
};
export const BlockHead = ({ className, size, wide, ...props }) => {
  const blockHeadClass = classNames({
    "nk-block-head": true,
    [`nk-block-head-${size}`]: size,
    [`wide-${wide}`]: wide,
    [`${className}`]: className,
  });
  return <div className={blockHeadClass}>{props.children}</div>;
};
export const BlockHeadContent = ({ className, ...props }) => {
  return (
    <div
      className={[`nk-block-head-content${className ? " " + className : ""}`]}
    >
      {props.children}
    </div>
  );
};
export const BlockTitle = ({ className, page, ...props }) => {

  return (
    <React.Fragment>
      <div className="flex flex-col md:flex-row items-left justify-left space-y-3 mb-6">
        <div className="px-3 py-4 grid gap-3 md:flex md:justify-left md:items-left  border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold  font-poppins text-[EDEDED]  dark:text-white  ">
            {props.children}
          </h2>
        </div>
      </div>


    </React.Fragment>
  );
};
export const BlockButton = ({ ...props }) => {
  return (
    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center  border-gray-200 dark:border-gray-700">
      <div className="inline-flex gap-x-2">
        <div className="inline-flex gap-x-3 mb-2 px-3 py-2 gap-2 -mt-4">
          {props.children}
        </div>
      </div>
    </div>
  )
}
export const BlockDes = ({ className, page, ...props }) => {
  const classes = [`nk-block-des${className ? " " + className : ""}`];
  return <div className={classes}>{props.children}</div>;
};

export const BackTo = ({ className, link, icon, ...props }) => {
  const classes = [`back-to${className ? " " + className : ""}`];
  return (
    <div className="nk-block-head-sub">
      <Link className={classes} to={process.env.PUBLIC_URL + link}>
        <Icon name={icon} />
        <span>{props.children}</span>
      </Link>
    </div>
  );
};
