import React from "react";
import errorimg from "../../assets/server_down/server_down.png";
import Head from "../Head/Head";


const Page503 = () => {
  return (
    <div>
      <Head title="Error 503" />
      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="flex flex-col items-center">
            <img src={errorimg} className="h-[310px] mr-2" alt="Server_Down" />
            <h1 className="mb-2 text-center text-2xl font-extrabold text-gray-800 md:text-3xl">
              OOPS ! Something Went Wrong
            </h1>
            <p className="mb-12 max-w-screen-md text-center text-gray-500 md:text-lg">
              Something Went Wrong. Please Try Again.
            </p>
            <a
              className="cursor-pointer inline-block text-white bg-black
              focus:ring-4 focus:outline-none
              focus:ring-gray-300 dark:focus:ring-red-800 font-medium rounded-lg text-lg px-5
               py-2.5 text-center mr-2 mb-2"
              onClick={() => window.open("/", "_self")}
            >
              Retry
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page503;
