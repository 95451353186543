import React from "react";
import classNames from "classnames";
export const ButtonGroup = ({ ...props }) => {
  return (
    <div className="px-2 py-4 grid gap-0 md:flex md:justify-end md:items-end  border-gray-200 dark:border-gray-700">
      <div className="flex justify-end gap-0">
        {props.children}
      </div>
    </div>
  );
};



export const Button = ({ color, size, className, outline, disabled, ...props }) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });
  return (
    <button className={buttonClass} {...props}>
      {props.children}
    </button>
  );
};
