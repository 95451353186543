import React, { useEffect, useState, Suspense } from "react";
import {
  checkDashboardStatusAction,
  checkLoginDetailsAction,
  logoutUserAction,
} from "../../redux/action/authUser";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { Outlet } from "react-router-dom";
import { StoneLoading } from "../../components/lmx-loading/loading";
import secureLocalStorage from "react-secure-storage";
import EventBus from "../../common/EventBus";
import { ScrollMemory } from "../../App";
import MenuBarMobile from "../Layout/MenuBarMobile";
import LogoutModal from "../Models/LogoutModal";
import NavbarTop from "../Layout/NavbarTop";
import Sidebar from "../Layout/Sidebar";
import Footer from "../Layout/Footer"
const ExpenseTracker = () => {
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showlogOut, setshowlogOut] = useState(false);
  const [message, setmessage] = useState({});

  //
  // on idle -> set what happens
  const onIdle = () => {
    dispatch(logoutUserAction());
    setmessage({
      title: "You have been logged out due to inactivity",
      desc: "Your account has been automatically logged out because there has been no activity or interaction for a specific period of time.To continue using the application, simply log in again, and you will be able to access your account and resume your activities.",
    });
    setshowlogOut(true);
    secureLocalStorage.removeItem("user_pref");
  };
  //

  // idle timer setting
  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 45, // 45 minutes idle
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  useEffect(() => {
    dispatch(checkDashboardStatusAction());
    dispatch(checkLoginDetailsAction());
    window.scroll({ top: 0, behavior: "smooth" });
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      setmessage({
        title: "Login Expired",
        desc: "Your login has expired. To continue using the application, simply log in again, and you will be able to access your account and resume your activities.",
      });
      setshowlogOut(true);
      secureLocalStorage.removeItem("pref");
    });

    EventBus.on("acc_expired", () => { });

    return () => {
      EventBus.remove("logout");
      EventBus.remove("acc_expired");
    };
  }, []);

  return (
    <div className="AdminArea w-[100%]">
      <LogoutModal
        showlogOut={showlogOut}
        setshowlogOut={setshowlogOut}
        message={message}
      />
      <div className="h-screen w-screen overflow-hidden flex bg-gray-100 flex-row">
        <MenuBarMobile setter={setShowSidebar} show={showSidebar} />
        <Sidebar show={showSidebar} setter={setShowSidebar} />
        <div className="flex flex-col flex-1">
          <NavbarTop />
          <div className="flex flex-col flex-grow w-screen md:w-full    min-h-0 overflow-auto">
            <Suspense fallback={<StoneLoading />}>
              <ScrollMemory>
                <Outlet />
              </ScrollMemory>
            </Suspense>
          </div>
          {/* <div className="m-0">
            <Footer />
          </div> */}
        </div>
      </div>

      {/* <div className="min-h-screen flex">
        <MenuBarMobile setter={setShowSidebar} show={showSidebar} />
        <Sidebar show={showSidebar} setter={setShowSidebar} />
        <div className="flex flex-col flex-grow w-screen md:w-full min-h-screen  pt-20 md:pt-2 bg-gray-100 ">
          <Suspense fallback={<StoneLoading />}>
            <ScrollMemory>
              <Outlet />
            </ScrollMemory>
          </Suspense>
          <div className="m-0">
            <NavbarTop />
          </div>
          <div className="m-0">
            <Footer />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ExpenseTracker;
