import {
  LOGIN_USER_BGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER_BGIN,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  CHECK_DASHBOARD_STATUS_BGIN,
  CHECK_DASHBOARD_STATUS_SUCCESS,
  CHECK_DASHBOARD_STATUS_ERROR,
  EDIT_PROFILE_BGIN,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  CHANGE_PROFILE_PASSWORD_BGIN,
  CHANGE_PROFILE_PASSWORD_SUCCESS,
  CHANGE_PROFILE_PASSWORD_ERROR,
  CHECK_TOKEN_BGIN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  SEND_OTP_BGIN,
  SEND_OTP_SUCCESS,
  SEND_OTP_ERROR,
  VERIFY_OTP_BGIN,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  RESET_PASS_BGIN,
  RESET_PASS_SUCCESS,
  RESET_PASS_ERROR,
  CHECK_LOGIN_DETAILS_BGIN,
  CHECK_LOGIN_DETAILS_SUCCESS,
  CHECK_LOGIN_DETAILS_ERROR,
  ADD_USER_PHOTO_BGIN,
  ADD_USER_PHOTO_SUCCESS,
  ADD_USER_PHOTO_ERROR,
  SIGNUP_USER_BGIN,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  EDIT_USER_PHOTO_BGIN,
  EDIT_USER_PHOTO_SUCCESS,
  EDIT_USER_PHOTO_ERROR,
} from "../constant/authUser";

import {
  DispatchErrorHandler,
  Auth,
  BasicAuth,
  AuthFD,
} from "../configs";
import { toastsuccess } from "../../components/lmx-toast-style/toast-style";
import secureLocalStorage from "react-secure-storage";

export const loginUserAction =
  (username, password, setError) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_USER_BGIN });
      const { data } = await BasicAuth.post(`/auth/user_signin/`, {
        username,
        password,
      });
      // console.log(data);
      dispatch({ type: LOGIN_USER_SUCCESS, payload: data });
    } catch (error) {
      // console.log(error?.response?.data?.error_detail[0]);
      setError(error?.response?.data?.error_detail);
      dispatch({ type: LOGIN_USER_ERROR });
    }
  };

export const SignupUserAction = (adddata) => async (dispatch) => {
  try {
    dispatch({ type: SIGNUP_USER_BGIN });
    const data = await BasicAuth.post(`/auth/sign_up/`, adddata);
    dispatch({ type: SIGNUP_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SIGNUP_USER_ERROR });
    DispatchErrorHandler(error);
  }
};
export const logoutUserAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_USER_BGIN });
    const { data } = await Auth.post(`/auth/user_signout/`);
    // console.log(data);
    dispatch({ type: LOGOUT_USER_SUCCESS, payload: data });
  } catch (error) {
    // setError(error.response.data);
    dispatch({ type: LOGOUT_USER_ERROR });
  }
};

// check token valid / invalid

export const checkTokenAction = () => async (dispatch) => {
  try {
    dispatch({ type: CHECK_TOKEN_BGIN });
    const { data } = await Auth.get(`auth/check_token/`);

    dispatch({ type: CHECK_TOKEN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHECK_TOKEN_ERROR });
  }
};

export const checkDashboardStatusAction = (reqdata) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_DASHBOARD_STATUS_BGIN });
    const { data } = await Auth.post(`/auth/user_info/`, reqdata);
    // console.log(data);
    dispatch({ type: CHECK_DASHBOARD_STATUS_SUCCESS, payload: data.data });
  } catch (error) {
    // setError(error.response.data);
    dispatch({ type: CHECK_DASHBOARD_STATUS_ERROR });
    DispatchErrorHandler(error);
  }
};

// edit personal info
// export const editProfileAction =
//   (editdata, { setModalTab, setModal, toastsuccess, updateUserFn }) =>
//   async (dispatch) => {
//     try {
//       dispatch({ type: EDIT_PROFILE_BGIN });
//       const data = await Auth.post(`/admin_change/`, editdata);
//       dispatch({ type: EDIT_PROFILE_SUCCESS, payload: data?.data });
//       if (data?.data?.message?.toLowerCase().includes("email verification")) {
//         setModalTab("2");
//       } else {
//         updateUserFn();
//         setModal(false);
//         toastsuccess("Profile Information Updated.");
//       }
//     } catch (error) {
//       dispatch({ type: EDIT_PROFILE_ERROR });
//       DispatchErrorHandler(error);
//     }
//   };

// send otp
export const sendOTPAction = (postdata) => async (dispatch) => {
  try {
    dispatch({ type: SEND_OTP_BGIN });
    const data = await Auth.post(`/user_create_otp/`, postdata);
    dispatch({ type: SEND_OTP_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({ type: SEND_OTP_ERROR });
    DispatchErrorHandler(error);
  }
};

// verify otp
export const verifyOTPAction = (postdata) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_OTP_BGIN });
    const data = await Auth.post(`/user_verify_otp/`, postdata);
    dispatch({ type: VERIFY_OTP_SUCCESS, payload: data?.data });
    toastsuccess("Email is verified");
  } catch (error) {
    dispatch({ type: VERIFY_OTP_ERROR });
    DispatchErrorHandler(error);
  }
};

// Reset pass by Mail
export const resetPassAction = (postdata) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASS_BGIN });
    const { data } = await BasicAuth.post(`/user_reset_pass/`, postdata);
    dispatch({ type: RESET_PASS_SUCCESS, payload: data });
    toastsuccess(data?.message);
  } catch (error) {
    dispatch({ type: RESET_PASS_ERROR });
    DispatchErrorHandler(error);
  }
};

// change password
export const changeProfilePasswordAction = (editdata) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_PROFILE_PASSWORD_BGIN });
    const data = await Auth.post(`/user_change_pass/`, editdata);
    dispatch({ type: CHANGE_PROFILE_PASSWORD_SUCCESS, payload: data?.data });
    dispatch(logoutUserAction());
    secureLocalStorage.clear("pref");
    window.open("/", "_self");
  } catch (error) {
    dispatch({ type: CHANGE_PROFILE_PASSWORD_ERROR });
    DispatchErrorHandler(error);
  }
};

export const editProfileAction =
  (editdata, { setModalTab, toastsuccess, closemodal }) =>
  async (dispatch) => {
    try {
      dispatch({ type: EDIT_PROFILE_BGIN });
      const data = await Auth.post(`/user_change/`, editdata);
      dispatch({ type: EDIT_PROFILE_SUCCESS, payload: data?.data });
      if (data?.data?.message?.toLowerCase().includes("email verification")) {
        setModalTab("2");
      } else {
        toastsuccess("Profile Information Updated.");
        closemodal();
        const loginpref = secureLocalStorage.getItem("pref").pref;
        dispatch(checkDashboardStatusAction(loginpref));
      }
    } catch (error) {
      dispatch({ type: EDIT_PROFILE_ERROR });
      DispatchErrorHandler(error);
    }
  };

export const checkLoginDetailsAction = (reqdata) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_LOGIN_DETAILS_BGIN });
    const { data } = await Auth.get(`/auth/login_det/`, reqdata);
    dispatch({ type: CHECK_LOGIN_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    // setError(error.response.data);
    dispatch({ type: CHECK_LOGIN_DETAILS_ERROR });
    DispatchErrorHandler(error);
  }
};
//ADD USER PHOTO
export const addUserPhotoAction =
  (adddata, { toastsuccess, closemodal }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADD_USER_PHOTO_BGIN });
      const data = await AuthFD.post(`/user_profile/`, adddata);
      dispatch({ type: ADD_USER_PHOTO_SUCCESS, payload: data });
      dispatch(checkDashboardStatusAction({}));
      toastsuccess("Profile photo added successfully.");
      closemodal();
    } catch (error) {
      dispatch({ type: ADD_USER_PHOTO_ERROR });
      DispatchErrorHandler(error);
    }
  };

export const editUserPhotoAction =
  (id, editdata, { toastsuccess, closemodal }) =>
  async (dispatch) => {
    try {
      dispatch({ type: EDIT_USER_PHOTO_BGIN });
      const { data } = await AuthFD.put(`/user_profile/${id}/`, editdata);
      dispatch({ type: EDIT_USER_PHOTO_SUCCESS, payload: data?.data });
      dispatch(checkDashboardStatusAction({}));
      toastsuccess("Profile photo Updated successfully.");
      closemodal();
    } catch (error) {
      dispatch({ type: EDIT_USER_PHOTO_ERROR });
      DispatchErrorHandler(error);
    }
  };
