import { DispatchErrorHandler, AuthPay } from "../configs";
import {
  //MONTHLY SALARY
  ADD_MONTHLY_SALARY_BGIN,
  ADD_MONTHLY_SALARY_ERROR,
  ADD_MONTHLY_SALARY_SUCCESS,
  EDIT_MONTHLY_SALARY_BGIN,
  EDIT_MONTHLY_SALARY_ERROR,
  EDIT_MONTHLY_SALARY_SUCCESS,
  INDI_FETCH_MONTHLY_SALARY_BGIN,
  INDI_FETCH_MONTHLY_SALARY_ERROR,
  INDI_FETCH_MONTHLY_SALARY_SUCCESS,

  //EXPENSE DETAILS
  ADD_EXPENSE_DETAILS_BGIN,
  ADD_EXPENSE_DETAILS_ERROR,
  ADD_EXPENSE_DETAILS_SUCCESS,
  LIST_REPORT_DETAILS_BGIN,
  LIST_REPORT_DETAILS_SUCCESS,
  LIST_REPORT_DETAILS_ERROR,
  LIST_CURRENT_MONTHLYSALARY_DETAILS_BGIN,
  LIST_CURRENT_MONTHLYSALARY_DETAILS_SUCCESS,
  LIST_CURRENT_MONTHLYSALARY_DETAILS_ERROR,
  ADD_CREDIT_DETAILS_BGIN,
  ADD_CREDIT_DETAILS_SUCCESS,
  ADD_CREDIT_DETAILS_ERROR,
  LIST_HISTORY_DETAILS_BGIN,
  LIST_HISTORY_DETAILS_SUCCESS,
  LIST_HISTORY_DETAILS_ERROR,
  PRINT_HISTORY_DETAILS_BGIN,
  PRINT_HISTORY_DETAILS_SUCCESS,
  PRINT_HISTORY_DETAILS_ERROR,
  GRAPH_EXPENSE_BEGIN,
  GRAPH_EXPENSE_SUCCESS,
  GRAPH_EXPENSE_ERROR,
  NOTIFY_DETAILS_BEGIN,
  NOTIFY_DETAILS_SUCCESS,
  NOTIFY_DETAILS_ERROR,
} from "../constant/expense";

//MONTHLY SALARY

export const ListCurrentMonthlySalaryAction = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_CURRENT_MONTHLYSALARY_DETAILS_BGIN });
    const data = await AuthPay.get(`/monthly_salary/`);
    dispatch({
      type: LIST_CURRENT_MONTHLYSALARY_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: LIST_CURRENT_MONTHLYSALARY_DETAILS_ERROR });
    DispatchErrorHandler(error);
  }
};

export const addMonthlySalaryAction =
  (adddata, navigate) => async (dispatch) => {
    try {
      dispatch({ type: ADD_MONTHLY_SALARY_BGIN });
      const data = await AuthPay.post(`/monthly_salary/`, adddata);
      dispatch({ type: ADD_MONTHLY_SALARY_SUCCESS, payload: data });
      dispatch(ListCurrentMonthlySalaryAction());
      navigate("/expense-tracker/expense/");
    } catch (error) {
      dispatch({ type: ADD_MONTHLY_SALARY_ERROR });
      DispatchErrorHandler(error);
    }
  };

export const EditMonthlySalaryAction = (id, editdata) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_MONTHLY_SALARY_BGIN });
    const { data } = await AuthPay.put(`/expense_details/${id}/`, editdata);
    dispatch({ type: EDIT_MONTHLY_SALARY_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: EDIT_MONTHLY_SALARY_ERROR });
    DispatchErrorHandler(error);
  }
};

export const IndiFetchMonthlySalaryAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: INDI_FETCH_MONTHLY_SALARY_BGIN });
    const { data } = await AuthPay.get(`/expense_details/${id}/`);
    dispatch({ type: INDI_FETCH_MONTHLY_SALARY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: INDI_FETCH_MONTHLY_SALARY_ERROR });
    DispatchErrorHandler(error);
  }
};

//EXPENSE DETAILS

export const ListReportDetailsAction = (month, year) => async (dispatch) => {
  try {
    dispatch({ type: LIST_REPORT_DETAILS_BGIN });
    const { data } = await AuthPay.get(
      `/current_report/?month=${month}&year=${year}`
    );
    dispatch({ type: LIST_REPORT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LIST_REPORT_DETAILS_ERROR });
    DispatchErrorHandler(error);
  }
};

export const addExpenseDetailsAction =
  (adddata, navigate) => async (dispatch) => {
    try {
      dispatch({ type: ADD_EXPENSE_DETAILS_BGIN });
      const data = await AuthPay.post(`/expense_details/`, adddata);
      dispatch({ type: ADD_EXPENSE_DETAILS_SUCCESS, payload: data });
      dispatch(
        ListReportDetailsAction(
          new Date().toLocaleString("default", { month: "long" }),
          new Date().getFullYear()
        )
      );
      navigate("/expense-tracker/expense/");
    } catch (error) {
      dispatch({ type: ADD_EXPENSE_DETAILS_ERROR });
      DispatchErrorHandler(error);
    }
  };

export const addCreditDetailsAction =
  (adddata, navigate) => async (dispatch) => {
    try {
      dispatch({ type: ADD_CREDIT_DETAILS_BGIN });
      const data = await AuthPay.post(`/credit_details/`, adddata);
      dispatch({ type: ADD_CREDIT_DETAILS_SUCCESS, payload: data });
      dispatch(
        ListReportDetailsAction(
          new Date().toLocaleString("default", { month: "long" }),
          new Date().getFullYear()
        )
      );
      navigate("/expense-tracker/expense/");
    } catch (error) {
      dispatch({ type: ADD_CREDIT_DETAILS_ERROR });
      DispatchErrorHandler(error);
    }
  };

//HISTORY

export const ListHistoryDetailsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_HISTORY_DETAILS_BGIN });
    const { data } = await AuthPay.get(`/history_report/`);
    dispatch({ type: LIST_HISTORY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LIST_HISTORY_DETAILS_ERROR });
    DispatchErrorHandler(error);
  }
};

export const IndiHistoryDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRINT_HISTORY_DETAILS_BGIN });
    const { data } = await AuthPay.get(`/history_detail_report/${id}/`);
    dispatch({ type: PRINT_HISTORY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRINT_HISTORY_DETAILS_ERROR });
    DispatchErrorHandler(error);
  }
};

//GRAPH
export const GetExpenseGraphAction = () => async (dispatch) => {
  try {
    dispatch({ type: GRAPH_EXPENSE_BEGIN });
    const { data } = await AuthPay.get(`/expense_graph/`);
    dispatch({ type: GRAPH_EXPENSE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GRAPH_EXPENSE_ERROR });
    DispatchErrorHandler(error);
  }
};

//Notify
export const GetNotificationDetailsAction = () => async (dispatch) => {
  try {
    dispatch({ type: NOTIFY_DETAILS_BEGIN });
    const { data } = await AuthPay.get(`/notify_details/`);
    dispatch({ type: NOTIFY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: NOTIFY_DETAILS_ERROR });
    DispatchErrorHandler(error);
  }
};
