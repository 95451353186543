import React from 'react'
import comingsoon from "../../../assets/404/commig.png"
import { Link } from 'react-router-dom'
const Analytics = () => {
  return (
    <div className="bg-white py-6 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div className="flex flex-col items-center">
          <img src={comingsoon} className="h-[310px] mr-2" alt="MJDTA Logo" />
          <a className="inline-block text-white bg-black cursor-pointer
          focus:ring-4 focus:outline-none
          focus:ring-gray-300 dark:focus:ring-red-800 font-medium rounded-lg text-lg px-5
           py-2.5 text-center mr-2 mb-2">
            <Link to="/expense-tracker"> ← Go Home</Link>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Analytics