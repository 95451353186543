import React from 'react'
import img from "../../assets/favicon.png"
import { Link } from 'react-router-dom'
const Navbar = () => {
    return (
        <div>
            <nav className="bg-eblack fixed w-full top-0  border-gray-200 dark:bg-gray-900">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
                    <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={img} className="h-8" alt="Expense Logo" />
                        <span className="self-center text-2xl  text-white font-poppins font-semibold whitespace-nowrap dark:text-white">Expense</span>
                    </Link>

                </div>
            </nav>


        </div>
    )
}

export default Navbar