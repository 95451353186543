import React from 'react'
import { useEffect, useState } from 'react'
import { BiArrowFromBottom } from 'react-icons/bi'
import classNames from 'classnames'

const BottomtoTop = () => {
    const [isVisible, setIsVisible] = useState(false)
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)

        return () => {
            window.removeEventListener('scroll', toggleVisibility)
        }
    }, [])
    return (
        <div>
            <div className="fixed bottom-2 right-2">
                <button
                    type="button"
                    onClick={scrollToTop}
                    className={classNames(
                        isVisible ? 'opacity-100' : 'opacity-0',
                        'bg-black inline-flex items-center rounded-xl p-2 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2',
                    )}
                >
                    <BiArrowFromBottom className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
        </div>
    )
}

export default BottomtoTop