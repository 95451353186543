import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  loginUserReducer,
  logoutUserReducer,
  checkDashboardStatusReducer,
  editProfileReducer,
  changeProfilePasswordReducer,
  checkTokenReducer,
  sendOTPReducer,
  verifyOTPReducer,
  resetPassReducer,
  checkLoginDetailsReducer,
  addUserPhotoReducer,
  SignupUserReducer,
  editUserPhotoReducer,
} from "./reducer/authUser";
import {
  EditMonthlySalaryReducer,
  GetExpenseGraphReducer,
  GetNotificationDetailsReducer,
  IndiFetchMonthlySalaryReducer,
  IndiHistoryDetailsReducer,
  ListCurrentMonthlySalaryReducer,
  ListHistoryDetailsReducer,
  addCreditDetailsReducer,
  addExpenseDetailsReducer,
  addMonthySalaryReducer,
  listReportDetailsReducer,
} from "./reducer/expense";

const reducer = combineReducers({
  //auth
  loginstate: loginUserReducer,
  Signupstate: SignupUserReducer,
  logoutUser: logoutUserReducer,
  checkDashboardStatus: checkDashboardStatusReducer,
  loginDetailsCheck: checkLoginDetailsReducer,
  editProfile: editProfileReducer,
  sendOTP: sendOTPReducer,
  verifyOTP: verifyOTPReducer,
  resetPass: resetPassReducer,
  changeProfilePassword: changeProfilePasswordReducer,
  checkToken: checkTokenReducer,
  //ADD USER PHOTO
  addUserPhoto: addUserPhotoReducer,
  editUserPhoto: editUserPhotoReducer,

  //EXPENSE TRACKER

  //MONTHLY SALARY
  salaryCurrent: ListCurrentMonthlySalaryReducer,
  addMonthlySalary: addMonthySalaryReducer,
  editMonthlySalary: EditMonthlySalaryReducer,
  IndiMonthlySalary: IndiFetchMonthlySalaryReducer,

  //EXPENSE DETAILS
  listReportDetails: listReportDetailsReducer,
  addExpenseDetails: addExpenseDetailsReducer,

  addCreditDetails: addCreditDetailsReducer,

  //HISTORY
  HistoryDetails: ListHistoryDetailsReducer,
  HistoryIndi: IndiHistoryDetailsReducer,

  //GRAPH
  expenseGraph: GetExpenseGraphReducer,

  //Notify
  notifyDetails: GetNotificationDetailsReducer,
});

const initialstate = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
