import secureLocalStorage from "react-secure-storage";
import EventBus from "../common/EventBus";

import axios from "axios";

export const BasicAuth = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const Auth = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `Token ${secureLocalStorage.getItem("pref")?.token}`,
  },
});
export const AuthPay = axios.create({
  baseURL: process.env.REACT_APP_EXPENSEAPI_ENDPOINT,
  headers: {
    Authorization: `Token ${secureLocalStorage.getItem("pref")?.token}`,
    "Content-Type": "application/json",
  },
});


export const AuthPayFD = axios.create({
  baseURL: process.env.REACT_APP_EXPENSEAPI_ENDPOINT,
  headers: {
    Authorization: `Token ${secureLocalStorage.getItem("pref")?.token}`,
    "Content-Type": "multipart/form-data",
  },
});

export const AuthFD = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `Token ${secureLocalStorage.getItem("pref")?.token}`,
    "Content-Type": "multipart/form-data",
  },
});
export const AuthPrv = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${secureLocalStorage.getItem("user_pref")?.token}`,
  },
});

export const AuthPrvFD = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${secureLocalStorage.getItem("user_pref")?.token}`,
  },
});
// login company and branch info
export const company = secureLocalStorage.getItem("pref")?.pref?.id_company;
export const branch = secureLocalStorage.getItem("pref")?.pref?.id_branch;

// fetch API url from env file
export const baseUrl = process.env.REACT_APP_API_ENDPOINT;

//  Function to check
// if unauthorized{or token expired} logout
// and trigger APP logout
export const DispatchErrorHandler = (error) => {
  if (error?.code == "ERR_NETWORK") {
    EventBus.dispatch("server_down");
  }
  if (error?.response?.status == 401) {
    EventBus.dispatch("logout");
  }
  // if not a token expire error; catch Acc expiry errors validation like errors => and dispatch event with error data
  else {
    // Account Expiry / No Permissions error handle
    if (error?.response?.status == 403) {
      if (
        !Array.isArray(error?.response?.data?.error_detail) &&
        error?.response?.data?.error_detail
          .toLowerCase()
          .includes("account has expired")
      ) {
        EventBus.dispatch("acc_expired");
      } else {
        EventBus.dispatch("error", error?.response?.data?.error_detail);
      }
    } //other
    else {
      EventBus.dispatch("error", error?.response?.data?.error_detail);
    }
  }
};
