import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Modal, Spinner } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toastsuccess } from "../../components/lmx-toast-style/toast-style";
import {
  addUserPhotoAction,
  checkDashboardStatusAction,
  editProfileAction,
  editUserPhotoAction,
} from "../../redux/action/authUser";
import Icon from "../../components/icon/Icon";
import store from "../../redux/store";

const ProfMod = ({ state, dispatch }) => {
  const redux_dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkDashboardStatus: { user } = {} } = useSelector(
    (state) => state.checkDashboardStatus
  );
  const { loading: addUserPhoto } = useSelector((state) => state.addUserPhoto);
  const { loading: editUserPhoto } = useSelector(
    (state) => state.editUserPhoto
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const id = user?.id;
  useEffect(() => {
    dispatch(checkDashboardStatusAction());
  }, []);
  const closemodal = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };
  const [memberphoto, setmemberphoto] = useState("");
  const edit_profile = (data) => {
    data = {
      profile_photo: memberphoto,
    };
    redux_dispatch(addUserPhotoAction(data, { toastsuccess, closemodal }));
    if (store.getState().addUserPhoto.hasOwnProperty("error") == false) {
      reset({
        memberphoto: "",
      });
      dispatch({ type: "CLOSE_MODAL" });
    }
  };

  const update_profile = (data) => {
    data = {
      profile_photo: memberphoto,
    };
    redux_dispatch(
      editUserPhotoAction(user?.photo_id, data, { toastsuccess, closemodal })
    );
    if (store.getState().editUserPhoto.hasOwnProperty("error") == false) {
      reset();
      dispatch({ type: "CLOSE_MODAL" });
    }
  };
  // console.log(IndiEmp);

  return (
    <React.Fragment>
      {state.mode === "change_prof" ? (
        <Modal
          className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem] justify-center"
          dismissible
          show={state.showModalB}
          size="xl"
          popup={true}
          onClose={() => {
            dispatch({ type: "CLOSE_MODAL" });
          }}
        >
          <Modal.Header />
          <Modal.Body key={"change_prof"}>
            <div className="space-y-6 ">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Add New Profile Photo
              </h3>
              <div>
                <div className=" block">
                  <Label htmlFor="emp_photo" value=" Upload Photo" />
                </div>
                <input
                  {...register("member_photo", {
                    required: "Profile Photo is required",
                  })}
                  className="block w-full text-sm text-gray-900 border
                      border-gray-300 rounded-lg cursor-pointer bg-gray-50
                       dark:text-gray-400 focus:outline-none dark:bg-gray-700
                        dark:border-gray-600 dark:placeholder-gray-400"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setmemberphoto(e.target.files[0]);
                  }}
                />
                {errors?.member_photo && (
                  <span className="text-danger text-red-600">
                    <Icon className={"sm"} name="alert-circle" />
                    {errors.member_photo.message}
                  </span>
                )}
              </div>
              <div className="flex justify-end gap-4 pt-0">
                <Button
                  size="sm"
                  className="flex justify-center rounded border cursor-pointer 
           border-stroke py-0  font-medium text-white bg-red-500 hover:shadow-1
           hover:bg-red-600"
                  disabled={addUserPhoto}
                  onClick={() => {
                    dispatch({ type: "CLOSE_MODAL" });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="flex justify-center rounded border cursor-pointer 
         border-stroke py-0 font-medium text-white bg-green-500 hover:shadow-1
         hover:bg-green-600"
                  disabled={addUserPhoto}
                  onClick={handleSubmit(edit_profile)}
                  size="sm"
                >
                  {addUserPhoto ? <Spinner size="sm" /> : " Update Profile"}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          className="pl-0 lg:pl-0 md:pl-[200px] mt-[3.5rem] justify-center"
          dismissible
          show={state.showModalB}
          size="xl"
          popup={true}
          onClose={() => {
            dispatch({ type: "CLOSE_MODAL" });
          }}
        >
          <Modal.Header />
          <Modal.Body key={"update_prof"}>
            <div className="space-y-6 ">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Update New Profile Photo
              </h3>
              <div>
                <div className=" block">
                  <Label htmlFor="emp_photo" value=" Upload Photo" />
                </div>
                <input
                  {...register("member_photo", {
                    required: "Profile Photo is required",
                  })}
                  className="block w-full text-sm text-gray-900 border
                             border-gray-300 rounded-lg cursor-pointer bg-gray-50
                              dark:text-gray-400 focus:outline-none dark:bg-gray-700
                               dark:border-gray-600 dark:placeholder-gray-400"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setmemberphoto(e.target.files[0]);
                  }}
                />
                {errors?.member_photo && (
                  <span className="text-danger text-red-600">
                    <Icon className={"sm"} name="alert-circle" />
                    {errors.member_photo.message}
                  </span>
                )}
              </div>
              <div className="flex justify-end gap-4 pt-0">
                <Button
                  size="sm"
                  className="flex justify-center rounded border cursor-pointer 
                  border-stroke py-0  font-medium text-white bg-red-500 hover:shadow-1
                  hover:bg-red-600"
                  disabled={editUserPhoto}
                  onClick={() => {
                    dispatch({ type: "CLOSE_MODAL" });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="flex justify-center rounded border cursor-pointer 
                border-stroke py-0 font-medium text-white bg-green-500 hover:shadow-1
                hover:bg-green-600"
                  disabled={editUserPhoto}
                  onClick={handleSubmit(update_profile)}
                  size="sm"
                >
                  {editUserPhoto ? <Spinner size="sm" /> : " Update Profile"}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ProfMod;
