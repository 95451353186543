import React from "react";

const Content = ({ ...props }) => {
  return (
    <div className="bg-transparent dark:bg-gray-900 p-1 ">
      <div className=" px-1 py-4 sm:px-6 lg:px-2  mx-auto">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg ">
          <div className="components-preview wide-md mx-auto">{props.children}</div>
        </div>
      </div>

    </div>
  );
};
export default Content;
