import { Button, Modal } from "flowbite-react";
import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import secureLocalStorage from "react-secure-storage";

const LogoutModal = ({ showlogOut, setshowlogOut, message }) => {
  //
  const logOut = React.useCallback(() => {
    setshowlogOut(false);
    setTimeout(() => {
      secureLocalStorage.clear("pref");
      // window.location.navigate();
      window.open("/expense-tracker/auth-login", "_self")
    }, 100);
  }, [setshowlogOut]);
  //
  return (
    <Modal
      className="pt-32 md:pt-0  pl-0 lg:pl-0 md:pl-[200px] bg-opacity-90 "
      dismissible
      show={showlogOut}
      size="lg"
      popup={true}
      onClose={() => {
        logOut();
      }}
    >
      <Modal.Header className="!border-b ">

        <div className="px-10 md:px-12 text-xs md:text-sm  py-2 flex items-center">
          <AiOutlineWarning color="red" />
          {message.title}
        </div>
      </Modal.Header>
      <Modal.Body className="!border-b pb-0  ">
        <div className="space-y-6 px-6 pb-2 sm:pb-2 lg:px-8 xl:pb-2 ">
          <div className="space-y-6">
            <p className=" leading-relaxed text-gray-500 dark:text-gray-400 !text-xs">
              {message.desc}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="!p-2">
        <div className="flex justify-center gap-4  w-full ">
          <Button
            className=""
            color="failure"
            onClick={() => {
              logOut();
            }}
            size="sm"
          >
            Okay
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
