
import india from "../../assets/contact/india.png"
import singapore from "../../assets/contact/singapore.png"
import logo from "../../assets/logo/logo1.png"
import { Link } from "react-router-dom";
import publici from "../../assets/contact/public.png"
import customer from "../../assets/contact/customer.png"
import analyst from "../../assets/contact/analyst.png"
import phone from "../../assets/contact/phone.png"
import settings from "../../assets/contact/settings.png"
import upgrade from "../../assets/contact/upgrade.png"
import cancel from "../../assets/contact/cancel.png"
import support from "../../assets/contact/support.png"
import React, { useEffect, useRef, useState } from "react";
import { Collapse, Dropdown } from "flowbite";
import BottomtoTop from "../../components/BottomtoTop/BottomtoTop";
const Contact = () => {
    let getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };
    const target = useRef(null);
    const trigger = useRef(null);
    const otherButton = useRef(null);
    const otherDropdown = useRef(null);
    const [bool, setbool] = useState(false)
    //Mobile Navbar func
    const mobile = () => {
        const hammenu = target.current;
        const nav = trigger.current;

        const navoptions = {
            onCollapse: () => { },
            onExpand: () => { },
            onToggle: () => { },
        };
        const mobnav = new Collapse(hammenu, nav, navoptions);
        mobnav.expand();
        mobnav.collapse();

        if (bool === true) {
            mobnav.collapse();
        }
    };


    //Other Dropdown function
    const otherDrop = () => {
        const other = otherButton.current;
        const otherdown = otherDropdown.current;
        const otheroptions = {
            placement: "bottom",
            triggerType: "click",
            offsetSkidding: 0,
            offsetDistance: 10,
            delay: 300,
            onHide: () => { },
            onShow: () => { },
            onToggle: () => { },
        };
        const dropdownother = new Dropdown(otherdown, other, otheroptions);
        dropdownother.show();

        dropdownother.hide();
    };

    useEffect(() => {
        mobile();
        otherDrop();
    }, []);

    return (
        <div>

            <nav className="bg-white dark:bg-white-900 fixed w-full z-20 p-1.5 top-0 left-0 border-b border-gray-200 dark:border-gray-600  ">
                <div className="xl:container m-auto px-6 md:px-12">
                    <div className="flex flex-wrap items-center justify-between gap-6 md:py-3 md:gap-0">
                        <div className="w-full flex justify-between lg:w-auto">
                            <a href="https://logimaxindia.com" target="_blank" className="flex items-center">
                                <img src={logo} className="h-10 mr-4" alt="logimax Logo" />
                            </a>
                            <div className="flex md:order-2 overflow-hidden">
                                <button
                                    data-collapse-toggle="navbar-sticky"
                                    id="hammenu"
                                    ref={trigger}
                                    type="button"
                                    className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 overflow-visible"
                                    aria-controls="navbar-sticky"
                                    aria-expanded="false"
                                >
                                    <span className="sr-only">Open main menu</span>
                                    <svg
                                        className="w-6 h-6"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div
                            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                            id="navbar-sticky"
                            aria-current="page"
                            ref={target} >
                            <div className="w-full space-y-2 border-primary/10 dark:border-gray-700 flex flex-col -ml-1 sm:flex-row lg:space-y-0 md:w-max lg:border-l">
                                <a href="https://www.logimaxindia.com/profile.php" target="_blank"
                                    className="text-black  font-semibold font-poppins block px-4 py-2 pl-3 pr-4
                          text-lg">About</a>
                                <a href="https://logimaxindia.com/bullion-live-rate.php" target="_blank"
                                    className="text-black  font-semibold font-poppins block px-4 py-2 pl-3 pr-4
                          text-lg">Products</a>

                                <a href="#inquires"
                                    className="text-black  font-semibold font-poppins block px-4 py-2 pl-3 pr-4 
                         text-lg">General Inquires</a>

                                <a href="#offices"
                                    className="text-black  font-semibold font-poppins block px-4 py-2 pl-3 pr-4 
                         text-lg">Our offices</a>

                            </div>
                            <button
                                ref={otherButton}
                                id="others"
                                data-dropdown-toggle="dropdownNavbar2"
                                data-dropdown-trigger="hover"
                                className="text-blue-600 flex  font-semibold font-poppins  px-4 py-2 pl-3 pr-4
                                text-lg"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" className="mt-0.5">
                                    <rect width="24" height="24" fill="white" />
                                    <circle cx="12" cy="12" r="9" stroke="#000000" strokeLinejoin="round" />
                                    <path d="M12 3C12 3 8.5 6 8.5 12C8.5 18 12 21 12 21" stroke="#000000" strokeLinejoin="round" />
                                    <path d="M12 3C12 3 15.5 6 15.5 12C15.5 18 12 21 12 21" stroke="#000000" strokeLinejoin="round" />
                                    <path d="M3 12H21" stroke="#000000" strokeLinejoin="round" />
                                    <path d="M19.5 7.5H4.5" stroke="#000000" strokeLinejoin="round" />
                                    <g filter="url(#filter0_d_15_556)">
                                        <path d="M19.5 16.5H4.5" stroke="#000000" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_15_556" x="3.5" y="16" width="17" height="3" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="1" />
                                            <feGaussianBlur stdDeviation="0.5" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_556" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_556" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                                EN{" "}
                                <svg
                                    className="w-5 h-5 mt-1 ml-1"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                            <div
                                ref={otherDropdown}
                                id="dropdownNavbar2"
                                className="z-40 hidden font-normal bg-white divide-y divide-gray-100 rounded shadow w-30 dark:bg-gray-700 dark:divide-gray-600" >
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton"  >

                                    <a
                                        className="text-blue-600  font-semibold font-poppins block px-4 py-2 pl-3 pr-4text-lg">English</a>
                                    <a
                                        className="text-black  font-semibold font-poppins block px-4 py-2 pl-3 pr-4 text-lg">Tamil</a>
                                    <a
                                        className="text-black  font-semibold font-poppins block px-4 py-2 pl-3 pr-4  text-lg">Hindi</a>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </nav>

            <section className="text-white bg-white" >
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h3 className="text-center text-4xl mt-10  mb-5 font-medium text-black font-poppins">
                            Looking for something in particular?
                        </h3>
                        <p className="text-center text-2xl font-medium font-poppins  text-black ">We're here to help.
                        </p>
                    </div>
                    <div className="flex flex-wrap ">
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <img
                                        className="mx-auto w-12"
                                        src={phone}
                                        alt="phone" />
                                </div>
                                <h2 className="text-gray-700  text-center text-xl mb-5 font-medium">Looking for a demo,
                                    or have questions about our products and pricing?</h2>
                                <div className="flex-grow text-center">
                                    <a href="mailto: sales@logimaxindia.com"
                                        className="leading-relaxed text-md  font-medium text-blue-600">
                                        sales@logimaxindia.com </a>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <img
                                        className="mx-auto w-12"
                                        src={settings}
                                        alt="settings" />
                                </div>
                                <h2 className="text-gray-700  text-center text-xl mb-5 font-medium"> Need help with adding licenses,
                                    changing plans, or additional services?</h2>
                                <div className="flex-grow text-center">
                                    <a href="mailto:support@logimaxindia.com"
                                        className="leading-relaxed text-md  font-medium text-blue-600">
                                        support@logimaxindia.com </a>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <img
                                        className="mx-auto w-12"
                                        src={upgrade}
                                        alt="upgrade" />
                                </div>
                                <h2 className="text-gray-700  text-center text-xl mb-5 font-medium"> Want to renew your subscription or need details about your
                                    current plan?</h2>
                                <div className="flex-grow text-center">
                                    <a href="mailto:support@logimaxindia.com"
                                        className="leading-relaxed text-md  font-medium text-blue-600">
                                        support@logimaxindia.com </a>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <img
                                        className="mx-auto w-12"
                                        src={cancel}
                                        alt="cancel" />
                                </div>
                                <h2 className="text-gray-700  text-center text-xl mb-5 font-medium">Need to cancel your
                                    subscription?
                                    We're sad to see you go, but ready to help.</h2>
                                <div className="flex-grow text-center">
                                    <a href="mailto:support@logimaxindia.com"
                                        className="leading-relaxed text-md  font-medium text-blue-600">
                                        support@logimaxindia.com </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="text-white bg-blue-800" id="inquires">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h3 className="text-center text-4xl mt-10  mb-5 font-medium text-white font-poppins">
                            General Inquiries
                        </h3>
                        <p className="text-center text-2xl  text-white ">Want to get in touch? Drop us a line.
                        </p>
                    </div>
                    <div className="flex flex-wrap ">
                        <div className="p-4 md:w-1/3">
                            <div className="flex rounded-lg h-full bg-white p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <img
                                        className="mx-auto w-24"
                                        src={publici}
                                        alt="illustration"
                                    />
                                </div>
                                <h2 className="text-gray-900  text-center text-3xl mb-5 font-semibold"> Public Relations</h2>

                                <div className="flex-grow text-center">
                                    <a href="mailto:support@logimaxindia.com"
                                        className="leading-relaxed text-xl  font-medium text-blue-600">
                                        support@logimaxindia.com </a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 md:w-1/3">
                            <div className="flex rounded-lg h-full bg-white p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <img
                                        className="mx-auto w-24"
                                        src={analyst}
                                        alt="illustration"
                                    />
                                </div>
                                <h2 className="text-gray-900  text-center text-3xl mb-5 font-semibold"> Analyst Relations</h2>
                                <div className="flex-grow text-center">
                                    <a href="mailto:support@logimaxindia.com"
                                        className="leading-relaxed text-xl  font-medium text-blue-600">
                                        support@logimaxindia.com </a>
                                </div>

                                <div className="flex-grow text-center">
                                    <a href="tel:+91 9626243499 "
                                        className="leading-relaxed text-xl  font-medium text-blue-600">
                                        +91 9626243499 </a>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 md:w-1/3">
                            <div className="flex rounded-lg h-full bg-white p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <img
                                        className="mx-auto w-24"
                                        src={customer}
                                        alt="illustration"
                                    />
                                </div>
                                <h2 className="text-gray-900  text-center text-3xl mb-5 font-semibold"> Customer Relations</h2>
                                <div className="flex-grow text-center">
                                    <a href="tel: +91 97515 05999 "
                                        className="leading-relaxed text-xl  font-medium text-blue-600">
                                        +91 9751505999 </a>
                                </div>
                                <div className="flex-grow text-center">
                                    <a href="mailto:support@logimaxindia.com"
                                        className="leading-relaxed text-xl  font-medium text-blue-600">
                                        support@logimaxindia.com </a>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="text-white bg-white" id="offices">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h3 className="text-center text-4xl mt-10  mb-5 font-medium text-black font-poppins">
                            Our offices around the world
                        </h3>
                        <p className="text-center text-2xl  text-black ">Connect with one of our global offices.
                            or feel free to just send us a email. We won't mind!</p>
                    </div>
                    <div className="flex flex-wrap ">
                        <div className="p-4 md:w-1/3">
                            <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <div className="w-8 h-8 mr-4 inline-flex justify-end  flex-shrink-0">
                                        <img src={india} alt="india" />
                                    </div>
                                    <h2 className="text-gray-900 text-2xl  font-bold"> India</h2>
                                </div>
                                <div className="flex-grow">
                                    <p className="leading-relaxed text-xl font-medium text-gray-700">
                                        <p className="text-left text-black font-medium text-xl mb-3">Logimax Technologies (P) Limited</p>
                                        Logimax Enclave, 24&25,<br />
                                        L&T Bypass Karpagam University Junction, Near L&T Toll Gate 3,
                                        Eachanari , Coimbatore,<br />
                                        Tamil Nadu - 641 021,<br /> India </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 md:w-1/3">
                            <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <div className="w-8 h-8 mr-4 inline-flex justify-end  flex-shrink-0">
                                        <img src={singapore} alt="singapore" />
                                    </div>
                                    <h2 className="text-gray-900 text-2xl  font-bold"> Singapore</h2>
                                </div>
                                <div className="flex-grow">
                                    <p className="leading-relaxed text-xl font-medium text-gray-700">
                                        <p className="text-left text-black font-medium text-xl mb-3">Logimax Technologies Pte Limited</p>
                                        315A, Anchorvale Road,<br />
                                        #05-152, Anchorvale Gardens,
                                        Singapore 541315. </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 md:w-1/3">
                            <div className="flex rounded-lg h-full bg-white border p-8 flex-col">
                                <div className="flex items-center mb-3">
                                    <div className="w-8 h-8 mr-4 inline-flex justify-end  flex-shrink-0">
                                        <img src={support} alt="support" />
                                    </div>
                                    <h2 className="text-gray-900 text-2xl  font-bold"> ASK FOR SUPPORT</h2>
                                </div>
                                <div className="flex-grow">
                                    <p className="leading-relaxed text-center text-md font-medium text-gray-700">
                                        <p className="text-center text-black font-medium text-xl mb-3">Logimax Technologies (P) Limited</p>
                                        If you have any questions don't hesitate to contact us. </p>
                                    <p className="text-center text-black font-medium text-lg mb-4">Monday to Saturday : 9:45 Am to 11:00 PM (IST)
                                    </p>
                                    <div className="flex-grow text-center mb-2">
                                        <a href="mailto:support@logimaxindia.com"
                                            className="leading-relaxed text-xl text-center  font-medium text-blue-600">
                                            support@logimaxindia.com </a>
                                    </div>
                                    <div className="flex-grow text-center">
                                        <a href="tel:+91 9626243499 "
                                            className="leading-relaxed text-xl  font-medium text-blue-600">
                                            +91 9626243499 </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


            <section className="bg-blue-900 py-16 ">
                <div className="container m-auto px-6 text-center md:px-12 lg:px-20">
                    <h2 className="mb-5 text-4xl font-semibold font-poppins text-white md:text-4xl">
                        Ready to do your best work?
                    </h2>
                    <h2 className="mb-8 text-3xl font-medium  text-white md:text-4xl">
                        Let's get you started.
                    </h2>
                    <a
                        href="#"
                        className="relative flex h-12 w-full mx-auto items-center 
                        justify-center px-8 before:absolute before:inset-0 before:rounded
                         before:bg-blue-700 before:transition before:duration-300 hover:before:scale-105 
                         active:duration-75 active:before:scale-95 sm:w-max"
                    >
                        <Link to="/" className="relative text-xl flex font-semibold text-white ">Get Started
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                <path d="M10 7L15 12L10 17" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </Link >
                    </a>
                </div>
            </section>


            <footer className="bg-white dark:bg-gray-800  border-2 bottom-0   ">
                <div className="mx-auto py-7 text-center">
                    <div className="flex flex-row justify-center  mt-2 mb-4 space-x-5 sm:justify-center md:mt-0">
                        <a href="https://twitter.com/logimax123" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                                <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"></path>
                            </svg>
                        </a>
                        <a href="mailto:support@logimaxindia.com" target="_blank" rel="noreferrer"  >
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                                <path d="M12 23.403V23.39 10.389L11.88 10.3h-.01L9.14 8.28C7.47 7.04 5.09 7.1 3.61 8.56 2.62 9.54 2 10.9 2 12.41v3.602L12 23.403zM38 23.39v.013l10-7.391V12.41c0-1.49-.6-2.85-1.58-3.83-1.46-1.457-3.765-1.628-5.424-.403L38.12 10.3 38 10.389V23.39zM14 24.868l10.406 7.692c.353.261.836.261 1.189 0L36 24.868V11.867L25 20l-11-8.133V24.868zM38 25.889V41c0 .552.448 1 1 1h6.5c1.381 0 2.5-1.119 2.5-2.5V18.497L38 25.889zM12 25.889L2 18.497V39.5C2 40.881 3.119 42 4.5 42H11c.552 0 1-.448 1-1V25.889z"></path>
                            </svg>

                        </a>
                        <a href="https://www.linkedin.com/company/logimaxtechnologies/mycompany/" target="_blank" rel="noreferrer">
                            <svg width="30" height="30" fill="currentColor" className="text-xl transition-colors text-black duration-200 hover:text-black dark:hover:text-black" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                                </path>
                            </svg>
                        </a>
                        <a href="https://www.facebook.com/LogimaxTechnologies" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                                <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                            </svg>
                        </a>
                    </div>
                    <div className="flex flex-row justify-center items-center mt-5 text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-0">
                        <Link to="https://www.logimaxindia.com/" target="_blank" className="mr-4 hover:text-blue-600 md:mr-6 "> Home </Link>

                        <Link to="https://www.logimaxindia.com/logimax-contact.php" target="_blank" className="mr-4 hover:text-blue-600 md:mr-6 "> Contact </Link>

                        <Link to="https://www.logimaxindia.com/profile.php" target="_blank" className="mr-4 hover:text-blue-600 md:mr-6 ">About</Link>

                        <Link to="https://www.logimaxindia.com/bullion-live-rate.php" target="_blank" className="mr-4 hover:text-blue-600 md:mr-6 ">Products</Link>

                        <Link to="/terms" target="_blank" className="mr-4 hover:text-blue-600 md:mr-6 ">Terms</Link>

                        <Link to="/faq" target="_blank" className="mr-4 hover:text-blue-600 md:mr-6 ">Faq</Link>
                    </div>
                </div>


                <div className=" bg-gray-200 mx-auto  p-3 md:flex md:items-center md:justify-between">
                    <span className="text-sm text-gray-500 sm:text-center font-medium dark:text-gray-400">  © {getYear()} Logimax Payroll - All Rights Reserved.
                    </span>
                    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                        <li>
                            <Link to="/terms" target="_blank" className="mr-4 hover:underline md:mr-6 ">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/terms" target="_blank" className="mr-4 hover:underline md:mr-6 "> Terms & conditions</Link>
                        </li>
                        <li>
                            <Link to="/faq" target="_blank" className="mr-4 hover:underline md:mr-6 ">Help</Link>
                        </li>
                    </ul>
                </div>
            </footer>
            {/* <BottomtoTop/> */}
        </div >

    )
}

export default Contact