import {
  //MONTHLY SALARY
  ADD_MONTHLY_SALARY_BGIN,
  ADD_MONTHLY_SALARY_ERROR,
  ADD_MONTHLY_SALARY_SUCCESS,
  EDIT_MONTHLY_SALARY_BGIN,
  EDIT_MONTHLY_SALARY_ERROR,
  EDIT_MONTHLY_SALARY_SUCCESS,
  INDI_FETCH_MONTHLY_SALARY_BGIN,
  INDI_FETCH_MONTHLY_SALARY_ERROR,
  INDI_FETCH_MONTHLY_SALARY_SUCCESS,

  //EXPENSE DETAILS
  ADD_EXPENSE_DETAILS_BGIN,
  ADD_EXPENSE_DETAILS_ERROR,
  ADD_EXPENSE_DETAILS_SUCCESS,
  LIST_REPORT_DETAILS_BGIN,
  LIST_REPORT_DETAILS_SUCCESS,
  LIST_REPORT_DETAILS_ERROR,
  LIST_CURRENT_MONTHLYSALARY_DETAILS_BGIN,
  LIST_CURRENT_MONTHLYSALARY_DETAILS_SUCCESS,
  LIST_CURRENT_MONTHLYSALARY_DETAILS_ERROR,
  ADD_CREDIT_DETAILS_BGIN,
  ADD_CREDIT_DETAILS_SUCCESS,
  ADD_CREDIT_DETAILS_ERROR,
  LIST_HISTORY_DETAILS_BGIN,
  LIST_HISTORY_DETAILS_SUCCESS,
  LIST_HISTORY_DETAILS_ERROR,
  PRINT_HISTORY_DETAILS_BGIN,
  PRINT_HISTORY_DETAILS_SUCCESS,
  PRINT_HISTORY_DETAILS_ERROR,
  GRAPH_EXPENSE_BEGIN,
  GRAPH_EXPENSE_SUCCESS,
  GRAPH_EXPENSE_ERROR,
  NOTIFY_DETAILS_BEGIN,
  NOTIFY_DETAILS_SUCCESS,
  NOTIFY_DETAILS_ERROR,
} from "../constant/expense";

//MONTHLY SALARY

export const ListCurrentMonthlySalaryReducer = (
  state = { CurrentSalary: [] },
  action
) => {
  switch (action.type) {
    case LIST_CURRENT_MONTHLYSALARY_DETAILS_BGIN:
      return { loading: true, CurrentSalary: [] };
    case LIST_CURRENT_MONTHLYSALARY_DETAILS_SUCCESS:
      return { loading: false, CurrentSalary: action.payload };
    case LIST_CURRENT_MONTHLYSALARY_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const addMonthySalaryReducer = (state = { adddata: {} }, action) => {
  switch (action.type) {
    case ADD_MONTHLY_SALARY_BGIN:
      return { loading: true, adddata: {} };
    case ADD_MONTHLY_SALARY_SUCCESS:
      return { loading: false, adddata: action.payload };
    case ADD_MONTHLY_SALARY_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const EditMonthlySalaryReducer = (
  state = { editMonthlySalary: {} },
  action
) => {
  switch (action.type) {
    case EDIT_MONTHLY_SALARY_BGIN:
      return { loading: true, editMonthlySalary: {} };
    case EDIT_MONTHLY_SALARY_SUCCESS:
      return { loading: false, editMonthlySalary: action.payload };
    case EDIT_MONTHLY_SALARY_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const IndiFetchMonthlySalaryReducer = (
  state = { IndiMonthlySalary: {} },
  action
) => {
  switch (action.type) {
    case INDI_FETCH_MONTHLY_SALARY_BGIN:
      return { loading: true, IndiMonthlySalary: {} };
    case INDI_FETCH_MONTHLY_SALARY_SUCCESS:
      return { loading: false, IndiMonthlySalary: action.payload };
    case INDI_FETCH_MONTHLY_SALARY_ERROR:
      return { error: true };
    default:
      return state;
  }
};

//EXPENSE DETAILS
export const addExpenseDetailsReducer = (state = { adddata: {} }, action) => {
  switch (action.type) {
    case ADD_EXPENSE_DETAILS_BGIN:
      return { loading: true, adddata: {} };
    case ADD_EXPENSE_DETAILS_SUCCESS:
      return { loading: false, adddata: action.payload };
    case ADD_EXPENSE_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const listReportDetailsReducer = (
  state = { listReportDetails: [] },
  action
) => {
  switch (action.type) {
    case LIST_REPORT_DETAILS_BGIN:
      return { loading: true, listReportDetails: [] };
    case LIST_REPORT_DETAILS_SUCCESS:
      return { loading: false, listReportDetails: action.payload };
    case LIST_REPORT_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const addCreditDetailsReducer = (state = { adddata: {} }, action) => {
  switch (action.type) {
    case ADD_CREDIT_DETAILS_BGIN:
      return { loading: true, adddata: {} };
    case ADD_CREDIT_DETAILS_SUCCESS:
      return { loading: false, adddata: action.payload };
    case ADD_CREDIT_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

//HISTORY
export const ListHistoryDetailsReducer = (
  state = { HistoryDetails: [] },
  action
) => {
  switch (action.type) {
    case LIST_HISTORY_DETAILS_BGIN:
      return { loading: true, HistoryDetails: [] };
    case LIST_HISTORY_DETAILS_SUCCESS:
      return { loading: false, HistoryDetails: action.payload };
    case LIST_HISTORY_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

export const IndiHistoryDetailsReducer = (
  state = { IndiHistory: {} },
  action
) => {
  switch (action.type) {
    case PRINT_HISTORY_DETAILS_BGIN:
      return { loading: true, IndiHistory: {} };
    case PRINT_HISTORY_DETAILS_SUCCESS:
      return { loading: false, IndiHistory: action.payload };
    case PRINT_HISTORY_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};

//GRAPH
export const GetExpenseGraphReducer = (
  state = { expenseGraph: [] },
  action
) => {
  switch (action.type) {
    case GRAPH_EXPENSE_BEGIN:
      return { loading: true, expenseGraph: [] };
    case GRAPH_EXPENSE_SUCCESS:
      return { loading: false, expenseGraph: action.payload };
    case GRAPH_EXPENSE_ERROR:
      return { error: true };
    default:
      return state;
  }
};

//Notify
export const GetNotificationDetailsReducer = (
  state = { notifyDetails: [] },
  action
) => {
  switch (action.type) {
    case NOTIFY_DETAILS_BEGIN:
      return { loading: true, notifyDetails: [] };
    case NOTIFY_DETAILS_SUCCESS:
      return { loading: false, notifyDetails: action.payload };
    case NOTIFY_DETAILS_ERROR:
      return { error: true };
    default:
      return state;
  }
};
